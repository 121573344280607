import { atom } from 'jotai';
import { activeFolderAtom } from '@/atoms/folders';
import { userAtom } from '@/atoms/general';
import { treeAtom } from '@/atoms/folders';

export const getDocumentsAndFoldersAtom = atom(null, async (get, set, val) => {
  const user = get(userAtom);
  const activeFolder = get(activeFolderAtom);
  console.log(activeFolder);
  console.log(user);
  let entireTree = get(treeAtom);

  try {
    let response;
    if (!entireTree) {
      set(treeAtom, response.data);
      entireTree = get(treeAtom);
    }
  } catch (err) {
    console.log(err);
  }
});
