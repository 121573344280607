import React from 'react';
import './voice-main.scoped.css';
import { useEffect } from 'react';
import VoiceTable from '../VoiceTable/VoiceTable';
import { useAtom } from 'jotai';
import { getDocumentsAndFoldersAtom } from '@/api/voice';

function VoiceMain() {
  const [, getDocumentsAndFolders] = useAtom(getDocumentsAndFoldersAtom);

  useEffect(() => {
    getDocumentsAndFolders();
  }, []);

  return (
    <div className="documents-main-container">
      <VoiceTable key={123} />
    </div>
  );
}

export default VoiceMain;
