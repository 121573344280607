import { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import axios from '@/configs/axios.js';
import cancelTokenAxios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import './chat.scoped.css';
import { htmlDecode } from '@/utils/html';
import calcTextToken from '@/utils/calcTextToken';
import { removeCharsFromAns, enlargeTextInput } from '@/utils/formatText';
import generateUniqueUserId from '@/utils/generateUniqueUserId';
import { ReactComponent as PlusIcon } from '@/assets/plus-icon-lm.svg';
import { getSpeech } from 'voice-rss-client/dist/browser';
import { ReactComponent as SpeakIcon } from '@/assets/speak-icon.svg';
import { ReactComponent as RefreshAnswer } from '@/assets/refresh_answer.svg';
import { ReactComponent as ToolKitImg } from '@/assets/tool-kit-mobile.svg';
import { ReactComponent as SpeakIconActive } from '@/assets/speak-icon-active.svg';
import { ReactComponent as NewChatIcon } from '@/assets/new-chat-icon.svg';
import Typewriter from 'typewriter-effect';
import { NewHeader } from '@/components/NewHeader/NewHeader';
import { Loader } from '@/components/Loader/Loader';
import { InputForm } from './Comments/InputForm/InputForm';
import { Answer } from './Comments/Answer/Answer';
import { useHistory } from 'react-router-dom';
import { History } from '@/components/RightPanel/History/History';
import { ToolKit } from '@/components/RightPanel/Toolkit/ToolKit';
import { PopUpQuestions } from '@/components/Popup/Question/PopUpQuestions';
import { DoucumentsPopUp } from '@/components/Popup/Document/DocumentsPopUp';
import { MobileToolKit } from '@/components/MobileToolkit/MobileToolKit';
import { useOktaAuth } from '@okta/okta-react';
import { toolKitSettingsAtom } from '@/atoms/chat';
import { useAtom } from 'jotai';
import { userAtom, requestCancelTokenAtom, submitAtom } from '@/atoms/general';
import { countChosenPromptsAtom } from '@/atoms/prompts';
import { prevQuestionsAtoms } from '@/atoms/questions';
import { isPreviewAtom } from '@/atoms/preview';
import { inputsAtoms, focusInputAtoms } from '@/atoms/inputs';
import { prevLogIdAtom } from '@/atoms/login';
import { socketAtom, isloadingAtom } from '@/atoms/general';
import Cookies from 'js-cookie';
import { markedDocumentsAtom } from '@/atoms/documents';
import { markedPromptsAtom } from '@/atoms/prompts';
import { activeLogAtom } from '@/atoms/logs';
import FilledBtn from '@/components/FilledBtn/FilledBtn';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const mic = new SpeechRecognition();

mic.continuous = true;
mic.interimResults = true;
mic.lang = 'en-US';

const loggedUser = JSON.parse(sessionStorage.getItem('user'));

export const Chat = forwardRef(({ promptsToDb, documentsToDb, checkOverflow }, ref) => {
  const navigate = useHistory();
  const [question, setQuestion] = useState('');
  const defaultDirection = useRef();
  const [placeholder, setPlaceholder] = useState('Type here...');
  const [isListening, setIsListening] = useState(false);
  const [isPressedMic, setIsPressedMic] = useState(false);
  const [isHeaderBurgerOpen, setIsHeaderBurgerOpen] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [audioPlayIndex, setAudioPlayIndex] = useState();
  const [checkboxData, setCheckboxData] = useState({ tag: false, answer: true, sentiment: false });
  const [activeLog, setActiveLog] = useAtom(activeLogAtom);
  const [isPreview, setIsPreview] = useAtom(isPreviewAtom); // atom for setting when chat is in preview
  const [prevLogId, setPrevLogId] = useAtom(prevLogIdAtom);
  const [isLoading, setIsLoading] = useAtom(isloadingAtom);
  const [markedDocuments] = useAtom(markedDocumentsAtom);
  const [markedPrompts] = useAtom(markedPromptsAtom);
  const [focusId, setFocusId] = useAtom(focusInputAtoms);
  const [toolKitSettings] = useAtom(toolKitSettingsAtom);
  const [user, setUser] = useAtom(userAtom);
  const [preQuestionCheckBoxData, setPreQuestionCheckBoxData] = useAtom(prevQuestionsAtoms);
  const [, setLogSignal] = useAtom(submitAtom);
  const [socket] = useAtom(socketAtom);
  const [, setRequestCancelToken] = useAtom(requestCancelTokenAtom);
  const [isPageEnglish, setIsPageEnglish] = useState(loggedUser?.lang === 'hebrew' ? false : true);
  const [isCloseToolKit, setIsCloseToolKit] = useState(true);
  const [inputs, setInputs] = useAtom(inputsAtoms);
  const isStillRecord = useRef();
  const dataToSaveToDB = useRef();
  const dataPrevLog = useRef();
  const audioPlayingRef = useRef();
  const currSession = useRef();
  const userWordsAmount = useRef();
  const overflowRef = useRef();

  useImperativeHandle(ref, () => ({
    generateAnswer: generateAnswer,
  }));

  ///use effect to handle sockets events on sending requests/
  useEffect(() => {
    socket.on('user-wants', (userWants) => {
      // console.log('user-wants:', userWants)
    });
    socket.on('chosen-resources', (chosenResources) => {
      // console.log('chosen-resources:', chosenResources)
    });
    return () => {
      socket.off('user-wants');
    };
  });

  useEffect(() => {
    const start = async () => {
      await handleSession();
    };

    start();

    sessionStorage.setItem('conversation', '');
    currSession.current = Date.now();
    promptsToDb.current = [];
    return () => {
      clearTimeout(isStillRecord.current);
    };
  }, []);

  useEffect(() => {
    if (isPageEnglish) {
      mic.lang = 'en-US';
    } else {
      mic.lang = 'he-IL';
    }
    sessionStorage.setItem(
      'user',
      JSON.stringify({
        ...user,
        lang: isPageEnglish ? 'english' : 'hebrew',
        prompt_id: user.prompt_id,
      })
    );
    setPlaceholder('Ask me whatever you want...');
    if (audioPlayingRef.current) {
      audioPlayingRef.current.pause();
      setIsSpeaking(false);
      audioPlayingRef.current = null;
    }
  }, [isPageEnglish]);

  useEffect(() => {
    handleListen();
    clearTimeout(isStillRecord.current);
  }, [isListening]);
  useEffect(() => {
    checkOverflow(overflowRef);
  }, [inputs]);

  useEffect(() => {
    return () => {
      // overflowRef.addEventListener('scroll',()checkOverflowfunc)
    };
  }, []);

  const checkOverflowfunc = () => {
    checkOverflow(overflowRef);
  };

  const restoreOldChat = async () => {
    sessionStorage.setItem('chat', inputs);
    sessionStorage.setItem('documents', JSON.stringify(markedDocuments));
    sessionStorage.setItem('toolkit', JSON.stringify(toolKitSettings));

    setActiveLog('');
    setIsPreview(false);
    setIsCloseToolKit(true);
  };

  useEffect(() => {
    var objDiv = document.getElementsByClassName('answer-text');
    if (objDiv[0]) {
      objDiv[0].scrollTop = objDiv[0].scrollHeight;
    }
    if (dataToSaveToDB.current && Object.keys(dataToSaveToDB.current).length !== 0) {
      saveToDB();
    }
  }, [inputs]);

  const handleOpenToolKit = (ev) => {
    ev.stopPropagation();
    setIsCloseToolKit(false);
  };
  const addInput = (
    role,
    isAnswer,
    value,
    arrOfAnswer = null,
    isFocus = false,
    resources,
    isResponseImgUrl
  ) => {
    let id = uuidv4();
    if (isFocus) {
      setFocusId(id);
    }
    const newInputField = {
      role: role,
      value: value,
      isAnswer: isAnswer,
      isResponseImgUrl: isResponseImgUrl,
      isLike: null,
      comment: '',
      show: true,
      toType: isAnswer ? true : false,
      key: id,
      arrOfAnswer: arrOfAnswer,
      files: [],
      resources: resources,
    };

    let newInput = [newInputField, ...inputs];
    setInputs((prev) => [newInputField, ...prev]);

    return newInput;
  };
  const handleDeleteInput = (key) => {
    setInputs((prev) =>
      prev.filter((input) => {
        return input.key !== key;
      })
    );
  };
  const handleToggleRole = (role, key) => {
    setInputs((prev) =>
      prev.map((input) => {
        return input.key === key ? { ...input, role: role === 'user' ? 'assistant' : 'user' } : input;
      })
    );
  };

  const handleCommentSave = async (ev, answer) => {
    try {
      await axios.put('/api/logs/comment', {
        logId: answer.id,
        comment: answer.comment,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCommentChange = (ev, index) => {
    if (ev.key === 'Enter') return;
    let newAnswers = [...answers];
    newAnswers[index].comment = ev.target.value;

    setInputs([...newAnswers]);
  };

  const toggleLikeAnswer = async (key, logId) => {
    let newAnswers = inputs.filter((item) => item.key === key)[0];
    if (newAnswers.isLike === 'like') {
      newAnswers.isLike = false;
    } else {
      newAnswers.isLike = 'like';
    }

    setInputs([...newAnswers]);

    try {
      await axios.put('/api/logs/toggle-like', {
        logId: logId,
        userId: user.id,
        isLike: newAnswers.isLike,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const toggleDislikeAnswer = async (index) => {
    let newAnswers = [...answers];

    if (newAnswers[index].isLike === 'dislike') {
      newAnswers[index].isLike = false;
    } else {
      newAnswers[index].isLike = 'dislike';
    }

    setInputs([...newAnswers]);

    try {
      await axios.put('/api/logs/toggle-like', {
        logId: newAnswers[index].id,
        isLike: newAnswers[index].isLike,
      });
    } catch (error) {
      console.log(error);
    }
  };

  async function handleSession() {
    try {
      await axios.post('/sessions', {
        id: generateUniqueUserId(),
        browserPlatform: navigator.userAgentData?.platform ?? '',
        javaEnabled: navigator.javaEnabled() ?? '',
        browserVersion: navigator.userAgent ?? '',
        dataCookiesEnabled: navigator.cookieEnabled ?? '',
        dataCookies: document.cookie ?? '',
        sizeScreenW: window.screen.width ?? '',
        sizeScreenH: window.screen.height ?? '',
        screenColorDepth: window.screen.colorDepth ?? '',
        screenPixelDepth: window.screen.pixelDepth ?? '',
      });
    } catch (error) {
      console.log(error.message);
    }
  }

  const handleListen = () => {
    if (isListening) {
      mic.start();
    } else {
      mic.stop();
    }

    mic.onspeechend = () => {
      generateAnswer();
      setQuestion('');
    };

    mic.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join('');

      if (!transcript) return;
      setQuestion(transcript);
      clearTimeout(isStillRecord.current);
      isStillRecord.current = setTimeout(() => {
        setIsListening(false);
      }, 1500);
      mic.onerror = (event) => {
        console.log(event.error);
      };
    };
  };

  const saveToDB = async () => {
    const token = sessionStorage.getItem('tokenForGeko');
    const logId = generateUniqueUserId();
    setPrevLogId(logId);

    try {
      await axios.post('/api/logs', {
        data: dataToSaveToDB.current,
        logId: logId,
      });

      if (dataToSaveToDB.current.tokenAmount) {
        await axios.put('/api/user/update-tokens', {
          id: user.id,
          tokenUsed: dataToSaveToDB.current.tokenAmount,
          wordsUsed: dataToSaveToDB.current.wordsAmount,
        });
      }
      dataToSaveToDB.current = {}; // trying to delay dataSave in order to update log
      setLogSignal((prev) => !prev);
    } catch (error) {
      console.log(error);
    }
  };

  const makeConversationArr = () => {
    let combined = questions
      .map((question, index) => {
        return [
          { role: 'user', content: question },
          { role: 'assistant', content: answers[index].answer },
        ];
      })
      .flat();
    combined.push({ role: 'user', content: question });
    return combined;
  };

  const getAnswerByDocument = async (documentsSelected, askQuestion, cancelToken) => {
    let newAnalysisRoute = '';
    console.log(markedDocuments[0].vectorMethods.includes('Text'));
    if (
      markedDocuments[0].vectorMethods.includes('Text') ||
      markedDocuments[0].vectorMethods.includes('Text & Images') ||
      markedDocuments[0].vectorMethods.includes('llamaPipeline') ||
      markedDocuments[0].vectorMethods.includes('parse-with-images')
    ) {
      newAnalysisRoute = '/analysis';
    }
    let { data } = await axios.put(
      `/api${newAnalysisRoute}/document-analysis`,
      {
        clientId: Cookies.get('client-id'),
        input: askQuestion,
        documentsSelected: documentsSelected,
        toolKitSettings: toolKitSettings,
      },
      {
        cancelToken: cancelToken.token,
      }
    );
    console.log('the sources are:', data.rankedAnswersText);

    console.log('the users wants:', data.userWants);
    console.log('all the resources with grades:', data.allResourcesWithGrades);
    console.log('final-array:', data.messages);

    // setAnswers((prev) => [
    //   ...prev,
    //   {
    //     answer: data.answer,
    //     answerInEnglish: data.answer,
    //     arrOfAnswer: data.arrOfAnswer ? data.arrOfAnswer : null,
    //     currAnsIndex: 0,
    //     resources: data.resources ? [...data.resources] : null,
    //     id: generateUniqueUserId(),
    //     isLike: false,
    //     speakAnswer: '',
    //     speakAnswerEnglish: '',
    //     category: '',
    //     sentiment: '',
    //     tokenAmount: 0,
    //     apiModel: {answer:toolKitSettings.model,tag:toolKitSettings.model,sentiment:toolKitSettings.model},
    //     wordsAmount: 0,
    //   },
    // ])
    // data.answer = addLinkToSource(data.answer, data.resources)
    const newInputs = data.arrOfAnswer
      ? addInput('assistant', true, data.answer, data.arrOfAnswer, false, data.resources)
      : addInput('assistant', true, data.answer, null, false, data.resources);
    addInput('user', false, '', null, true);

    dataToSaveToDB.current = {
      calculationTime: new Date() - dataToSaveToDB.current.calculationTime,
      answerInEnglish: data.answer,
      questionInEnglish: question.replace('/', '').replace(/\\"/g, '"'),
      answer: data.answer,
      arrOfAnswer: data.arrOfAnswer ? data.arrOfAnswer : null,
      question: JSON.stringify(newInputs),
      documentsSelected: JSON.stringify(documentsSelected),
      toolkitSettings: JSON.stringify(toolKitSettings),
      sessionId: currSession.current,
      questionNumber: questions.length + 1,
      category: '',
      userId: user.id,
      sentiment: '',
      tokenAmount: 0,
      apiModel: JSON.stringify({
        answer: toolKitSettings.model,
        tag: toolKitSettings.model,
        sentiment: toolKitSettings.model,
      }),
      wordsAmount: 0,
      prompts: promptsToDb.current,
    };
  };

  const setNextAnswer = (index) => {
    let newAnswers = [...answers];
    let length = newAnswers[index].arrOfAnswer.length;

    if (newAnswers[index].currAnsIndex < length - 1) {
      newAnswers[index] = {
        ...newAnswers[index],
        answer: newAnswers[index].arrOfAnswer[newAnswers[index].currAnsIndex + 1],
        answerInEnglish: newAnswers[index].arrOfAnswer[newAnswers[index].currAnsIndex + 1],
        currAnsIndex: newAnswers[index].currAnsIndex + 1,
      };
    } else {
      newAnswers[index] = {
        ...newAnswers[index],
        answer: newAnswers[index].arrOfAnswer[0],
        answerInEnglish: newAnswers[index].arrOfAnswer[0],
        currAnsIndex: 0,
      };
    }
    setAnswers(newAnswers);
  };

  const handleFilter = (input) => {
    const res = input.value !== '' || (input.files.length !== 0 && markedPrompts.length !== 0);
    return res;
  };

  const prompts = markedPrompts.map((prompt) => prompt.title);

  const generateAnswer = async (ev, askQuestion = question) => {
    askQuestion = inputs
      .map((input) => {
        let newStr = input.value.replace(/\n/g, ' ');

        return {
          role: input.role,
          content: newStr.trim(),
          files: input.files,
          images: input.images,
        };
      })
      .filter((input) => input.content !== '' || prompts.toString() !== '') // Filter out empty contents.
      .reverse();

    setInputs((prev) => prev.filter((input) => handleFilter(input)));

    // let promptsArr = inputs.filter((input)=>input.role ==='Assistant')
    // let questionsArr = inputs.filter((input)=>input.role==='user')
    // let questionsString = ' '

    // questionsArr.forEach((inputs)=>{
    //   questionsString  = ` ${questionsString} Human: ${inputs.value} .`
    // })

    let promptsString = '';
    const cancelToken = cancelTokenAxios.CancelToken.source();
    setRequestCancelToken(cancelToken);
    dataToSaveToDB.current = {};
    dataToSaveToDB.current.calculationTime = new Date();

    if (ev) ev.preventDefault();
    clearTimeout(isStillRecord.current);
    setIsListening(false);
    // if (!askQuestion) return

    // var el = document.getElementsByClassName('question-input')
    // if (el[0]) el[0].style.height = '42px'

    setPlaceholder('');
    try {
      setQuestion('');

      let html, speakAnswer, htmlEnglish, speakAnswerEnglish;
      let conversation = sessionStorage.getItem('conversation');
      setQuestions((prev) => [...prev, askQuestion]);
      setIsLoading(true);

      let documentsSelected = markedDocuments.map((doc) => doc.docId);
      let promptsSelected = markedPrompts.map((prompt) => prompt.content);

      for (let i = promptsSelected.length - 1; i >= 0; i--) {
        // Prepend an object with 'role' as 'user' and 'content' as the current string
        askQuestion.unshift({
          role: 'user',
          content: promptsSelected[i],
          files: [],
        });
      }

      if (documentsSelected.length > 0) {
        await getAnswerByDocument(documentsSelected, askQuestion, cancelToken);
        setIsLoading(false);
        return;
      }

      const formData = new FormData();

      // Append fields to FormData
      formData.append('temp', 0);
      formData.append('tokens', (1500 * 1000) / 750);
      formData.append(
        'apiModel',
        JSON.stringify({
          answer: toolKitSettings.model,
          tag: toolKitSettings.model,
          sentiment: toolKitSettings.model,
        })
      );
      formData.append('askQuestion', JSON.stringify(askQuestion));
      formData.append('conversation', JSON.stringify(conversation));
      formData.append(
        'inputs',
        JSON.stringify(
          [...inputs].reverse().map((item, index) => {
            const hasImages = item.images && item.images.length > 0;
            return {
              role: item.role,
              value: item.value,
              isHaveImg: hasImages,
              imageUrls: hasImages ? item.images.map((image) => image.imageUrl) : [],
            };
          })
        )
      );

      formData.append('toolKitSettings', JSON.stringify(toolKitSettings));
      formData.append('clientId', Cookies.get('client-id'));
      formData.append('checkboxData', JSON.stringify({ tag: false, answer: true, sentiment: false }));
      formData.append('preQuestionCheckBoxData', JSON.stringify(preQuestionCheckBoxData));
      formData.append('wordsUsed', userWordsAmount.current);
      formData.append('coupon', user.coupon);
      formData.append('documentsSelected', JSON.stringify(documentsSelected));

      // Append the image file
      const reversedArray = inputs.slice().reverse();
      reversedArray.forEach((input, inputIndex) => {
        if (input.images && input.images.length > 0) {
          input.images.forEach((image, imgIndex) => {
            formData.append(`imageFile${inputIndex}_${imgIndex}`, image.imageFile);
          });
        }
      });
      console.log(formData, 'formDataformData');
      let isResponseImgUrl = toolKitSettings.model === 'dall-e-3';
      var { data } = await axios.put('/get-answer', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        cancelToken: cancelToken.token,
      });

      let { AnsFromAi, QuestionInEnglish, categoryText, sentimentText } = data;

      // if(toolKitSettings.chatLang ==='hebrew'){
      //   AnsFromAi = await requestTranslationToHebrew(AnsFromAi)
      // }else if (toolKitSettings.chatLang ==='english'){
      //   AnsFromAi = await requestTranslationToEnglish(AnsFromAi)

      // }

      if (!isValidUrl(AnsFromAi)) isResponseImgUrl = false;
      const newInputs = addInput('assistant', true, AnsFromAi, null, null, null, isResponseImgUrl);
      if (!isResponseImgUrl || !toolKitSettings.model === 'dall-e-3')
        addInput('user', false, '', null, true);

      let answerTranslate = AnsFromAi;

      speakAnswer = answerTranslate;
      speakAnswerEnglish = AnsFromAi;

      answerTranslate = htmlDecode(answerTranslate);
      html = answerTranslate;

      htmlEnglish = htmlDecode(AnsFromAi);

      setIsLoading(false);

      setQuestion('');
      sessionStorage.setItem(
        'conversation',
        `${conversation} Human: ${QuestionInEnglish} \n ${AnsFromAi}`
      );

      var tokenAmount = calcTextToken(AnsFromAi, QuestionInEnglish);
      var wordsAmount = calcWordsAmount({
        ansInEnglish: AnsFromAi,
        ansInHebrew: answerTranslate,
        question,
      });
      let id = '';

      setAnswers((prev) => [
        ...prev,
        {
          answer: removeCharsFromAns(html),
          isImgUrl: isResponseImgUrl,
          answerInEnglish: removeCharsFromAns(htmlEnglish),
          id: id || generateUniqueUserId(),
          isLike: false,
          speakAnswer: removeCharsFromAns(speakAnswer),
          speakAnswerEnglish: removeCharsFromAns(speakAnswerEnglish),
          category: categoryText,
          sentiment: sentimentText,
          tokenAmount: tokenAmount,
          apiModel: {
            answer: toolKitSettings.model,
            tag: toolKitSettings.model,
            sentiment: toolKitSettings.model,
          },
          wordsAmount: wordsAmount,
        },
      ]);
      dataToSaveToDB.current = {
        calculationTime: new Date() - dataToSaveToDB.current.calculationTime,
        answerInEnglish: AnsFromAi,
        questionInEnglish: JSON.stringify(QuestionInEnglish),
        answer: answerTranslate,
        question: JSON.stringify(newInputs),
        // question: askQuestion.replace('/', '').replace(/\\"/g, '"'),
        toolkitSettings: JSON.stringify(toolKitSettings),
        sessionId: currSession.current,
        questionNumber: newInputs.length,
        category: categoryText,
        userId: user.id,
        sentiment: sentimentText,
        tokenAmount: tokenAmount,
        apiModel: JSON.stringify({
          answer: toolKitSettings.model,
          tag: toolKitSettings.model,
          sentiment: toolKitSettings.model,
        }),
        wordsAmount: wordsAmount,
        prompts: promptsToDb.current,
      };
    } catch (error) {
      if (error.code === 'ERR_CANCELED') {
        setIsLoading(false);
        console.log('canceled');
        return;
      }
      console.log(error);
      if (error?.response?.data === 'Input is too long for requested model.') {
        alert('input too big for model.');
        setIsLoading(false);
        return;
      }
      if (error?.response?.data === 'Exceed limit') {
        alert('You have Exceeded your words limit.');
        setIsLoading(false);
        return;
      }
      if (error.message.includes('Paths must not contain // in them')) {
        console.log('catch link');
      }

      if (error.message === 'Request failed with status code 403') {
        sessionStorage.removeItem('tokenForGeko');
        setUser();
        navigate('/welcome-page');
      }
    }
  };

  function isValidUrl(string) {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  }
  const calcWordsAmount = ({ question, ansInEnglish, ansInHebrew }) => {
    let wordsAmount = question.split(' ').length;
    wordsAmount += isPageEnglish ? ansInEnglish.split(' ').length : ansInHebrew.split(' ').length;
    return wordsAmount;
  };
  const handleSubmit = () => {
    console.log('toolkit-settings:', toolKitSettings);
    generateAnswer();
  };

  const handleChangeInput = (e, key) => {
    enlargeTextInput();
    let value = e.target ? e.target.value : e;

    // let removeScript
    // if (target.value) {
    //   removeScript = target.value.replace('<script>', '')
    //   removeScript = removeScript.replace('</script>', '')
    //   removeScript = removeScript.replace('<script/>', '')
    // }
    setInputs((prev) =>
      prev.map((input) => {
        return input.key === key ? { ...input, value: value, arrOfAnswer: null } : input;
      })
    );
  };

  const removeImg = (inputIndex, imageToRemove) => {
    setInputs((prev) =>
      prev.map((input, index) => {
        if (index === inputIndex) {
          return {
            ...input,
            images: input.images.filter(
              (image) =>
                image.imageUrl !== imageToRemove.imageUrl || image.imageFile !== imageToRemove.imageFile
            ),
          };
        }
        return input;
      })
    );
  };

  const downloadImg = async (imageUrl, fileName = 'image.jpg') => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();

    URL.revokeObjectURL(url);
  };

  const handleChangeInputImg = (e, key) => {
    const files = Array.from(e.target.files);
    const validImageFiles = files.filter((file) => file.type.startsWith('image/'));

    if (validImageFiles.length > 0) {
      setInputs((prev) =>
        prev.map((input) => {
          if (input.key === key) {
            const newImages = validImageFiles.map((file) => ({
              imageUrl: URL.createObjectURL(file),
              imageFile: file,
            }));

            return {
              ...input,
              images: [...(input.images || []), ...newImages], // concatenate new images with existing ones
            };
          }
          return input;
        })
      );
    } else {
      alert('Please select valid image files.');
    }
  };

  const handleMicClick = async () => {
    setIsListening((prevState) => !prevState);

    if (!isPressedMic) setIsPressedMic(true);
  };

  const speakAnswer = async (ans, index) => {
    const text = isPageEnglish ? ans.answerInEnglish : ans.answer;
    if (index === audioPlayIndex && isSpeaking && audioPlayingRef.current) {
      audioPlayingRef.current.pause();
      setIsSpeaking(false);
      return;
    }

    if (index === audioPlayIndex && !isSpeaking && audioPlayingRef.current) {
      audioPlayingRef.current.play();
      setIsSpeaking(true);
      return;
    }

    if (index !== audioPlayIndex && isSpeaking && audioPlayingRef.current) {
      audioPlayingRef.current.pause();
    }

    if (audioPlayingRef.current) {
      audioPlayingRef.current.pause();
      audioPlayingRef.current = null;
    }

    let speech = await getSpeech({
      apiKey: '4a37b9e6a3ae4a2596a6ba8f6275fe49',
      language: isPageEnglish ? 'en-us' : 'he-il',
      text: text,
    });

    speech = speech.slice(0, 5) + 'audio/wav' + speech.slice(5);
    audioPlayingRef.current = new Audio(speech);
    audioPlayingRef.current.play();
    setAudioPlayIndex(index);
    setIsSpeaking(true);

    audioPlayingRef.current.addEventListener('ended', function() {
      audioPlayingRef.current = null;
      setIsSpeaking(false);
    });
  };
  return (
    <>
      <div
        ref={overflowRef}
        className={questions.length > 0 ? 'question-container' : 'question-container'}
      >
        {/* <div className={isPageEnglish ? 'answer-text ltr' : 'answer-text'}>
              {answers.length > 0 &&
                answers.map((answer, index) => (
                  <div className="single-ans-que-container" key={answer.id + index}>
                    {
                      <h4 className={isPageEnglish ? 'old-question-text ltr' : 'old-question-text'}>
                        {questions[index].split('\n').map((item, i) => (
                          <span key={i}>
                            {item}
                            <br />
                          </span>
                        ))}
                      </h4>
                    }
              
                    {answer.arrOfAnswer && (
                      <RefreshAnswer
                        className="refresh-answer-icon"
                        onClick={() => setNextAnswer(index)}
                      />
                    )}
                    {!toolKitSettings.isTypingEffect || answers.length - 1 !== index ? (
                      <p className="answer">
                        {isPageEnglish
                          ? formatTextBreakLine(answer.answerInEnglish, answer.resources, answer)
                          : formatTextBreakLine(answer.answer, answer.resources, answer)}
                      </p>
                    ) : (
                      answer.resources.map(
                        (res, index) =>
                          answer.currAnsIndex === index && (
                            <Typewriter
                              options={{
                                skipAddStyles: true,
                                delay: 8,
                              }}
                              onInit={(typewriter) => {
                                typewriter
                                  .typeString(
                                    isPageEnglish
                                      ? formatTextBreakLineForTyping(
                                          answer.answerInEnglish,
                                          answer.resources,
                                          answer
                                        )
                                      : formatTextBreakLineForTyping(
                                          answer.answer,
                                          answer.resources,
                                          answer
                                        )
                                  )
                                  .callFunction(() => {
                                    typewriter.stop()
                                  })
                                  .start()
                              }}
                              className="answer"
                            />
                          )
                      )
                    )}
                    {toolKitSettings.isTypingEffect && answer.resources && (
                      <a
                        href={`/resource/[${`"${
                          answer.resources[answer.currAnsIndex ? answer.currAnsIndex : 0]
                        }"`}}]`}
                        target="_blank"
                      >
                        source
                      </a>
                    )}
                  </div>
                ))}
            </div> */}

        {/* <Loader isLoading={isLoading} isPageEnglish={isPageEnglish} /> */}
        {toolKitSettings.model !== 'dall-e-3' && (
          <button
            style={isPreview ? { visibility: 'hidden' } : {}}
            className="add-message-btn"
            disabled={isPreview}
            onClick={() => addInput('user', false, '', null, true)}
          >
            <PlusIcon className="plus-icon" /> Add Message{' '}
          </button>
        )}

        {inputs.length > 0 && (
          <>
            {isLoading && <Answer loadingAns={true} />}
            {inputs.map((input, index) => {
              return !input.isAnswer ? (
                <InputForm
                  key={input.key}
                  generateAnswer={generateAnswer} //to delete because generate will happen from submit
                  question={input.value}
                  role={input.role}
                  id={input.key}
                  files={input.files}
                  isResponseImgUrl={input.isResponseImgUrl}
                  isFocus={input.key === focusId}
                  handleChange={(e) => handleChangeInput(e, input.key)}
                  handleToggleRole={(role) => handleToggleRole(role, input.key)}
                  placeholder={placeholder}
                  isPageEnglish={isPageEnglish}
                  handleDeleteInput={() => handleDeleteInput(input.key)}
                  defaultDirection={defaultDirection}
                  selectedImages={input.images}
                  removeImg={(image) => removeImg(index, image)}
                  setSelectedImage={(e) => handleChangeInputImg(e, input.key)}
                />
              ) : (
                <Answer
                  key={input.key}
                  toggleLikeAnswer={() => toggleLikeAnswer(input.key)}
                  id={input.key}
                  isLike={input.isLike}
                  isFocus={input.key === focusId}
                  files={input.files}
                  arrOfAnswer={input.arrOfAnswer}
                  isResponseImgUrl={input.isResponseImgUrl}
                  comment={input.comment}
                  generateAnswer={generateAnswer} //to delete because generate will happen from submit
                  question={input.value}
                  downloadImg={(image) => downloadImg(image)}
                  role={input.role}
                  index={index}
                  toType={input.toType}
                  show={input.show}
                  handleChange={(e) => handleChangeInput(e, input.key)}
                  handleToggleRole={(role) => handleToggleRole(role, input.key)}
                  placeholder={placeholder}
                  isPageEnglish={isPageEnglish}
                  handleDeleteInput={() => handleDeleteInput(input.key)}
                  resources={input.resources}
                  defaultDirection={defaultDirection}
                />
              );
            })}
          </>
        )}
      </div>

      {isPreview && (
        <>
          <div className="submit-strip">
            <FilledBtn style={{ fontWeight: '400' }} className="submit-btn" onClick={restoreOldChat}>
              {' '}
              Restore
            </FilledBtn>
            <div className="log-details">
              <span className="log-date">
                {' '}
                {activeLog.day} {activeLog.dayNum} {activeLog.month}
                {', '} {activeLog.year}{' '}
              </span>
              <span>Restoring this version will overwrite your current session.</span>
            </div>
          </div>
        </>
      )}
    </>
  );
});
