import React from 'react';
import { ReactComponent as AudioFileIcon } from '@/assets/audio-file-icon.svg';

const FileNameRenderer = (props) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span>{props.value}</span>
            <AudioFileIcon style={{ cursor: 'auto' }} />
        </div>
    );
};
export default FileNameRenderer;