import axios from '@/configs/axios.js';
import React, { useEffect, useState } from 'react';
import './delete-modal.scoped.css';
import { Modal } from '@/components/Modal/Modal';
import promptImg from '@/assets/table-prompt-icon.png';
import { ReactComponent as ChatboxClose } from '@/assets/chatbox-close-icon-lm.svg';
import { markedPromptsAtom } from '@/atoms/prompts';
import { useAtom } from 'jotai';
import { ProgressBar } from '@/components/ProgressBar/ProgressBar'

import { chosenPromptsAtom } from '@/atoms/prompts';

function DeleteModalPrompt({ isOpen, onClose, header, subHeader }) {
  const [markedPrompts, setMarkedPrompts] = useAtom(markedPromptsAtom);
  const [toShowPrompts, setToShowFiles] = useState();
  const [chosenPrompts, setChosenPrompts] = useAtom(chosenPromptsAtom);
  const [isLoading, setIsLoading] = useState(false);

  console.log(toShowPrompts);
  console.log(markedPrompts);

  useEffect(() => {
    setToShowFiles(markedPrompts);
  }, []);

  const deletePrompts = async () => {
    try {
      setIsLoading(true);
      console.log(toShowPrompts, 'toShowPrompts');
      let PromptsId = toShowPrompts.map((toShowPrompts) => toShowPrompts.prompt_id);
      console.log('file to delete from', PromptsId);

      PromptsId.forEach((prompt_id) => {
        console.log('file to delete to', chosenPrompts);
        setChosenPrompts((prev) => {
          return prev.filter((item) => item !== prompt_id);
        });
      });
      await axios.post('/api/prompts/delete-prompts', {
        prompts: JSON.stringify(toShowPrompts.map((toShowPrompts) => toShowPrompts.prompt_id)),
      });
    } catch (err) {
      console.log(err);
    }
  };
  const handleFilesForDelete = (prompt_id) => {
    setToShowFiles((prev) => prev.filter((file) => file.prompt_id.trim() !== prompt_id.trim()));
  };

  return (
    <Modal
      header={header}
      subHeader={!isLoading ? subHeader : 'deleting the chosen prompts'}
      onClose={onClose}
      isOpen={isOpen}
      isTimeout={isLoading}
    >
      {!isLoading ? (
        <>
          <div className="flex-files">
            {toShowPrompts &&
              toShowPrompts.map((file) => {
                return (
                  <div className="file" key={file.prompt_id}>
                    <div className="icon-name-container">
                      <img src={promptImg} /> {file.title}
                    </div>
                    <button type="button" onClick={() => handleFilesForDelete(file.prompt_id)}>
                      <ChatboxClose />
                    </button>
                  </div>
                );
              })}
          </div>
          <div className="flex-btns-actions">
            <button>cancel</button>
            <button onClick={deletePrompts}>delete</button>
          </div>
        </>
      ) : (
        <ProgressBar key={'delete-progress'} />
      )}
    </Modal>
  );
}

export default DeleteModalPrompt;
