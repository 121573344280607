import React from 'react'
import './attachment.scoped.css'
import { useAtom } from 'jotai'
import { handleFileAddAtom } from '@/atoms/logs'
import { ReactComponent as AttachmentIcon } from '@/assets/attachment-icon-lm.svg'
import { toolKitSettingsAtom } from '@/atoms/chat'

const Attachment = ({ id }) => {
  const [toolKitSettings] = useAtom(toolKitSettingsAtom)
  const fileInputRef = React.createRef()
  const [, addFile] = useAtom(handleFileAddAtom)
  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      addFile({ key: id, file: file, modelChosen: toolKitSettings.model })
      console.log('Selected file:', file.name)
      console.log('toolKitSettings.model', toolKitSettings.model)
      // You can handle the file here (e.g., uploading it or reading its contents)
    }
  }

  return (
    <>
      <button onClick={handleButtonClick}>
        {' '}
        <AttachmentIcon />{' '}
      </button>
      <input
        type="file"
        ref={fileInputRef}
        accept=".doc, .docx, .pdf"
        onChange={handleFileChange}
        style={{ display: 'none' }} // Hide the file input
      />
    </>
  )
}

export default Attachment
