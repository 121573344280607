import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ReactComponent as ModelIcon } from '@/assets/model-icon-lm.svg';
import { ReactComponent as LangIcon } from '@/assets/language-icon-lm.svg';
import { ReactComponent as AnswersIcon } from '@/assets/answers-icon-lm.svg';
import { ReactComponent as ValidationsIcon } from '@/assets/validations-icon-lm.svg';
import { ReactComponent as DataIcon } from '@/assets/data-icon-lm.svg';
import { ReactComponent as LeftArrowIcon } from '@/assets/arrow-left-icon.svg';
import { ReactComponent as RightArrowIcon } from '@/assets/arrow-right-icon.svg';
import { ReactComponent as BotCreatorStatus } from '@/assets/bot-creator-status.svg';
import { ReactComponent as VectorType } from '@/assets/toolkit-vector-type.svg';
import { ReactComponent as HistoryIcon } from '@/assets/history-icon.svg';
import { ReactComponent as HistoryIconUnActive } from '@/assets/history-icon-unactive.svg';
import openSelectArrow from '@/assets/open-select-arrow.png';
import { ReactComponent as FilterIconUnActive } from '@/assets/filter-icon-unactive.svg';
import { ReactComponent as FilterIcon } from '@/assets/filter-icon-active.svg';
import { useAtom } from 'jotai';
import { v4 as uuidv4 } from 'uuid';
import { isMobileAtom } from '@/atoms/general';
import './botCreatorToolKit.scoped.scss';
import { useState } from 'react';
import { markedDocumentsAtom, documentsOpenPopUpAtom } from '@/atoms/documents';
import { toolKitSettingsAtom } from '@/atoms/bots';
import { useEffect, useRef } from 'react';

export const BotCreatorToolKit = ({ handleIsCloseToolKit, setIsCloseToolKit, isCloseToolKit }) => {
  const [toolKitSettings, setToolKitSettings] = useAtom(toolKitSettingsAtom);
  const [markedDocuments] = useAtom(markedDocumentsAtom);
  console.log(toolKitSettings);
  const [isMobile] = useAtom(isMobileAtom);

  const [toggleTabs, setToggleTabs] = useState('control-panel');

  const [activeTooltip, setActiveTooltip] = useState({ key: null });
  const timerIdRef = useRef(null);

  const activateControl = () => {
    setToggleTabs('control-panel');
  };
  const activateHistory = () => {
    setToggleTabs('history');
  };

  useEffect(() => {
    return () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
    };
  }, []);

  const settingsArr = [
    {
      name: 'is_active',
      options: [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }],
    },
    {
      name: 'status',
      options: [{ label: 'Development', value: 'Development' }, { label: 'Prod', value: 'Prod' }],
    },
    {
      name: 'ui_language',
      options: [
        { label: 'English (UK)', value: 'English (UK)' },
        { label: 'English (US)', value: 'English (US)' },
        { label: 'Hebrew', value: 'Hebrew' },
      ],
    },
    {
      name: 'questions_selected',
      options: [
        { label: 'Tags', value: 'tags' },
        { label: 'Follow-up questions', value: 'Follow-up questions' },
        { label: 'Similar questions while typing', value: 'Similar questions while typing' },
        { label: 'Display the source link', value: 'Display the source link' },
        { label: 'Display the source text', value: 'Display the source text' },
        { label: 'Web page link', value: 'Web page link' },
      ],
    },
    {
      name: 'should_bot_translate',
      options: [
        { label: 'None', value: 'None' },
        { label: 'To English', value: 'To English' },
        { label: 'To Hebrew', value: 'To Hebrew' },
      ],
    },
    {
      name: 'validations_selected',
      options: [
        { label: 'Like/Dislike', value: 'like+dislike' },
        { label: 'Comment', value: 'comment' },
        { label: 'Dropdown List', value: 'dropdown-list' },
      ],
    },
    {
      name: 'question_choice',
      options: [{ label: 'Vectors', value: 'Vectors' }, { label: 'Q&A', value: 'Q&A' }],
    },
    {
      name: 'embedding-version',
      options: [
        { label: '003-Small', value: '003-small' },
        { label: '003-Large', value: '003-large' },
        { label: 'Embedding-002', value: 'embedding-002' },
      ],
    },
  ];
  const updateToolKit = ({ target }) => {
    const { name, value } = target;
    if (name === 'question_choice' && value && value.length === 0) return;
    setToolKitSettings((prev) => ({ ...prev, [name]: value }));
  };
  const showTooltip = (key) => {
    // Clear any existing timer
    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current);
    }
    timerIdRef.current = setTimeout(() => {
      setActiveTooltip({ key: key });
    }, 500); // Set the delay here

    // setActiveTooltip({ key: null })
  };

  const hideTooltip = () => {
    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current);
    }
    setActiveTooltip({ key: null });
  };

  const disabledEmbeddingVersion =
    toolKitSettings.question_choice.includes('Vectors') && markedDocuments.length;
  console.log(settingsArr);

  return (
    <div className={`tool-kit ${!isCloseToolKit ? 'closed-toolkit' : ''} `}>
      <button className="toggle-nav" onClick={handleIsCloseToolKit}>
        {isCloseToolKit ? <RightArrowIcon /> : <LeftArrowIcon />}
      </button>
      {isCloseToolKit ? (
        <>
          <div className="user-container">
            <button
              disabled={toggleTabs === 'control-panel'}
              className={`tool-kit-title control-panel ${
                toggleTabs === 'control-panel' ? 'active-control' : 'unactive-control'
              }`}
              onClick={activateControl}
            >
              {toggleTabs === 'control-panel' ? <FilterIconUnActive /> : <FilterIcon />}
              Control Panel
            </button>
            <button
              disabled={toggleTabs === 'history'}
              className={`tool-kit-title history ${
                toggleTabs === 'history' ? 'active-control' : 'unactive-control'
              }`}
              onClick={activateHistory}
            >
              {toggleTabs === 'history' ? <HistoryIconUnActive /> : <HistoryIcon />}
              Versions
            </button>
          </div>
          <>
            <div style={toggleTabs === 'control-panel' ? {} : { display: 'none' }}>
              {/* <ToolKit /> */}
            </div>{' '}
            <div style={toggleTabs === 'history' ? {} : { display: 'none' }}>
              {/* <History setIsCloseToolKit={setIsCloseToolKit} /> */}
            </div>
          </>
        </>
      ) : (
        <div className="closed-toolkit-flex">
          <button onClick={() => handleIsCloseToolKit('control-panel')}>
            {toggleTabs === 'control-panel' ? <FilterIconUnActive /> : <FilterIcon />}
          </button>
          <button onClick={() => handleIsCloseToolKit('history')}>
            {toggleTabs === 'history' ? <HistoryIconUnActive /> : <HistoryIcon />}
          </button>
        </div>
      )}
      {isCloseToolKit ? (
        <div className="toolkit-flex">
          <div className="checkboxes-container">
            <div className="tool-tip-container">
              <div className={activeTooltip.key === 'is_active' ? 'tooltip show' : 'tooltip'}>
                Indicates whether the bot is currently available for user interaction.
              </div>

              <div
                onMouseEnter={() => showTooltip('is_active')}
                onMouseLeave={hideTooltip}
                className="flex-dropdown-and-label"
              >
                <div className="label-icon-container">
                  <ModelIcon className="icon-clr" /> Active
                </div>

                <Select
                  className="select-box-tool-box"
                  size="small"
                  id="is_active"
                  value={toolKitSettings.is_active}
                  label="is_active"
                  name="is_active"
                  onChange={updateToolKit}
                >
                  {settingsArr[0].options.map((option) => (
                    <MenuItem key={uuidv4()} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className="tool-tip-container">
              <div className={activeTooltip.key === 'status' ? 'tooltip show' : 'tooltip'}>
                Shows the current state of the bot
              </div>

              <div
                onMouseEnter={() => showTooltip('status')}
                onMouseLeave={hideTooltip}
                className="flex-dropdown-and-label"
              >
                <div className="label-icon-container">
                  <BotCreatorStatus className="icon-clr" /> Status
                </div>

                <Select
                  className="select-box-tool-box"
                  size="small"
                  id="status"
                  value={toolKitSettings.status}
                  label="status"
                  name="status"
                  onChange={updateToolKit}
                >
                  {settingsArr[1].options.map((option) => (
                    <MenuItem key={uuidv4()} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <hr className="seperator" />
          </div>

          <div className="checkboxes-container">
            {!isMobile && (
              <>
                <div className="tool-tip-container">
                  <div className={activeTooltip.key === 'ui_language' ? 'tooltip show' : 'tooltip'}>
                    Choose your preferred language model for tailored responses
                  </div>
                  <div
                    onMouseEnter={() => showTooltip('ui_language')}
                    onMouseLeave={hideTooltip}
                    className="flex-dropdown-and-label"
                  >
                    <div className="label-icon-container">
                      <LangIcon className="icon-clr" /> UI Language
                    </div>

                    <Select
                      className="select-box-tool-box"
                      size="small"
                      id="ui_language"
                      value={toolKitSettings.ui_language}
                      label="ui_language"
                      name="ui_language"
                      onChange={updateToolKit}
                    >
                      {settingsArr[2].options.map((option) => (
                        <MenuItem key={uuidv4()} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="tool-tip-container">
                  <div
                    className={activeTooltip.key === 'should_bot_translate' ? 'tooltip show' : 'tooltip'}
                  >
                    Choose your preferred language model for tailored responses
                  </div>
                  <div
                    onMouseEnter={() => showTooltip('should_bot_translate')}
                    onMouseLeave={hideTooltip}
                    className="flex-dropdown-and-label"
                  >
                    <div className="label-icon-container">
                      <LangIcon className="icon-clr" />
                      Translate
                    </div>

                    <Select
                      className="select-box-tool-box"
                      size="small"
                      id="should_bot_translate"
                      value={toolKitSettings.should_bot_translate}
                      label="should_bot_translate"
                      name="should_bot_translate"
                      onChange={updateToolKit}
                    >
                      {settingsArr[4].options.map((option) => (
                        <MenuItem key={uuidv4()} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>

                <div
                  onMouseEnter={() => showTooltip('question_choice')}
                  onMouseLeave={hideTooltip}
                  className="flex-dropdown-and-label"
                >
                  <div className="label-icon-container">
                    <DataIcon className="icon-clr" /> Data
                  </div>

                  <Select
                    className="select-box-tool-box"
                    size="small"
                    id="question_choice"
                    value={[...toolKitSettings.question_choice]}
                    label="question_choice"
                    name="question_choice"
                    multiple={true}
                    disabled={markedDocuments.length === 0}
                    onChange={updateToolKit}
                    input={<OutlinedInput id="select-multiple-checkbox" label="Validations" />}
                    renderValue={(selected) =>
                      selected
                        .map(
                          (value) =>
                            settingsArr[6].options.find((option) => option.value === value).label
                        )
                        .join(', ')
                    }
                  >
                    {settingsArr[6].options.map((option) => (
                      <MenuItem key={uuidv4()} value={option.value}>
                        <Checkbox checked={toolKitSettings.question_choice.indexOf(option.value) > -1} />
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <hr className="seperator" />
              </>
            )}

            {!isMobile && (
              <div className="tool-tip-container">
                <div className={activeTooltip.key === 'questions_selected' ? 'tooltip show' : 'tooltip'}>
                  Allows selection of chat display options Multiple selections are possible
                </div>

                <div
                  onMouseEnter={() => showTooltip('questions_selected')}
                  onMouseLeave={hideTooltip}
                  className="flex-dropdown-and-label"
                >
                  <div className="label-icon-container">
                    <AnswersIcon className="icon-clr" /> Questions
                  </div>

                  <Select
                    className="select-box-tool-box"
                    size="small"
                    id="questions_selected"
                    value={[...toolKitSettings.questions_selected]}
                    label="questions_selected"
                    name="questions_selected"
                    multiple={true}
                    disabled={markedDocuments.length === 0}
                    onChange={updateToolKit}
                    input={<OutlinedInput id="select-multiple-checkbox" label="Questions" />}
                    renderValue={(selected) =>
                      selected
                        .map(
                          (value) =>
                            settingsArr[3].options.find((option) => option.value === value).label
                        )
                        .join(', ')
                    }
                  >
                    {settingsArr[3].options.map((option) => (
                      <MenuItem key={uuidv4()} value={option.value}>
                        <Checkbox
                          checked={toolKitSettings.questions_selected.indexOf(option.value) > -1}
                        />
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            )}

            {!isMobile && (
              <div className="tool-tip-container">
                <div
                  className={activeTooltip.key === 'validations_selected' ? 'tooltip show' : 'tooltip'}
                >
                  Allows selection of options for bot answers
                </div>

                <div
                  onMouseEnter={() => showTooltip('validations_selected')}
                  onMouseLeave={hideTooltip}
                  className="flex-dropdown-and-label"
                >
                  <div className="label-icon-container">
                    <ValidationsIcon className="icon-clr" /> Validations
                  </div>

                  <Select
                    className="select-box-tool-box"
                    size="small"
                    id="validations_selected"
                    value={[...toolKitSettings.validations_selected]}
                    label="validations_selected"
                    name="validations_selected"
                    multiple={true}
                    onChange={updateToolKit}
                    input={<OutlinedInput id="select-multiple-checkbox" label="Validations" />}
                    renderValue={(selected) =>
                      selected
                        .map(
                          (value) =>
                            settingsArr[5].options.find((option) => option.value === value).label
                        )
                        .join(', ')
                    }
                  >
                    {settingsArr[5].options.map((option) => (
                      <MenuItem key={uuidv4()} value={option.value}>
                        <Checkbox
                          checked={toolKitSettings.validations_selected.indexOf(option.value) > -1}
                        />
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            )}
            <div
              onMouseEnter={() => showTooltip('status')}
              onMouseLeave={hideTooltip}
              className="flex-dropdown-and-label"
            >
              <div className="label-icon-container">
                <VectorType className="icon-clr" /> Vectors Type
              </div>

              <Select
                className="select-box-tool-box"
                size="small"
                id="embedding_version"
                value={toolKitSettings.embedding_version}
                label="embedding_version"
                name="embedding_version"
                onChange={updateToolKit}
                disabled={!disabledEmbeddingVersion}
              >
                {settingsArr[7].options.map((option) => (
                  <MenuItem key={uuidv4()} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
