import { useEffect, useRef, useState } from 'react'
import { questionsPopUp } from '@/constants/questions'
import './popUpQuestions.scss'
import axios from '@/configs/axios.js'
import { ReactComponent as AddNewPromptImg } from '@/assets/add_new_prompts.svg'
import { ReactComponent as PromptsPanel } from '@/assets/prompts-panel.svg'
import { ReactComponent as ThreeDotsMobile } from '@/assets/three-dots-mobile.svg'
import { ReactComponent as EditPrompt } from '@/assets/edit-prompt.svg'
import { ReactComponent as DeletePrompt } from '@/assets/delete-prompt.svg'
import moment from 'moment/moment'
import { PromptOptionPopUp } from '@/components/PopupBanner/PromptOptionPopUp'
import { useAtom } from 'jotai'
import { prevQuestionsAtoms } from '@/atoms/questions'
import { countChosenPromptsAtom } from '@/atoms/prompts'
import { userAtom } from '@/atoms/general'

const promptsFromSession = JSON.parse(sessionStorage.getItem('prompts'))

export const PopUpQuestions = ({
  isOpenPopUp,
  isPageEnglish,
  setIsOpenPopUp,
  promptsToDb,
  promptsToShowUser,
  setPromptsToShowUser,
}) => {
  //// atoms
  const [, setCountChosenPrompts] = useAtom(countChosenPromptsAtom) //global state
  const [user, setUser] = useAtom(userAtom)
  const [preQuestionCheckBoxData, setPreQuestionCheckBoxData] = useAtom(prevQuestionsAtoms)
  //// end atoms
  const [checkboxQuestions, setCheckboxQuestions] = useState([])
  const [mobileToolKitPrompt, setMobileToolKitPrompt] = useState('')
  const [filter, setFilter] = useState('')
  const [isAddTemplate, setIsAddTemplate] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [inputsNewTemplate, setInputsNewTemplate] = useState({
    subject: '',
    content: '',
    description: '',
  })
  const [checkboxData, setCheckboxData] = useState(promptsFromSession ? promptsFromSession : {})

  const isFirstRun = useRef(true)

  useEffect(() => {
    if (user.id) {
      const setDefaultPrompts = async () => {
        const defaultPrompts = await getDefaultPrompts()
        // console.log(defaultPrompts)
        setCheckboxQuestions([...defaultPrompts])
      }

      const getPrompts = async () => {
        let { data: userPrompts } = await axios.get(`/api/prompts/${user.id}`)
        // console.log(promptsFromSession)
        // console.log(userPrompts)
        setPromptsToShowUser([...userPrompts])
        if (userPrompts.length > 0) {
          let newStr = ''

          for (var key in promptsFromSession) {
            if (promptsFromSession[key]) {
              userPrompts.map((ques) => {
                if (ques.subject === key) {
                  if (ques.isOneTimePrompt) {
                    sessionStorage.setItem('conversation', ques.content + ' . Human: ')
                  } else {
                    newStr += ' ' + ques.content
                  }
                  promptsToDb.current.push(ques.content)
                }
              })
            }
          }
          setPreQuestionCheckBoxData((prev) => prev + ' ' + newStr)
        }

        if (promptsFromSession) {
          let newStr = ''
          for (var key in promptsFromSession) {
            if (promptsFromSession[key]) {
              questionsPopUp.map((ques) => {
                if (ques.name === key) {
                  if (ques.isOneTimePrompt) {
                    sessionStorage.setItem('conversation', ques.content + ' . Human: ')
                  } else {
                    newStr += ' ' + ques.content
                  }
                  promptsToDb.current.push(ques.content)
                }
              })
            }
          }
          setPreQuestionCheckBoxData((prev) => prev + ' ' + newStr)
        }
      }

      setDefaultPrompts()
      getPrompts()
    }
  }, [user])

  useEffect(() => {
    const filterPrompts = async () => {
      let defaultPrompts = await getDefaultPrompts()
      let userPrompts = await axios.get(`/api/prompts/${user.id}`)

      let resForCustom = userPrompts.data.filter((item) => item.subject.includes(filter))
      let resForCustomDefault = defaultPrompts.filter((item) => item.subject.includes(filter))
      setCheckboxQuestions([...resForCustomDefault])
      setPromptsToShowUser([...resForCustom])
    }

    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    } else {
      filterPrompts()
    }
  }, [filter])

  const getDefaultPrompts = async () => {
    const { data } = await axios.get('/api/prompts/default')
    return [...data]
  }

  const editPrompt = (ev, q) => {
    ev.stopPropagation()

    setIsAddTemplate(true)
    setIsEdit(true)
    setInputsNewTemplate({ ...q })
  }

  const handleChange = ({ target }) => {
    const { value } = target
    setFilter(value)
  }
  const handleNewTemplateChange = ({ target }) => {
    const { name, value } = target
    setInputsNewTemplate((prev) => ({ ...prev, [name]: value }))
  }

  function hasTrueValue(obj) {
    for (let key in obj) {
      if (obj[key] === true) {
        return true
      }
    }
    return false
  }

  const goBack = () => {
    setIsAddTemplate(false)
    setIsEdit(false)
    setInputsNewTemplate({ subject: '', content: '' })
    setFilter('')
  }

  const deletePrompt = async (prompt) => {
    if (window.confirm('are you sure?')) {
      try {
        await axios.delete(`/api/prompts/${prompt.id}`)
      } catch (error) {
        console.log(error)
      }
      await resetPrompts()
      goBack()
    } else return
  }

  const EditTemplateQuestion = async () => {
    if (!inputsNewTemplate.subject && !inputsNewTemplate.content) {
      alert('please fill all fields')
      return
    }
    try {
      await axios.put('/api/prompts', {
        subject: inputsNewTemplate.subject,
        content: inputsNewTemplate.content,
        description: inputsNewTemplate.description,
        changedAt: Date.now(),
        id: inputsNewTemplate.id,
      })
    } catch (error) {
      console.log(error)
    }
    await resetPrompts()
    goBack()
  }

  const resetPrompts = async () => {
    let defaultPrompts = await getDefaultPrompts()
    let userPrompts = await axios.get(`/api/prompts/${user.id}`)

    setCheckboxQuestions([...defaultPrompts])
    setPromptsToShowUser([...userPrompts.data])
  }

  const addTemplateQuestion = async () => {
    if (!inputsNewTemplate.subject && !inputsNewTemplate.content) {
      alert('please fill all fields')
      return
    }

    await axios.post('/api/prompts', {
      subject: inputsNewTemplate.subject,
      content: inputsNewTemplate.content,
      description: inputsNewTemplate.description,
      createdAt: Date.now(),
      userId: user.id,
    })
    await resetPrompts()
    goBack()
  }

  const handleCheckBoxClicks = (name, ques, isOneTimePrompt) => {
    setCheckboxData((prev) => ({ ...checkboxData, [name]: !prev[name] }))
    sessionStorage.setItem('prompts', JSON.stringify({ ...checkboxData, [name]: !checkboxData[name] }))
    let newStr = preQuestionCheckBoxData
    if (!checkboxData[name]) {
      setCountChosenPrompts((prev) => prev + 1)
      newStr += ' ' + ques
      if (!isOneTimePrompt) {
        sessionStorage.setItem('conversation', ques + ' . Human: ')
      } else {
        setPreQuestionCheckBoxData(newStr)
      }

      promptsToDb.current.push(ques)
    } else {
      setCountChosenPrompts((prev) => prev - 1)
      newStr = preQuestionCheckBoxData.replace(ques, '')
      setPreQuestionCheckBoxData(newStr)

      let newPrompts = promptsToDb.current
      var index = newPrompts.indexOf(ques)
      if (index !== -1) {
        newPrompts.splice(index, 1)
      }
      promptsToDb.current = [...newPrompts]
    }
  }
  return (
    <>
      {isOpenPopUp && (
        <div
          onClick={() => {
            setIsOpenPopUp(false)
          }}
          className="pop-up-full-screen"
        >
          {!isAddTemplate && (
            <div
              onClick={(ev) => {
                ev.stopPropagation()
              }}
              className={isOpenPopUp ? 'pop-up-container' : 'dont-show'}
            >
              <button className="close-btn" onClick={() => setIsOpenPopUp(false)} />
              <div className="title-header margin-0">
                <PromptsPanel />
                <h1 className="doucument-panel-header">Prompts Panel</h1>
              </div>

              <div className="inputs-container">
                <input
                  onChange={handleChange}
                  value={filter}
                  style={{ direction: isPageEnglish ? 'ltr' : 'rtl' }}
                  className="search-pop-up"
                  type="text"
                  placeholder={isPageEnglish ? 'Search prompt...' : 'חפש מסמך...'}
                />
                <div
                  tabIndex="0"
                  alt="add prompt"
                  title="add prompt"
                  onClick={() => setIsAddTemplate(true)}
                  className="add-prompt-btn"
                >
                  <div className="line">
                    <div className="line-horizontal" />
                  </div>
                  <p>NEW PROMPT</p>
                </div>
              </div>

              <div className="checkboxes-container">
                {promptsToShowUser.length > 0 &&
                  promptsToShowUser.map((q) => (
                    <div
                      key={q.id}
                      className={
                        checkboxData[q.subject] ? 'prompt-container  chosen' : 'prompt-container'
                      }
                    >
                      <div className="prompt-name mobile-gone">
                        <input
                          type="checkbox"
                          checked={checkboxData[q.subject]}
                          onClick={() => handleCheckBoxClicks(q.subject, q.content, q.isOneTimePrompt)}
                        />
                        <p>{q.subject}</p>
                      </div>
                      <div className="prompt-name-date descktop-gone">
                        <input
                          type="checkbox"
                          checked={checkboxData[q.subject]}
                          onClick={() => handleCheckBoxClicks(q.subject, q.content, q.isOneTimePrompt)}
                        />
                        <div className="name-date">
                          <p>{q.subject}</p>
                          <p style={{ fontSize: '10px' }}>{moment(q.created_at).format('D MMM YYYY')}</p>
                        </div>
                      </div>
                      <p className="mobile-gone">{user.userName}</p>
                      <p className="mobile-gone">{moment(q.created_at).format('MMM D')}</p>
                      <div
                        onClick={(ev) => editPrompt(ev, q)}
                        className="edit-img-prompt-container mobile-gone"
                      >
                        <EditPrompt />
                        <p>Edit</p>
                      </div>
                      <div
                        onClick={(ev) => {
                          deletePrompt(q)
                          ev.stopPropagation()
                        }}
                        className="delete-img-prompt-container mobile-gone"
                      >
                        <DeletePrompt />
                        <p>Remove</p>
                      </div>

                      <div className="three-dots-mobile">
                        <ThreeDotsMobile
                          onClick={(ev) => {
                            setMobileToolKitPrompt(q)
                            ev.stopPropagation()
                          }}
                        />
                      </div>

                      {/* <div className="icons-prompt-container"> */}
                      {/* <LinePromptIcon /> */}
                      {/* </div> */}
                    </div>
                  ))}
                {checkboxQuestions.map((q) => (
                  <div
                    key={q.id}
                    className={checkboxData[q.subject] ? 'prompt-container  chosen' : 'prompt-container'}
                  >
                    <div className="prompt-name">
                      <input
                        type="checkbox"
                        checked={checkboxData[q.subject]}
                        onClick={() => handleCheckBoxClicks(q.subject, q.content, q.isOneTimePrompt)}
                      />
                      <p>{q.subject}</p>
                    </div>
                    <p>Default</p>
                  </div>
                ))}
              </div>
              <button
                className={
                  !hasTrueValue(checkboxData) ? 'add-new-template-btn disable' : 'add-new-template-btn'
                }
                onClick={() => setIsOpenPopUp(false)}
                disabled={!hasTrueValue(checkboxData)}
              >
                Submit
              </button>
            </div>
          )}
          {isAddTemplate && (
            <div
              onClick={(ev) => ev.stopPropagation()}
              className={isOpenPopUp ? 'pop-up-container add-template' : 'dont-show'}
            >
              <div className="new-template-container">
                <div className="navigation">
                  <button className="back-btn" onClick={goBack} />
                  <button className="close-btn" onClick={() => setIsOpenPopUp(false)} />
                </div>
                <div className="title-header">
                  <AddNewPromptImg />
                  <h2 className="title-edit-pop-up">{isEdit ? 'Edit a prompt' : 'Add new prompts'}</h2>
                </div>
                <div className="input-container">
                  <p> {isPageEnglish ? 'Title:' : 'Title:'}</p>

                  <input
                    name="subject"
                    value={inputsNewTemplate.subject}
                    onChange={handleNewTemplateChange}
                    type="text"
                  />
                </div>
                <div className="input-container">
                  <p> {isPageEnglish ? 'Description:' : 'Description:'}</p>

                  <input
                    name="description"
                    value={inputsNewTemplate.description}
                    onChange={handleNewTemplateChange}
                    type="text"
                  />
                </div>
                <div className="input-container">
                  <p> {isPageEnglish ? 'Content:' : 'Content:'}</p>

                  <textarea
                    name="content"
                    value={inputsNewTemplate.content}
                    onChange={handleNewTemplateChange}
                    type="text"
                  />
                </div>
                {!isEdit ? (
                  <>
                    <button
                      className={
                        inputsNewTemplate.content && inputsNewTemplate.subject
                          ? 'add-new-template-btn'
                          : 'add-new-template-btn  disable'
                      }
                      disabled={!(inputsNewTemplate.content && inputsNewTemplate.subject)}
                      onClick={addTemplateQuestion}
                    >
                      Add to list
                    </button>
                  </>
                ) : (
                  <button className="add-new-template-btn" onClick={EditTemplateQuestion}>
                    Save
                  </button>
                )}
              </div>
            </div>
          )}
          {mobileToolKitPrompt && (
            <PromptOptionPopUp
              editPrompt={editPrompt}
              mobileToolKitPrompt={mobileToolKitPrompt}
              setMobileToolKitPrompt={setMobileToolKitPrompt}
            />
          )}
        </div>
      )}
    </>
  )
}
