// import { atomWithReducer } from "jotai/utils";
import { atom } from 'jotai'

// export const groupsReducer = (state, action) => {
//   switch (action.type) {
//     case "FETCH_ALL":
//       return { groups: action.payload };

//     case "UPDATE_USER_TOKEN_LIMIT":
//       return {
//         ...state,
//         groups: state.groups.map((group) =>
//           group.group_name === action.payload.group_name
//             ? {
//                 ...group,
//                 users: group.users.map((user) =>
//                   user.id === action.payload.id
//                     ? {
//                         ...user,
//                         token_limit: action.payload.token_limit,
//                       }
//                     : user
//                 ),
//               }
//             : group
//         ),
//       };
//     case "MODIFY_AVAILABLE_MODELS":
//       return {
//         ...state,
//         groups: state.groups.map((group) =>
//           group.groupName === action.payload.groupName
//             ? {
//                 ...group,
//                 users: group.users.map((user) =>
//                   user.name === action.payload.userName
//                     ? {
//                         ...user,
//                         availableModels: action.payload.availableModels,
//                       }
//                     : user
//                 ),
//               }
//             : group
//         ),
//       };
//     default:
//       return state;
//   }
// };
// export const groupsAtom = atomWithReducer([], groupsReducer);

export const groupsAtom = atom([])
export const searchAtom = atom('')

export const filteredGroupsAtom = atom((get) => {
  const groups = get(groupsAtom)
  const searchName = get(searchAtom)

  // .filter((group) => group.users.length > 0);
  const result =
    searchName.length > 0
      ? groups
          .map((group) => ({
            ...group,
            users: group.users.filter(
              (user) =>
                user.name !== null &&
                user.name.toLowerCase().includes(searchName)
            ),
          }))
          .filter((group) => group.users.length > 0)
      : groups;
  console.log(result);
  return result;
});

export const groupsAtomWithUpdates = atom(null, (get, set, action) => {
  const groups = get(groupsAtom)
  const { groupId, userId, val } = action.payload

  switch (action.type) {
    case 'updateUserToken':
      set(
        groupsAtom,
        groups.map((group) =>
          group.group_id !== groupId
            ? group
            : {
                ...group,
                users: group.users.map((user) =>
                  user.id !== userId ? user : { ...user, token_limit: val }
                ),
              }
        )
      )
      break
    case 'updateUserModel':
      set(
        groupsAtom,
        groups.map((group) =>
          group.group_id !== groupId
            ? group
            : {
                ...group,
                users: group.users.map((user) => (user.id !== userId ? user : { ...user, model: val })),
              }
        )
      )
      break
    case 'updateGroupModel':
      set(
        groupsAtom,
        groups.map((group) =>
          group.group_id !== groupId
            ? group
            : {
                ...group,
                default_model: val,
                users: group.users.map((user) => ({ ...user, model: val })),
              }
        )
      )
      break
    case 'updateGroupToken':
      set(
        groupsAtom,
        groups.map((group) =>
          group.group_id !== groupId
            ? group
            : {
                ...group,
                default_max_tokens_per_user: val,
                users: group.users.map((user) => ({
                  ...user,
                  token_limit: val,
                })),
              }
        )
      )
      break

    default:
      console.error('Action type not recognized: ', action.type)
      break
  }
})
