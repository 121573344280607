import React, { useState } from 'react'
import { Modal } from '@/components/Modal/Modal'

import axios from '@/configs/axios.js';
import TextField from '@mui/material/TextField'

import { useAtom } from 'jotai'
import Cookies from 'js-cookie'

import { activeFolderAtom } from '@/atoms/folders.js'
import { userAtom } from '@/atoms/general'
import './add-edit-modal.scoped.css'

import { markedPromptsAtom } from '@/atoms/prompts'
import { getPromptsAndFoldersAtom } from '@/api/prompts'

function AddEditModalPrompt({ isOpen, onClose, action, header, subHeader, closeAddEditPopup }) {
  const [isLoading, setIsLoading] = useState(false)
  const [, getDocumentsAndFolders] = useAtom(getPromptsAndFoldersAtom)
  const [activeFolder] = useAtom(activeFolderAtom)
  const [user] = useAtom(userAtom)
  const [markedPrompts, setMarkedPrompts] = useAtom(markedPromptsAtom)
  const editedPrompt = markedPrompts[0]
  //atoms end
  console.log(markedPrompts)
  const [promptsData, setPromptsData] = useState(
    action == 'add'
      ? {
          title: '',
          description: '',
          content: '',
        }
      : {
          title: editedPrompt.title,
          description: editedPrompt.description,
          content: editedPrompt.content,
          isAdmin: editedPrompt.isAdmin,
        }
  )
  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      if (action == 'add') {
        await axios.post('/api/prompts', {
          title: promptsData.title,
          description: promptsData.description,
          content: promptsData.content,
          userId: user.id,
          clientId: Cookies.get('client-id'),
          folderId: activeFolder && activeFolder.id,
        })

        setIsLoading(true)
      } else {
        console.log(promptsData.prompt_id, 'elior')
        
        await axios.put(`/api/prompts/${editedPrompt.prompt_id}`, {
          title: promptsData.title,
          description: promptsData.description,
          content: promptsData.content,
          userId: user.id,
          clientId: Cookies.get('client-id'),
          folderId: activeFolder && activeFolder.id,
        })
        
        setIsLoading(true)
        onClose()
        setMarkedPrompts([])
        // setIsLoading(false)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const checkIfDisabled = () => {
    return !(promptsData.title.trim() && promptsData.content.trim())
  }

  const handleTextField = (e) => {
    setPromptsData((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const inputsStyle = {
    style: { color: 'var(--text-clr)', opacity: '0.5' },
  }
  return (
    <Modal
      header={isLoading ? 'adding new prompt' : header}
      subHeader={isLoading ? 'You will be notified when the prompt is uploaded' : subHeader}
      onClose={onClose}
      isOpen={isOpen}
      isTimeout={isLoading}
      style={isLoading ? { height: 'auto' } : {}}
    >
      {!isLoading ? (
        <form className="modal-content" method="POST" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            InputLabelProps={inputsStyle}
            value={promptsData.title}
            variant="filled"
            label="Title"
            name="title"
            InputProps={{
              disableUnderline: true, // Disables the underline
            }}
            onChange={handleTextField}
          />
          <TextField
            fullWidth
            InputLabelProps={inputsStyle}
            variant="filled"
            label="Prompt Content"
            name="content"
            value={promptsData.content}
            multiline
            rows={10}
            onChange={handleTextField}
            InputProps={{
              disableUnderline: true, // Disables the underline
            }}
          />

          <h3 className="prompt-purpose-title">Purpose of a prompt</h3>
          <TextField
            fullWidth
            InputLabelProps={inputsStyle}
            value={promptsData.description}
            name="description"
            variant="filled"
            label="Purpose of a prompt"
            InputProps={{
              disableUnderline: true,
            }}
            onChange={handleTextField}
          />
          {action == 'add' || !markedPrompts[0].isAdmin ? (
            <button disabled={checkIfDisabled()} className="submit-btn-document" type="submit">
              {action == 'add' ? 'Add Prompt' : 'Edit Prompt'}
            </button>
          ) : (
            <></>
          )}
        </form>
      ) : (
        <></>
      )}
    </Modal>
  )
}

export default AddEditModalPrompt
