import React from 'react'
import './main-panel.scoped.scss'
import { useState } from 'react'
import { ReactComponent as LeftArrowIcon } from '@/assets/arrow-left-icon.svg'
import { ReactComponent as RightArrowIcon } from '@/assets/arrow-right-icon.svg'

import { ReactComponent as HistoryIcon } from '@/assets/history-icon.svg'
import { ReactComponent as HistoryIconUnActive } from '@/assets/history-icon-unactive.svg'

import { ReactComponent as FilterIconUnActive } from '@/assets/filter-icon-unactive.svg'
import { ReactComponent as FilterIcon } from '@/assets/filter-icon-active.svg'
import {
  socketAtom,
  isMobileAtom,
  userAtom,
  requestCancelTokenAtom,
  isloadingAtom,
} from '@/atoms/general'
import { ReactComponent as ChatboxClose } from '@/assets/chatbox-close-icon-lm.svg'

import { useAtom } from 'jotai'
import { History } from '../History/History'
import { ToolKit } from '../Toolkit/ToolKit'
function MainPanel() {
  const [isCloseToolKit, setIsCloseToolKit] = useState(false)

  const [toggleTabs, setToggleTabs] = useState('control-panel')
  const [isMobile, setIsMobile] = useAtom(isMobileAtom)

  const handleClosedToolkitPress = (name) => {
    setIsCloseToolKit(false)
    setToggleTabs(name)
  }
  const activateControl = () => {
    setToggleTabs('control-panel')
  }
  const handleIsCloseToolKit = () => {
    setIsCloseToolKit((prev) => {
      return !prev
    })
  }
  const activateHistory = () => {
    setToggleTabs('history')
  }

  return (
    <>
      <div className={`tool-kit ${isCloseToolKit ? 'closed-toolkit' : ''} `}>
        {!isMobile ? (
          <button className="toggle-nav" onClick={handleIsCloseToolKit}>
            {!isCloseToolKit ? <RightArrowIcon /> : <LeftArrowIcon />}
          </button>
        ) : (
          <>
            {' '}
            <div className="close-btn-mobile-container">
              {!isCloseToolKit && (
                <button
                  className="close-toolkit-btn"
                  // className='toggle-nav-mobile'
                  onClick={handleIsCloseToolKit}
                >
                  {' '}
                  <ChatboxClose />
                  {/* {!isCloseToolKit ? <RightArrowIcon/> : <LeftArrowIcon/> }  */}
                </button>
              )}
            </div>
          </>
        )}
        {!isCloseToolKit ? (
          <>
            <div className="user-container">
              <button
                disabled={toggleTabs === 'control-panel'}
                className={`tool-kit-title control-panel ${
                  toggleTabs === 'control-panel' ? 'active-control' : 'unactive-control'
                }`}
                onClick={activateControl}
              >
                {toggleTabs === 'control-panel' ? <FilterIconUnActive /> : <FilterIcon />}
                Control Panel
              </button>
              <button
                disabled={toggleTabs === 'history'}
                className={`tool-kit-title history ${
                  toggleTabs === 'history' ? 'active-control' : 'unactive-control'
                }`}
                onClick={activateHistory}
              >
                {toggleTabs === 'history' ? <HistoryIconUnActive /> : <HistoryIcon />}
                History
              </button>
            </div>
            <>
              {toggleTabs === 'control-panel' && <div style={toggleTabs === 'control-panel' ? {} : { display: 'none' }}>
                <ToolKit />
              </div>}
           { toggleTabs === 'history' &&  <div style={toggleTabs === 'history' ? {} : { display: 'none' }}>
                <History setIsCloseToolKit={setIsCloseToolKit} />
              </div>}
              {/* {toggleTabs==='control-panel' ?  <ToolKit  />:   <History setIsCloseToolKit={setIsCloseToolKit} />} */}
            </>
          </>
        ) : (
          <div className="closed-toolkit-flex">
            <button onClick={() => handleClosedToolkitPress('control-panel')}>
              {' '}
              {toggleTabs === 'control-panel' ? <FilterIconUnActive /> : <FilterIcon />}{' '}
            </button>
            <button onClick={() => handleClosedToolkitPress('history')}>
              {' '}
              {toggleTabs === 'history' ? <HistoryIconUnActive /> : <HistoryIcon />}{' '}
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default MainPanel
