export function removeFileById(files, subfolders, fileIds) {

    // Create a copy of the top-level files and try to remove the file
    const newTopLevelFiles = files.filter(file => !fileIds.includes(file.doc_id));
  
    const newTopLevelFolders = subfolders.map(folder => removeFileFromFolder(folder, fileIds));
  
  
    return { files: newTopLevelFiles, subfolders: newTopLevelFolders };
  }
  
  function removeFileFromFolder(folder, fileIds) {
    const newFolder = { ...folder, files: folder.files.filter(file => !fileIds.includes(file.doc_id)) };
  
  
    newFolder.subfolders = folder.subfolders.map(subfolder => removeFileFromFolder(subfolder, fileIds));
    return newFolder;
  }