import axios from '@/configs/axios.js';

export const createTranslates = async (translates) => {
  await axios.post(`/api/translates/create-translates`, {
    translates,
  });
};

export const getTranslates = async (botId) => {
  const result = await axios.get(`/api/translates/${botId}`);
  return result.data;
};

export const updateTranslates = async (data) => {
  const { translates, date } = data;
  await axios.post(`/api/translates/update-translates`, {
    translates,
    date,
  });
};

export const deleteTranslates = async (translates) => {
  const translatesIds = translates.map((translate) => translate.id);
  await axios.delete(`/api/translates`, {
    data: { translatesIds },
  });
};
