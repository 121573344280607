import React from 'react'
import './outline-btn.scoped.css'
function OutlineBtn({type,disabled,children,onClick}) {

  return (
    <button  onClick={onClick} type={type} disabled={disabled} className='outline-btn'> 
        {children}
    </button>
  )
}

export default OutlineBtn