import axios from '@/configs/axios.js'
import React, { useState, useEffect, useRef } from 'react'
import { Modal } from '@/components/Modal/Modal'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { v4 as uuidv4 } from 'uuid'
import { useAtom } from 'jotai'
import Cookies from 'js-cookie'
import { activeFolderAtom } from '@/atoms/folders.js'
import { userAtom } from '@/atoms/general'
import './add-edit-modal.scoped.css'
import { ReactComponent as UploadFile } from '@/assets/uplaod-files.svg'
import { ReactComponent as CloseIcon } from '@/assets/close-icon-lm.svg'
import { ReactComponent as OpenOptionsIcon } from '@/assets/vector-option-to-open-icon.svg'
import { ReactComponent as CloseOptionsIcon } from '@/assets/vector-option-to-close-icon.svg'
import { upload } from '@testing-library/user-event/dist/upload'
import { ProgressBar } from '@/components/ProgressBar/ProgressBar'
import { socketAtom } from '@/atoms/general'
import { markedDocumentsAtom } from '@/atoms/documents'
import { fileUploadCallbackAtom, fileErrorCallbackAtom } from '@/atoms/files'
import { type } from '@testing-library/user-event/dist/type'
import { treeAtom } from '@/atoms/folders'
import { addFile } from '@/services/files/addFile'
import { removeFileById } from '@/services/files/removeFile'
import { ReactComponent as OptionSelected } from '@/assets/check-icon-active.svg'
import { ReactComponent as OptionUnSelected } from '@/assets/check-icon-lm.svg'
import Snackbar from '@/components/Snackbar/Snackbar'
import { SnackbarProvider, useSnackbar } from 'notistack'

function AddEditModal({ isOpen, onClose, action, header, subHeader }) {
  const [toggle, setToggle] = useState('file')
  const [uploadFile, setUploadFile] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [progressPercent, setProgressPercent] = useState(0)
  const [isAdvancedOptionOpen, setIsAdvancedOptionOpen] = useState(false)
  //atoms
  const [, setFileUploadCallback] = useAtom(fileUploadCallbackAtom)
  const [, setFileErrorCallback] = useAtom(fileErrorCallbackAtom)
  const [entireTree, setEntireTree] = useAtom(treeAtom)
  const [activeFolder] = useAtom(activeFolderAtom)
  const [apiModel, setApiModel] = useState('Gpt-4o')
  const [user] = useAtom(userAtom)
  const [socket] = useAtom(socketAtom)
  const [markedDocuments, setMarkedDocuments] = useAtom(markedDocumentsAtom)
  console.log(markedDocuments)

  let editedDocument = useRef(markedDocuments[0])
  const company = user.email.split('@')[1]
  let vectorData = useRef()
  if (action === 'edit') {
    vectorData.current = {
      title: {
        value: editedDocument.current.vectorMethods.includes('title'),
        label: 'Divide by Titles',
      },
      questions: {
        value: editedDocument.current.vectorMethods.includes('question'),
        label: 'Questions & Answers',
      },
      summary: {
        value: editedDocument.current.vectorMethods.includes('summary'),
        label: 'Document Summary',
      },
      recursive: {
        value: editedDocument.current.vectorMethods.includes('recursive'),
        label: 'recursive',
      },
      small: {
        value: editedDocument.current.vectorMethods.includes('small'),
        label: 'small',
      },
      llama: {
        value: editedDocument.current.vectorMethods.includes('llama'),
        label: 'llama',
      },
      large: {
        value: editedDocument.current.vectorMethods.includes('large'),
        label: 'large',
      },
      'titles-summary': {
        value: editedDocument.current.vectorMethods.includes('titles-summary'),
        label: 'titles-summary',
      },
      'titles-chapters': {
        value: editedDocument.current.vectorMethods.includes('titles-chapters'),
        label: 'titles-chapters',
      },
      Flex: {
        value: editedDocument.current.vectorMethods.includes('Flex'),
        label: 'Flex',
      },
    }
  }
  useEffect(() => {}, [])
  //atoms end
  console.log(markedDocuments)
  const [documentData, setDocumentData] = useState(
    action == 'add'
      ? {
          title: '',
          // description: '',
          url: '',
          vectorLanguage: 'english (UK)',
          chunkSize: 500,
          chunkOverLap: 15,
          vectorMethods: {
            // recursive: { value: false, label: 'recursive' },
            llama: { value: false, label: 'llama' },
            Text: { value: true, label: 'Text' },
            'Text & Image': { value: false, label: 'Text & Image' },
            Flex: { value: false, label: 'Flex' },
            // title: { value: false, label: 'Divide by Titles' },
            questions: { value: false, label: 'Questions & Answers' },
            summary: { value: false, label: 'Document Summary' },
            small: { value: false, label: 'small embedding' },
            large: { value: false, label: 'large embedding' },
            'titles-summary': { value: false, label: 'titles-summary' },
            'titles-chapters': { value: false, label: 'titles-chapters' },
          },
        }
      : {
          title: editedDocument.current.docTitle,
          // description: editedDocument.current.docDescription,
          url: editedDocument.current.url ? editedDocument.current.url : ' ',
          vectorLanguage: 'english (UK)',
          vectorMethods: {
            llama: {
              llama: editedDocument.current.vectorMethods.includes('llama'),
              label: 'llama',
            },
            Text: {
              Text: editedDocument.current.vectorMethods.includes('Text'),
              label: 'Text',
            },
            Flex: {
              Flex: editedDocument.current.vectorMethods.includes('Flex'),
              label: 'Flex',
            },
            'Text & Image': {
              'Text & Image': editedDocument.current.vectorMethods.includes('Text & Image'),
              label: 'Text & Image',
            },
            // recursive: {
            //   recursive: editedDocument.current.vectorMethods.includes('recursive'),
            //   label: 'recursive',
            // },
            // title: {
            //   value: editedDocument.current.vectorMethods.includes('title'),
            //   label: 'Divide by Titles',
            // },
            summary: {
              value: editedDocument.current.vectorMethods.includes('summary'),
              label: 'summary',
            },
            'titles-summary': {
              value: editedDocument.current.vectorMethods.includes('titles-summary'),
              label: 'titles-summary',
            },
            'titles-chapters': {
              value: editedDocument.current.vectorMethods.includes('titles-chapters'),
              label: 'titles-chapters',
            },

            questions: {
              value: editedDocument.current.vectorMethods.includes('question'),
              label: 'Questions & Answers',
            },
            small: {
              value: editedDocument.current.vectorMethods.includes('small'),
              label: 'Document small',
            },
          },
          large: {
            value: editedDocument.current.vectorMethods.includes('large'),
            label: 'Document large',
          },
        }
  )

  const handleRemoveFile = (e) => {
    e.preventDefault()
    setUploadFile()
  }
  const handleFiles = (e) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    console.log(e.target.files[0])
    setUploadFile(formData)
  }
  const handleChangeVectorMethod = (type) => {
    console.log(type)
    setDocumentData((prev) => {
      return {
        ...prev,
        vectorMethods: {
          ...prev.vectorMethods,
          [type]: {
            ...prev.vectorMethods[type],
            value: !prev.vectorMethods[type].value,
          },
        },
      }
    })
  }

  const isIncludeFlex = () => {
    let chosenVectorMethods = Object.keys(documentData.vectorMethods).filter(
      (vectorMethod) => documentData.vectorMethods[vectorMethod].value
    )
    return chosenVectorMethods.includes('Flex')
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    let chosenVectorMethods = Object.keys(documentData.vectorMethods).filter(
      (vectorMethod) => documentData.vectorMethods[vectorMethod].value
    )

    // chosenVectorMethods = chosenVectorMethods.split(',').filter((vector)=>vector.trim())
    // console.log(chosenVectorMethods)
    // setMarkedDocuments([])
    try {
      if (action == 'add') {
        if (
          (uploadFile.get('file').name.includes('xlsx') ||
            uploadFile.get('file').name.includes('csv')) &&
          chosenVectorMethods.some((method) => method !== 'Text' && method !== 'llama')
        ) {
          alert('csv and excel file only works with llama/llamapipeline')
          return
        }

        setIsLoading(true)

        const finalFormData = new FormData()
        finalFormData.append('docTitle', documentData.title)
        finalFormData.append('apiModel', apiModel.toLowerCase())
        finalFormData.append('docDescription', '')
        finalFormData.append('userId', user.id)
        finalFormData.append('clientId', Cookies.get('client-id'))
        finalFormData.append('folderId', activeFolder && activeFolder.id)
        finalFormData.append('chunkSizeFromUser', documentData.chunkSize)
        finalFormData.append('overlapSizeFromUser', documentData.chunkOverLap)

        if (action === 'add') {
          if (toggle === 'file') {
            finalFormData.append('file', uploadFile && uploadFile.get('file'))
          } else {
            finalFormData.append('url', documentData.url)
          }
          finalFormData.append('vectorLanguage', documentData.vectorLanguage)
          finalFormData.append('vectorMethods', JSON.stringify(chosenVectorMethods))
          const { data } = await axios.post(
            `${
              chosenVectorMethods.includes('Text') || chosenVectorMethods.includes('Text & Image')
                ? '/api/uploads'
                : ''
            }/upload`,
            finalFormData
          )

          const newTree = addFile(
            entireTree.files,
            entireTree.subfolders,
            data,
            activeFolder ? activeFolder.id : null
          )

          enqueueSnackbar('Files added successfully', {
            content: (key, message) => <Snackbar type={'success'} message={message} />,
          })
          setEntireTree(newTree)

          onClose()
        }
      } else {
        let finalVectorData = Object.keys(vectorData.current).filter(
          (vectorMethod) => vectorData.current[vectorMethod].value
        )

        let isVectorMethodChange =
          JSON.stringify(finalVectorData) !== JSON.stringify(chosenVectorMethods)
        if (isVectorMethodChange) {
          setIsLoading(true)
        }
        const { data } = await axios.put('/api/documents/edit-doc', {
          docId: editedDocument.current.docId,
          docTitle: documentData.title,
          // docDescription: documentData.description,
          userId: user.id,
          isVectorMethodChange: isVectorMethodChange,
          clientId: Cookies.get('client-id'),
          folderId: activeFolder && activeFolder.id,
          vectorLanguage: documentData.vectorLanguage,
          vectorMethods: JSON.stringify(chosenVectorMethods),
          docNameOrigin: editedDocument.current.docNameOrigin,
          docType: editedDocument.current.docType.trim(),
        })
        let newTree = removeFileById(entireTree.files, entireTree.subfolders, [
          editedDocument.current.docId,
        ])
        newTree = addFile(newTree.files, newTree.subfolders, data, activeFolder ? activeFolder.id : null)
        setEntireTree(newTree)

        if (!isVectorMethodChange) {
          onClose()
        }
      }
    } catch (err) {
      enqueueSnackbar('We encountered a problem uploading, please try again', {
        content: (key, message) => <Snackbar type={'error'} message={message} />,
      })
      console.log(err)
    }
  }
  const checkIfDisabled = () => {
    if (action == 'add') {
      return !(
        (documentData.title.trim() && (uploadFile && toggle === 'file')) ||
        (documentData.url.trim() && toggle === 'url')
      )
    } else {
      return false
    }
  }
  const toggleFile = () => {
    setDocumentData((prev) => ({ ...prev, url: '' }))
    setToggle('file')
  }
  const toggleUrl = () => {
    setUploadFile()
    setToggle('url')
  }
  const handleTextField = (e) => {
    setDocumentData((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  const generateLoadMessage = () => {
    return action === 'add' ? 'adding new document' : 'editing document'
  }
  const inputsStyle = {
    style: { color: 'var(--text-clr)', opacity: '0.5' },
  }

  const updateModel = ({ target }) => {
    const { value } = target
    setApiModel(value)
  }

  return (
    <Modal
      header={isLoading ? generateLoadMessage() : header}
      subHeader={isLoading ? 'You will be notified when the action is done' : subHeader}
      onClose={onClose}
      isOpen={isOpen}
      style={isLoading ? { height: 'auto' } : { height: 'auto' }}
    >
      {!isLoading ? (
        <form className="modal-content" method="POST" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            InputLabelProps={inputsStyle}
            value={documentData.title}
            variant="filled"
            label="Title"
            name="title"
            InputProps={{
              disableUnderline: true, // Disables the underline
            }}
            onChange={handleTextField}
          />
          {/* <TextField
            fullWidth
            InputLabelProps={inputsStyle}
            variant="filled"
            label="Description"
            name="description"
            value={documentData.description}
            multiline
            rows={3}
            onChange={handleTextField}
            InputProps={{
              disableUnderline: true, // Disables the underline
            }}
          /> */}
          {/* {action == 'add' && (
            <div className="url-file-toggler">
              <button
                type="button"
                className={`${toggle === 'file' ? 'active-toggle' : ''} `}
                onClick={toggleFile}
              >
                File
              </button>
              <button
                type="button"
                className={`${toggle === 'url' ? 'active-toggle' : ''} `}
                onClick={toggleUrl}
              >
                URL
              </button>
            </div>
          )} */}
          {action == 'add' && (
            <>
              {toggle === 'file' ? (
                <label className="input-file-container">
                  {!uploadFile ? (
                    <>
                      {' '}
                      <UploadFile />
                      <input
                        className="file-picker-input"
                        style={{ color: 'transparent' }}
                        title=" "
                        accept=".doc, .docx, .pdf ,.xlsx ,.csv"
                        type="file"
                        id="files"
                        name="filename"
                        onChange={handleFiles}
                      />
                      Drag or Attach Your File Here (.xlsx .csv .docx .pdf)
                    </>
                  ) : (
                    <div className="file-return-flex">
                      <h2>{uploadFile.get('file').name} </h2>{' '}
                      <button onClick={handleRemoveFile}>
                        {' '}
                        <CloseIcon />{' '}
                      </button>
                    </div>
                  )}
                </label>
              ) : (
                <TextField
                  fullWidth
                  InputLabelProps={inputsStyle}
                  value={documentData.url}
                  name="url"
                  variant="filled"
                  label="Url"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  onChange={handleTextField}
                />
              )}
            </>
          )}
          <div className="vectors-upper">
            <div className="vectors-methods-description">
              <h4>Vectors options </h4>
              {isAdvancedOptionOpen ? (
                <CloseOptionsIcon onClick={() => setIsAdvancedOptionOpen((prev) => !prev)} />
              ) : (
                <OpenOptionsIcon onClick={() => setIsAdvancedOptionOpen((prev) => !prev)} />
              )}
            </div>
          </div>
          {isAdvancedOptionOpen && (
            <>
              <span style={{ alignSelf: 'baseline' }}>{'(optional,multiple choice)'} </span>
              {user.email.includes('one') && (
                <div className="lower-part-vectors">
                  {/* <button
                    type="button"
                    className={documentData.vectorMethods.questions.value ? 'active-vector' : ''}
                    onClick={() => handleChangeVectorMethod('questions')}
                  >
                    {documentData.vectorMethods.questions.label}
                  </button>
                  <button
                    type="button"
                    className={documentData.vectorMethods.summary.value ? 'active-vector' : ''}
                    onClick={() => handleChangeVectorMethod('summary')}
                  >
                    {documentData.vectorMethods.summary.label}
                  </button> */}
                  {/* <button
                    type="button"
                    className={documentData.vectorMethods.title.value ? 'active-vector' : ''}
                    onClick={() => handleChangeVectorMethod('title')}
                  >
                    {documentData.vectorMethods.title.label}{' '}
                  </button> */}
                  {/* checks for ormat user to */}
                  {company !== 'ormat.com' && (
                    <>
                      {/* <button
                        type="button"
                        className={documentData.vectorMethods.recursive.value ? 'active-vector' : ''}
                        onClick={() => handleChangeVectorMethod('recursive')}
                      >
                        {documentData.vectorMethods.recursive.label}
                      </button>*/}
                      {/* <button
                        type="button"
                        className={documentData.vectorMethods.small.value ? 'active-vector' : ''}
                        onClick={() => handleChangeVectorMethod('small')}
                      >
                        {documentData.vectorMethods.small.label}
                      </button> */}
                      {/* <button type="button" className={documentData.vectorMethods['titles-chapters'].value ? 'active-vector' : ''}
                           onClick={() => handleChangeVectorMethod('titles-chapters')
                        }
                       >{documentData.vectorMethods['titles-chapters'].label}
                        </button>  */}
                      {/* <button
                        type="button"
                        className={
                          documentData.vectorMethods['titles-summary'].value ? 'active-vector' : ''
                        }
                        onClick={() => handleChangeVectorMethod('titles-summary')}
                      >
                        {documentData.vectorMethods['titles-summary'].label}
                      </button> */}

                      <button
                        type="button"
                        style={{ fontSize: '14px' }}
                        className={documentData.vectorMethods['Flex'].value ? 'active-vector' : ''}
                        onClick={() => handleChangeVectorMethod('Flex')}
                      >
                        {documentData.vectorMethods['Flex'].label}
                      </button>

                      <button
                        type="button"
                        style={{ fontSize: '14px' }}
                        className={documentData.vectorMethods['Text'].value ? 'active-vector' : ''}
                        onClick={() => handleChangeVectorMethod('Text')}
                      >
                        {documentData.vectorMethods.Text.label}{' '}
                      </button>

                      <button
                        type="button"
                        style={{ fontSize: '14px' }}
                        className={
                          documentData.vectorMethods['Text & Image'].value ? 'active-vector' : ''
                        }
                        onClick={() => handleChangeVectorMethod('Text & Image')}
                      >
                        {documentData.vectorMethods['Text & Image'].label}{' '}
                      </button>

                      {/* <button
                        type="button"
                        className={documentData.vectorMethods.llama.value ? 'active-vector' : ''}
                        onClick={() => handleChangeVectorMethod('llama')}
                      >
                        {documentData.vectorMethods.llama.label}{' '}
                      </button> */}
                    </>
                  )}
                </div>
              )}
              {isIncludeFlex() && (
                <div className="chunks-options-container">
                  <p>Model</p>

                  <Select
                    className="select-box-tool-box"
                    size="small"
                    id="Model"
                    value={apiModel}
                    label="Model"
                    name="model"
                    onChange={updateModel}
                  >
                    {['Gpt-4o', 'Gpt-4'].map((option) => (
                      <MenuItem key={uuidv4()} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                // <div className="chunks-options-container">
                //   <div className="single-chunk-container">
                //     <p>Model</p>
                //     <input
                //       onChange={(ev) =>
                //         setDocumentData((prev) => ({ ...prev, chunkSize: ev.target.value }))
                //       }
                //       value={documentData.chunkSize}
                //       type="number"
                //       max={600}
                //       min={20}
                //     />
                //   </div>
                // </div>
              )}
              <div className="chunks-options-container">
                <div className="single-chunk-container">
                  <p>Chunk size</p>
                  <input
                    onChange={(ev) =>
                      setDocumentData((prev) => ({ ...prev, chunkSize: ev.target.value }))
                    }
                    value={documentData.chunkSize}
                    type="number"
                    max={4000}
                    min={20}
                  />
                </div>
                <div className="single-chunk-container">
                  <p>Overlap</p>
                  <input
                    onChange={(ev) =>
                      setDocumentData((prev) => ({ ...prev, chunkOverLap: ev.target.value }))
                    }
                    value={documentData.chunkOverLap}
                    type="number"
                    max={100}
                    min={2}
                  />
                </div>
              </div>

              <div className="english-translation-container">
                {/* <img
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    setDocumentData((prev) => ({
                      ...prev,
                      vectorLanguage: prev.vectorLanguage === 'english (UK)' ? 'hebrew' : 'english (UK)',
                    }))
                  }
                  src={
                    documentData.vectorLanguage === 'english (UK)' ? OptionSelected : OptionUnSelected
                  }
                  alt={documentData.vectorLanguage === 'english (UK)' ? 'selected' : 'not selected'}
                /> */}
                {documentData.vectorLanguage === 'english (UK)' ? (
                  <OptionSelected
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setDocumentData((prev) => ({
                        ...prev,
                        vectorLanguage:
                          prev.vectorLanguage === 'english (UK)' ? 'hebrew' : 'english (UK)',
                      }))
                    }}
                    alt={'selected'}
                  />
                ) : (
                  <OptionUnSelected
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setDocumentData((prev) => ({
                        ...prev,
                        vectorLanguage:
                          prev.vectorLanguage === 'english (UK)' ? 'hebrew' : 'english (UK)',
                      }))
                    }}
                    alt={'not selected'}
                  />
                )}

                <p>Translate document to English</p>
              </div>
              {/* <Select
                className="select-box-tool-box"
                size="small"
                id="Model"
                fullWidth
                //   style={{ width: '200px' }}
                value={documentData.vectorLanguage}
                label="Model"
                name="vectorLanguage"
                onChange={handleTextField}
              >
                <MenuItem key={uuidv4()} value={'english (UK)'}>
                  {'english (UK)'}
                </MenuItem>
                <MenuItem key={uuidv4()} value={'hebrew'}>
                  {'hebrew'}
                </MenuItem>
              </Select> */}
            </>
          )}
          <button disabled={checkIfDisabled()} className="submit-btn-document" type="submit">
            {action == 'add' ? 'Add Document' : 'edit document'}
          </button>
        </form>
      ) : (
        <></>
      )
      //  <ProgressBar isLoading={isLoading} isPageEnglish={true} progressPercent={progressPercent} />
      }
    </Modal>
  )
}

export default AddEditModal
