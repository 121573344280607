import axios from '@/configs/axios.js';

export const deleteBotsIds = async (botsIds) => {
  console.log(botsIds);
  const res = await axios.delete(`/api/bots`, { data: { botsIds } });
  return res.data;
};

export const createOrUpdateBot = async (createBotData) => {
  const res = await axios.put('/api/bots/bot-configuration', {
    userId: createBotData.userId,
    botId: createBotData.botId,
    firstMessageValue: createBotData.firstMessageValue,
    promptsSettingsFiltered: createBotData.promptsSettingsFiltered,
    toolKitSettings: createBotData.toolKitSettings,
    endMessageValue: createBotData.endMessageValue,
    errorMessageValue: createBotData.errorMessageValue,
    chosenDocs: createBotData.chosenDocs,
    botData: createBotData.botData,
  });
  return res.data;
};

export const getBotData = async (botId) => {
  const res = await axios.get(`/api/bots/bot-configuration/${botId}`);
  return res.data;
};

export const getUserBots = async (userId) => {
  const res = await axios.get(`/api/bots/${userId}`);
  return res.data;
};
