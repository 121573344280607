import React from 'react'
import pdfImg from '@/assets/pdf-img.png'
import wordImg from '@/assets/pdf-img.png'
import { ReactComponent as ChatboxClose } from '@/assets/chatbox-close-icon-lm.svg'
import { handleFileRemoveAtom } from '@/atoms/logs'
import { useAtom } from 'jotai'
import './file-list.scoped.css'
import axios from '@/configs/axios.js';

function FileList({ files, id }) {
  const [, removeFile] = useAtom(handleFileRemoveAtom)

  const openFile = async (ev, fileId) => {
    ev.stopPropagation()
    console.log('remove from comment')
    // try {
    //   const response = await axios.get('/openfile', { params: { fileId } })

    //   const url = window.URL.createObjectURL(new Blob([response.data]))
    //   window.open(url, '_blank')
    // } catch (error) {
    //   console.error('Error:', error)
    //   // Handle the error as needed, e.g., show an error message to the user
    // }
  }
  return (
    <div className="flex-files">
      {files.map((file) => {
        return (
          <div onClick={(ev) => openFile(ev, file.id)} className="file" key={file.id}>
            <div className="icon-name-container">
              <img src={file.type === 'pdf' ? pdfImg : wordImg} /> {file.name}
              <button type="button" onClick={() => removeFile({ key: id, fileId: file.id })}>
                <ChatboxClose />{' '}
              </button>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default FileList
