import React from "react";
import "./admin-main.scoped.css";
import { useEffect } from "react";
import { AdminTable } from "@/components/AdminTab/AdminTable/AdminTable";
import axios from '@/configs/axios.js';
import { groupsAtom } from "@/atoms/groups";
import { useAtom } from "jotai";

export function AdminMain() {
  const [, setGroups] = useAtom(groupsAtom);

  useEffect(() => {
    const fetchGroups = async () => {
      const groupsUsers = await axios.get("/api/groups/users");
      setGroups(groupsUsers.data);
    };
    fetchGroups();
  }, []);

  return <AdminTable />;
}
