import axios from '@/configs/axios.js';
import { useSetAtom } from 'jotai';
import { isChatEnabledAtom, isAssistatEnabledAtom } from '@/atoms/features.js';

export async function fetchEnabledFeaturesFromAPI() {
  try {
    const response = await axios.get(`/api/features`);
    return response.data;
  } catch (error) {
    console.error('Error fetching enabled features:', error);
    throw error;
  }
}
