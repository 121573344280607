import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const botsFromSession = JSON.parse(sessionStorage.getItem('bots'));
let countChosenBots = 0;
if (botsFromSession) {
  countChosenBots = Object.values(botsFromSession).reduce((a, item) => a + item, 0);
}

export const botsToShowUserAtom = atom([]);

export const searchBotAtom = atom('');

export const markedBotsAtom = atom([]);

export const updateMarkedBotsAtom = atom(null, (get, set, action) => {
  const markedBots = get(markedBotsAtom);
  const { val } = action.payload;
  switch (action.type) {
    case 'markAllBots':
      set(markedBotsAtom, val);
      break;
    case 'markBot':
      const isFound = markedBots.some((markedBot) => markedBot.botId === val.botId);
      if (val && val !== '') {
        if (isFound) {
          set(markedBotsAtom, markedBots.filter((markedBot) => markedBot.botId !== val.botId));
        } else {
          set(markedBotsAtom, [...markedBots, val]);
        }
      }
      break;
  }
});

export const chosenBotsAtom = atomWithStorage('bots', []);

export const botsOpenPopUpAtom = atom('');

export const botCreatorDataAtom = atom({ bot_name: '', description: '', bot_audience: '' });

export const toolKitSettingsAtom = atomWithStorage('botCreatorToolkit', {
  is_active: 'No',
  status: 'Development',
  should_bot_translate: 'None',
  ui_language: 'English (UK)',
  questions_selected: [],
  validations_selected: [],
  question_choice: [],
  embedding_version: 'Embedding-002',
});
