export function toggleFileMark(files, subfolders, fileId) {
  const newTopLevelFiles = files.map((file) =>
    file.doc_id === fileId ? { ...file, marked: !file.marked } : file
  );

  const newTopLevelFolders = subfolders.map((folder) => toggleMarkInFolder(folder, fileId));

  return { files: newTopLevelFiles, subfolders: newTopLevelFolders };
}

export function setFilesMark(allFiles, subfolders, fileIds) {
  const newTopLevelFiles = allFiles.map((file) =>
    fileIds.includes(file.doc_id) ? { ...file, marked: true } : { ...file, marked: false }
  );

  const newTopLevelFolders = subfolders.map((folder) => setMarkInFolder(folder, fileIds));

  return { files: newTopLevelFiles, subfolders: newTopLevelFolders };
}

function setMarkInFolder(folder, fileIds) {
  const newFiles = folder.files.map((file) =>
    fileIds.includes(file.doc_id) ? { ...file, marked: true } : { ...file, marked: false }
  );

  const newSubfolders = folder.subfolders.map((subfolder) => setMarkInFolder(subfolder, fileIds));

  return { ...folder, files: newFiles, subfolders: newSubfolders };
}

function toggleMarkInFolder(folder, fileId) {
  const newFiles = folder.files.map((file) =>
    file.doc_id === fileId ? { ...file, marked: !file.marked } : file
  );

  const newSubfolders = folder.subfolders.map((subfolder) => toggleMarkInFolder(subfolder, fileId));

  return { ...folder, files: newFiles, subfolders: newSubfolders };
}

function toggleMarkAllInFolder(folder, isMark) {
  const newFiles = folder.files.map((file) => {
    return { ...file, marked: file.marked === isMark ? file.marked : isMark };
  });
  const newSubfolders = folder.subfolders.map((subfolder) => {
    const newInSubfolder = toggleMarkAllInFolder(subfolder, isMark);
    return { ...newInSubfolder, marked: subfolder.marked === isMark ? subfolder.marked : isMark };
  });

  return {
    ...folder,
    marked: !folder.marked,
    files: newFiles,
    subfolders: newSubfolders,
  };
}

export function toggleMarkAllTree(tree, isMark) {
  const files = tree.files.map((file) => ({
    ...file,
    marked: file.marked === isMark ? file.marked : isMark,
  }));
  const mainSubfolders = tree.subfolders.map((folder) => {
    const newMainSubfolder = toggleMarkAllInFolder(folder, isMark);
    return {
      ...newMainSubfolder,
      marked: folder.marked === isMark ? folder.marked : isMark,
      subfolders: newMainSubfolder.subfolders,
    };
  });

  return { files: files, subfolders: mainSubfolders };
}
