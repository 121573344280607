import React from 'react';
import { useAtom } from 'jotai';
import './restore-history.scoped.css';
import { isPreviewAtom } from '@/atoms/preview';
import { toolKitSettingsAtom } from '@/atoms/chat';
import { activeLogAtom } from '@/atoms/logs';
import FilledBtn from '@/components/FilledBtn/FilledBtn';
import { inputsAtoms } from '@/atoms/inputs';
import { documentsOpenPopUpAtom } from '@/atoms/documents';

function RestoreHistory() {
  const [isPreview, setIsPreview] = useAtom(isPreviewAtom);
  const [toolkitSettings, setToolKitSettings] = useAtom(toolKitSettingsAtom);
  const [inputs, setInputs] = useAtom(inputsAtoms);
  const [activeLog, setActiveLog] = useAtom(activeLogAtom);
  const [setDocumentsOpenPopUp] = useAtom(documentsOpenPopUpAtom);
  const restoreOldChat = async () => {
    sessionStorage.setItem('chat', inputs);
    sessionStorage.setItem('toolkit', JSON.stringify(toolkitSettings));

    setActiveLog('');
    setIsPreview(false);
    setDocumentsOpenPopUp('');
  };

  return (
    <>
      {isPreview && (
        <div className="submit-strip">
          <FilledBtn style={{ fontWeight: '400' }} className="submit-btn" onClick={restoreOldChat}>
            {' '}
            Restore
          </FilledBtn>
          <div className="log-details">
            <span className="log-date">
              {' '}
              {activeLog.day} {activeLog.dayNum} {activeLog.month}
              {', '} {activeLog.year}{' '}
            </span>
            <span>Restoring this version will overwrite your current session.</span>
          </div>
        </div>
      )}
    </>
  );
}

export default RestoreHistory;
