import React, { useState } from 'react';
import './lower-navbar.scoped.css';
import { ReactComponent as Duplicate } from '@/assets/duplicate-icon.svg';
import { ReactComponent as DeleteIcon } from '@/assets/delete-icon.svg';
import { ReactComponent as ManageIcon } from '@/assets/manage-icon.svg';
import { ReactComponent as AddDocumentIcon } from '@/assets/chatbox-newdocs-icon-lm.svg';
import { useAtom } from 'jotai';
import { botsOpenPopUpAtom, updateMarkedBotsAtom, markedBotsAtom } from '@/atoms/bots';
import FilledBtn from '@/components/FilledBtn/FilledBtn';
import OutlineBtn from '@/components/OutlineBtn/OutlineBtn';
import { CreateBotModal } from '@/components/BotCreator/CreateBotModal/CreateBotModal';
import DeleteModalBots from '@/components/BotsListPage/DeleteModal/DeleteModal.jsx';

function LowerNavbar({
  setIsOpen,
  getAllBotsUser,
  openBotCreator,
  duplicateBots,
  setIsOpenBotCreator,
  getCharacterPrompt,
  setAllBotsData,
  setBotsDetails,
}) {
  const [botsOpenPopUp, setBotsOpenPopUp] = useAtom(botsOpenPopUpAtom);
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [, setMarkedBots] = useAtom(updateMarkedBotsAtom);
  const [markedBots] = useAtom(markedBotsAtom);
  const closeDeletePopup = () => {
    setBotsOpenPopUp('');
  };

  return (
    <div className="bottom-flex">
      <div className="btn-and-dropdown-container">
        <FilledBtn
          onClick={() => {
            setMarkedBots({ payload: { botId: '' } });
            setIsCreateModal(true);
            setIsOpen(false);
          }}
        >
          <AddDocumentIcon />
          New Bot
        </FilledBtn>
      </div>
      {markedBots.length > 0 && (
        <div className="bottom-left-btns">
          <>
            {/* <OutlineBtn onClick={handleDownload}>
              <DownloadIcon />
              Download
            </OutlineBtn> */}
          </>
          {markedBots.length <= 3 && (
            <OutlineBtn onClick={() => duplicateBots(markedBots)}>
              <Duplicate />
              Duplicate
              {/* <EditIcon /> */}
            </OutlineBtn>
          )}

          {markedBots.length === 1 && (
            <>
              <OutlineBtn onClick={() => openBotCreator(markedBots[0])}>
                {/* <EditIcon /> */}
                <ManageIcon />
                Manage
              </OutlineBtn>
              {/* <OutlineBtn>
                <HistoryIcon />
                History
              </OutlineBtn> */}
            </>
          )}
          {/* <OutlineBtn>
            <ShareIcon />
            Share
          </OutlineBtn> */}
          <OutlineBtn onClick={() => setBotsOpenPopUp('delete-bot')}>
            <DeleteIcon />
            Delete
          </OutlineBtn>
        </div>
      )}

      {botsOpenPopUp === 'delete-bot' && (
        <DeleteModalBots
          header={'Delete Bots'}
          isOpen={botsOpenPopUp === 'delete-bot'}
          subHeader={'i would like to delete the following bots'}
          onClose={closeDeletePopup}
          setBotsDetails={setBotsDetails}
          setAllBotsData={setAllBotsData}
          getAllBotsUser={getAllBotsUser}
        />
      )}
      {isCreateModal && (
        <CreateBotModal
          openBotCreator={openBotCreator}
          isOpen={isCreateModal}
          onClose={() => setIsCreateModal(false)}
          setIsOpenBotCreator={setIsOpenBotCreator}
          getCharacterPrompt={getCharacterPrompt}
        />
      )}
    </div>
  );
}

export default LowerNavbar;
