import ArrowDown from '@/assets/setting-arrow-down.png'
import { useState } from 'react'
import './dropDown.scss'
function DropDown({ selectedOption, setSelectedOption, options }) {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => setIsOpen(!isOpen)

  const handleOptionClick = (option) => {
    setSelectedOption(option)
    setIsOpen(false)
  }

  return (
    <div className="custom-select-container">
      <div className="custom-select-selected" onClick={toggleDropdown}>
        <p className="sort-by-select">
          {selectedOption} <img src={ArrowDown} alt="" />
        </p>
      </div>
      {isOpen && (
        <div className="custom-select-options">
          {options.map((option, index) => (
            <div key={index} className="custom-select-option" onClick={() => handleOptionClick(option)}>
              <p>{option}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default DropDown
