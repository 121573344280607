import React, { useState } from 'react'
import './add-edit-folder-modal.scoped.css'
import { Modal } from '@/components/Modal/Modal'
import TextField from '@mui/material/TextField'
import FilledBtn from '@/components/FilledBtn/FilledBtn'
import axios from '@/configs/axios.js';
import { useAtom } from 'jotai'
import { userAtom } from '@/atoms/general'
import { activeFolderAtom } from '@/atoms/folders'
import { addFolder } from '@/services/folders/addFolder'
import { treeAtom } from '@/atoms/folders'
import { markedFoldersAtom } from '@/atoms/folders'
import { editFolder } from '@/services/folders/editFolder'

function AddEditFolderModal({ header, isOpen, subHeader, onClose, action }) {
  const [user] = useAtom(userAtom)
  const [entireTree, setEntireTree] = useAtom(treeAtom)
  const [activeFolder] = useAtom(activeFolderAtom)
  const [markedFolders] = useAtom(markedFoldersAtom)

  const [folderName, setFolderName] = useState(
    action === 'add' ? '' : markedFolders[0].folder_name.trim()
  )

  const inputsStyle = {
    style: { color: 'var(--text-clr)', opacity: '0.5' },
  }
  const handleTextField = (e) => {
    setFolderName(e.target.value)
  }
  const handleEdit = async () => {
    try {
      const response = await axios.put('/api/documents/edit-folder', {
        userId: user.id,
        folderName: folderName.trim(),
        folderId: markedFolders[0].id,
      })
      const newTree = editFolder(
        entireTree.files,
        entireTree.subfolders,
        markedFolders[0].id,
        folderName.trim()
      )
      setEntireTree(newTree)
      onClose()
    } catch (err) {
      console.log(err)
      alert('error while editing the folder')
    }
  }
  const handleSubmit = async () => {
    try {
      const response = await axios.post('/api/documents/create-folder', {
        userId: user.id,
        folderName: folderName.trim(),
        parentId: !activeFolder ? null : activeFolder.id,
      })
      const newTree = addFolder(
        entireTree.files,
        entireTree.subfolders,
        response.data,
        !activeFolder ? null : activeFolder.id
      )
      setEntireTree(newTree)
      onClose()
    } catch (err) {
      console.log(err)
      alert('error while creating the folder')
    }
  }
  return (
    <Modal
      key="add-folder"
      header={header}
      subHeader={subHeader}
      onClose={onClose}
      isOpen={isOpen}
      style={{ height: 'auto' }}
    >
      <TextField
        fullWidth
        InputLabelProps={inputsStyle}
        value={folderName}
        variant="filled"
        label="Folder name"
        name="title"
        InputProps={{
          disableUnderline: true, // Disables the underline
        }}
        onChange={handleTextField}
      />
      <FilledBtn
        onClick={action === 'add' ? handleSubmit : handleEdit}
        disabled={folderName.trim() === ''}
      >
        {action === 'add' ? 'Submit' : 'Edit'}
      </FilledBtn>
    </Modal>
  )
}

export default AddEditFolderModal
