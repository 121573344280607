export function filterDocsInFolders(docIds, folderIds, folders) {
  let docsNotInMarkedFolders = new Set(docIds) // Start with all document IDs

  // Function to recursively check each folder
  function checkFolder(folder) {
    if (folderIds.includes(folder.id)) {
      folder.files.forEach((file) => {
        if (docsNotInMarkedFolders.has(file.doc_id)) {
          docsNotInMarkedFolders.delete(file.doc_id) // Remove docId if it's in a marked folder
        }
      })
    }
    folder.subfolders.forEach((subfolder) => checkFolder(subfolder)) // Recurse into subfolders
  }

  // Check each folder
  folders.forEach((folder) => checkFolder(folder))

  return Array.from(docsNotInMarkedFolders)
}
