export function getMarkedFolders(subfolders) {
    let markedFolders = [];

    // Check each top-level folder and its subfolders
    subfolders.forEach(folder => {
        if (folder.marked) {
            markedFolders.push(folder);
        }
        markedFolders = markedFolders.concat(getMarkedFoldersInSubfolders(folder));
    });

    return markedFolders;
}

function getMarkedFoldersInSubfolders(folder) {
    let markedFolders = [];

    // Recurse into subfolders
    folder.subfolders.forEach(subfolder => {
        if (subfolder.marked) {
            markedFolders.push(subfolder);
        }
        markedFolders = markedFolders.concat(getMarkedFoldersInSubfolders(subfolder));
    });

    return markedFolders;
}