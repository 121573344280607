import { atom } from 'jotai'

import { fetchEnabledFeaturesFromAPI } from '@/api/features.js'

export const isChatEnabledAtom = atom(true)
export const isAssistantEnabledAtom = atom(true)

export const enabledFeaturesAtom = atom({
  isChatEnabledAtom: true,
  isAssistantEnabledAtom: true,
  isDataEnabledAtom: true,
  isPromptsEnabledAtom: true,
  isAdminEnabledAtom: true,
  isaAgentEnabledAtom: false,
  isVoiceEnabledAtom: false,
})

export const setEnabledFeaturesAtoms = atom(null, async (get, set, val) => {
  try {
    const enabledFeatures = await fetchEnabledFeaturesFromAPI()
    set(enabledFeaturesAtom, {
      isChatEnabledAtom: enabledFeatures.enable_chat,
      isAssistantEnabledAtom: enabledFeatures.enable_assistant,
      isDataEnabledAtom: enabledFeatures.enable_data,
      isPromptsEnabledAtom: enabledFeatures.enable_prompts,
      isAdminEnabledAtom: enabledFeatures.enable_admin,
      isaAgentEnabledAtom: enabledFeatures.enable_agents,
      isVoiceEnabledAtom: enabledFeatures.enable_voice,

    })
  } catch (error) {
    console.error('Error setting enabled features:', error)
  }
})
