import { atom } from "jotai";

export const translatesDataAtom = atom([]);
export const searchAtom = atom("");

export const activeTranslatesAtom = atom((get) => {
  const translates = get(filteredTranslatesAtom);

  return translates.filter((translate) => translate.is_active === true);
});

export const filteredTranslatesAtom = atom((get) => {
  const translates = get(translatesDataAtom);
  const searchWord = get(searchAtom);

  const result =
    searchWord.length > 0
      ? translates.filter(
          (translate) =>
            translate.src.includes(searchWord) ||
            translate.translated.includes(searchWord)
        )
      : translates;

  return result;
});

export const updateTranslatesAtom = atom(null, (get, set, action) => {
  const translates = get(translatesDataAtom);
  const { translateId, val } = action.payload;

  switch (action.type) {
    case "createTranslate":
      set(translatesDataAtom, [...translates, val]);
      break;
    case "deleteTranslate":
      set(
        translatesDataAtom,
        translates.filter((translate) => translate.id !== translateId)
      );
      break;
    case "updateSrc":
      set(
        translatesDataAtom,
        translates.map((translate) =>
          translate.id !== translateId
            ? translate
            : {
                ...translate,
                src: val,
              }
        )
      );
      break;
    case "updateTranslated":
      set(
        translatesDataAtom,
        translates.map((translate) =>
          translate.id !== translateId
            ? translate
            : {
                ...translate,
                translated: val,
              }
        )
      );
      break;
    case "updateType":
      set(
        translatesDataAtom,
        translates.map((translate) =>
          translate.id !== translateId
            ? translate
            : {
                ...translate,
                type: val,
              }
        )
      );
      break;

    case "updateActive":
      set(
        translatesDataAtom,
        translates.map((translate) =>
          translate.id !== translateId
            ? translate
            : {
                ...translate,
                is_active: val,
              }
        )
      );
      break;
    case "updateActiveAll":
      set(
        translatesDataAtom,
        translates.map((translate) => ({
          ...translate,
          is_active: val,
        }))
      );
      break;
    default:
      console.error("Action type not recognized: ", action.type);
      break;
  }
});
