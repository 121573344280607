import React from 'react'
import { ReactComponent as ModalCloseIcon } from '@/assets/modal-close-icon-lm.svg'
import { ReactComponent as ModalBackIcon } from '@/assets/modal-back-btn.svg'

import './modal.scoped.css'

export function Modal({
  header,
  subHeader,
  children,
  isOpen,
  onClose,
  style = {},
  isTimeout,
  backFunction,
}) {
  // console.log('rendreed', style)
  if (!isOpen) {
    return null
  }

  if (isTimeout) {
    setTimeout(() => {
      onClose()
    }, 4000)
  }

  return (
    <div className="modal-full-screen">
      <div className="modal-container" style={style}>
        {backFunction && (
          <button onClick={backFunction} className="modal-back-btn">
            <ModalBackIcon />
          </button>
        )}
        <button onClick={onClose} className="modal-close-btn">
          <ModalCloseIcon />
        </button>
        <div className="modal-upper">
          <h2>{header} </h2>
          <span>{subHeader}</span>
        </div>
        {children}
      </div>
    </div>
  )
}
