import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Checkbox from '@mui/material/Checkbox'
import { ReactComponent as ModelIcon } from '@/assets/model-icon-lm.svg'
import { ReactComponent as LangIcon } from '@/assets/language-icon-lm.svg'
import { ReactComponent as ScopeIcon } from '@/assets/scope-icon-lm.svg'
import { ReactComponent as AnswersIcon } from '@/assets/answers-icon-lm.svg'
import { ReactComponent as DataIcon } from '@/assets/data-icon-lm.svg'
import { ReactComponent as TypeIcon } from '@/assets/type-icon-lm.svg'
import { ReactComponent as PromptsIcon } from '@/assets/prompts_icon_toolkit.svg'
import { ReactComponent as TemplateIcon } from '@/assets/template-icon-lm.svg'
import { ReactComponent as TemperatureIcon } from '@/assets/temperature-icon-lm.svg'
import { ReactComponent as MinusIcon } from '@/assets/minus-icon-lm.svg'
import { ReactComponent as PlusIcon } from '@/assets/plus-icon-lm.svg'
import { ReactImageTint } from 'react-image-tint'
import MainPanel from '../MainPanel/MainPanel'
import { toolKitSettingsAtom } from '@/atoms/chat'
import { useAtom } from 'jotai'
import { v4 as uuidv4 } from 'uuid'
import Switch from '@mui/material/Switch'
import { isMobileAtom } from '@/atoms/general'
import './toolkit.scoped.scss'
import { useEffect, useRef, useState } from 'react'
import { markedDocumentsAtom, documentsOpenPopUpAtom } from '@/atoms/documents'
import { promptsToShowUserAtom, markedPromptsAtom } from '@/atoms/prompts'

export const ToolKit = ({ isCloseToolKit }) => {
  const [toolKitSettings, setToolKitSettings] = useAtom(toolKitSettingsAtom)
  const [markedDocuments] = useAtom(markedDocumentsAtom)
  const [promptsToShowUser] = useAtom(promptsToShowUserAtom)
  const [isMobile] = useAtom(isMobileAtom)
  const [markedPrompts, setMarkedPrompts] = useAtom(markedPromptsAtom)
  const [toggleTabs, setToggleTabs] = useState('control-panel')

  const [activeTooltip, setActiveTooltip] = useState({ key: null })
  const timerIdRef = useRef(null)

  useEffect(() => {
    return () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current)
      }
    }
  }, [])

  useEffect(() => {
    console.log('====================================')
    console.log(promptsToShowUser)
    console.log('====================================')
  }, [promptsToShowUser])

  const activateControl = () => {
    setToggleTabs('control-panel')
  }
  const activateHistory = () => {
    setToggleTabs('history')
  }
  const is_selectedDocuments = () => {
    return markedDocuments.length > 0
  }
  const settingsArr = [
    {
      name: 'model',
      options: [
        { label: 'GPT-4', value: 'gpt-4' },
        { label: 'GPT-4o', value: 'gpt-4o' },
        { label: 'GPT-4o-Mini', value: 'gpt-4o-mini' },
        { label: 'Dall-E-3', value: 'dall-e-3' },

        { label: 'Mistral', value: 'mistral' },

        { label: 'GPT-3.5-Turbo-16k', value: 'gpt-3.5-turbo-16k' },
        { label: 'Anthropic.Claude-v2', value: 'anthropic.claude-v2' },
        { label: 'Anthropic.Claude-Instant-v1', value: 'anthropic.claude-instant-v1' },
        { label: 'Claude-3-Opus-20240229', value: 'claude-3-opus-20240229' },
        { label: 'Claude-3-Sonnet-20240229', value: 'claude-3-sonnet-20240229' },
        { label: 'Gemini', value: 'gemini' },
        { label: 'Gemini-1.5-Pro', value: 'gemini-1.5-pro' },
        { label: 'Gemini-1.5-Flash', value: 'gemini-1.5-flash' },
      ],
    },
    {
      name: 'chatLang',
      options: [
        { label: 'English', value: 'english' },
        { label: 'Hebrew', value: 'hebrew' },
        { label: 'None', value: 'none' },
      ],
    },
    {
      name: 'scope',
      options: [{ label: 'Long answer', value: true }, { label: 'Short answer', value: false }],
    },
    {
      name: 'sources',
      options: [{ label: 'Multiple', value: true }, { label: 'Single', value: false }],
    },
    {
      name: 'typing',
      options: [{ label: 'Effect', value: true }, { label: 'No effect', value: false }],
    },
    {
      name: 'template',
      options: [{ label: 'None', value: 'none' }],
    },
    {
      name: 'temperature',
      label: 'Temperature',
    },
    {
      name: 'question_choice',
      options: [{ label: 'Vectors', value: 'Vectors' }, { label: 'Q&A', value: 'Q&A' }],
    },
  ]
  const updateToolKit = ({ target }) => {
    const { name, value } = target
    if (name === 'question_choice' && value && value.length === 0) return
    setToolKitSettings((prev) => ({ ...prev, [name]: value }))
  }

  const handlePromptSelect = ({ target }) => {
    const { value } = target

    let promptsAfterDelete = removePromptsByTitles(value)

    const promptsChosen = findPromptsByTitles(promptsAfterDelete)

    setMarkedPrompts([...promptsChosen])
  }

  // Function to find prompts by titles
  function findPromptsByTitles(titles) {
    return promptsToShowUser.filter((prompt) => {
      return titles.some((title) => {
        if (typeof title === 'string') {
          return prompt.title === title
        } else if (typeof title === 'object' && title !== null && 'title' in title) {
          return prompt.title === title.title
        }
        return false
      })
    })
  }

  function removePromptsByTitles(titles) {
    let promptTitle = titles.pop()
    let isFound = false
    let newPrompts = titles.filter((prompt) => {
      console.log('====================================')
      console.log(prompt, 'prompt')
      console.log('====================================')
      if (prompt.title !== promptTitle) return true
      else {
        isFound = true
        return false
      }
    })

    if (!isFound) newPrompts.push(promptTitle)

    return newPrompts
  }

  const raiseTemperature = () => {
    setToolKitSettings((prev) => {
      return { ...prev, temperature: prev.temperature < 10 ? prev.temperature + 1 : prev.temperature }
    })
  }
  const lowerTemperature = () => {
    setToolKitSettings((prev) => {
      return { ...prev, temperature: prev.temperature > 0 ? prev.temperature - 1 : prev.temperature }
    })
  }

  const showTooltip = (key) => {
    // Clear any existing timer
    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current)
    }
    timerIdRef.current = setTimeout(() => {
      setActiveTooltip({ key: key })
    }, 500) // Set the delay here

    // setActiveTooltip({ key: null })
  }

  const hideTooltip = () => {
    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current)
    }
    setActiveTooltip({ key: null })
  }
  return (
    <div className={`tool-kit ${isCloseToolKit ? 'closed-toolkit' : ''} `}>
      {!isCloseToolKit ? (
        <div className="toolkit-flex">
          <div className="checkboxes-container">
            <div className="tool-tip-container">
              <div className={activeTooltip.key === 'model' ? 'tooltip show' : 'tooltip'}>
                Choose your preferred language model for tailored responses.
              </div>

              <div
                onMouseEnter={() => showTooltip('model')}
                onMouseLeave={hideTooltip}
                className="flex-dropdown-and-label"
              >
                <div className="label-icon-container">
                  <ModelIcon className="icon-clr" /> Model{' '}
                </div>

                <Select
                  className="select-box-tool-box"
                  size="small"
                  id="Model"
                  value={toolKitSettings.model}
                  label="Model"
                  name="model"
                  onChange={updateToolKit}
                >
                  {settingsArr[0].options.map((option) => (
                    <MenuItem key={uuidv4()} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className="tool-tip-container">
              <div className={activeTooltip.key === 'translate' ? 'tooltip show' : 'tooltip'}>
                Select your desired language for system responses.
              </div>

              <div
                onMouseEnter={() => showTooltip('translate')}
                onMouseLeave={hideTooltip}
                className="flex-dropdown-and-label"
              >
                <div className="label-icon-container">
                  <LangIcon className="icon-clr" /> Translate{' '}
                </div>

                <Select
                  className="select-box-tool-box"
                  size="small"
                  id="Model"
                  value={toolKitSettings.chatLang}
                  label="Model"
                  name="chatLang"
                  onChange={updateToolKit}
                >
                  {settingsArr[1].options.map((option) => (
                    <MenuItem key={uuidv4()} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <hr className="seperator" />
          </div>

          <div className="checkboxes-container">
            {!isMobile && (
              <div className="tool-tip-container">
                {' '}
                <div className={activeTooltip.key === 'scope' ? 'tooltip show' : 'tooltip'}>
                  Control the length of responses - short or detailed.
                </div>
                <div
                  onMouseEnter={() => showTooltip('scope')}
                  onMouseLeave={hideTooltip}
                  className={`flex-dropdown-and-label ${!is_selectedDocuments() && 'opacity'}`}
                >
                  <div className="label-icon-container">
                    <ScopeIcon className="icon-clr" /> Scope{' '}
                  </div>

                  <Select
                    className="select-box-tool-box"
                    size="small"
                    id="Model"
                    disabled={!is_selectedDocuments()}
                    value={toolKitSettings.isLongAnswer}
                    label="Model"
                    name="isLongAnswer"
                    onChange={updateToolKit}
                  >
                    {settingsArr[2].options.map((option) => (
                      <MenuItem key={uuidv4()} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            )}

            {!isMobile && (
              <div className="tool-tip-container">
                <div className={activeTooltip.key === 'answers' ? 'tooltip show' : 'tooltip'}>
                  Get either one most accurate answer or multiple possible answers arranged based on
                  relevance to your query.
                </div>

                <div
                  onMouseEnter={() => showTooltip('answers')}
                  onMouseLeave={hideTooltip}
                  className={`flex-dropdown-and-label ${!is_selectedDocuments() && 'opacity'}`}
                >
                  <div className="label-icon-container">
                    <AnswersIcon className="icon-clr" /> Answers{' '}
                  </div>

                  <Select
                    className="select-box-tool-box"
                    size="small"
                    id="Model"
                    disabled={!is_selectedDocuments()}
                    value={toolKitSettings.isMultiple}
                    label="Model"
                    name="isMultiple"
                    onChange={updateToolKit}
                  >
                    {settingsArr[3].options.map((option) => (
                      <MenuItem key={uuidv4()} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            )}

            <div className="tool-tip-container">
              <div className={activeTooltip.key === 'temperature' ? 'tooltip show' : 'tooltip'}>
                Adjust the creativity level of the bot's responses. With 0, the bot won't invent details
                at all and with 10, it'll be highly creative.
              </div>
              <div
                onMouseEnter={() => showTooltip('temperature')}
                onMouseLeave={hideTooltip}
                className="flex-dropdown-and-label"
              >
                <div className="label-icon-container">
                  <TemperatureIcon className="icon-clr" /> Temperature
                </div>

                <div className="temperature-input-container">
                  <button onClick={lowerTemperature}>
                    <MinusIcon />
                  </button>
                  <div className="temperature">{toolKitSettings.temperature}</div>
                  <button onClick={raiseTemperature}>
                    <PlusIcon />
                  </button>
                </div>
              </div>
            </div>
            <hr className="seperator" />
          </div>

          <div className="checkboxes-container">
            <div className="tool-tip-container">
              <div className={activeTooltip.key === 'typing' ? 'tooltip show' : 'tooltip'}>
                Choose between a typing effect or instant complete answers.
              </div>
              <div
                onMouseEnter={() => showTooltip('typing')}
                onMouseLeave={hideTooltip}
                className="flex-dropdown-and-label"
              >
                <div className="label-icon-container">
                  <TypeIcon className="icon-clr" />
                  Typing{' '}
                </div>

                <Select
                  className="select-box-tool-box"
                  size="small"
                  id="Model"
                  value={toolKitSettings.isTypingEffect}
                  label="Model"
                  name="isTypingEffect"
                  onChange={updateToolKit}
                >
                  {settingsArr[4].options.map((option) => (
                    <MenuItem key={uuidv4()} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div
              // onMouseEnter={() => showTooltip('question_choice')}
              // onMouseLeave={hideTooltip}
              className={`flex-dropdown-and-label ${!is_selectedDocuments() && 'opacity'}`}
            >
              <div className="label-icon-container">
                <DataIcon className="icon-clr" /> Data
              </div>

              <Select
                className="select-box-tool-box"
                size="small"
                id="question_choice"
                disabled={!is_selectedDocuments()}
                value={[...toolKitSettings.question_choice]}
                label="question_choice"
                name="question_choice"
                multiple={true}
                onChange={updateToolKit}
                input={<OutlinedInput id="select-multiple-checkbox" label="Validations" />}
                renderValue={(selected) =>
                  selected
                    .map(
                      (value) => settingsArr[7].options.find((option) => option.value === value).label
                    )
                    .join(', ')
                }
              >
                {settingsArr[7].options.map((option) => (
                  <MenuItem key={uuidv4()} value={option.value}>
                    <Checkbox checked={toolKitSettings.question_choice.indexOf(option.value) > -1} />
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="flex-dropdown-and-label-last">
              {/* <div className="label-icon-container"> 
           <TemplateIcon className='icon-clr' /> Template </div>

            <Select
              className="select-box-tool-box-last"
              size="small"
              id="Model"
              value={toolKitSettings.template}
              label="Model"
              name="template"
              onChange={updateToolKit}
       
            >
              {settingsArr[5].options.map((option) => (
                <MenuItem key={uuidv4()}  value={option.value}>{option.label}</MenuItem>
              ))}
            </Select> */}
            </div>
          </div>

          <hr className="seperator" />
          <div className="checkboxes-container">
            <div className="tool-tip-container">
              <div className={activeTooltip.key === 'prompt' ? 'tooltip show' : 'tooltip'}>
                Choose between a prompt.
              </div>
              <div
                onMouseEnter={() => showTooltip('prompt')}
                onMouseLeave={hideTooltip}
                className="flex-dropdown-and-label"
              >
                <div className="label-icon-container">
                  <PromptsIcon className="icon-clr" />
                  Prompts
                </div>
                {/* 
                <Select
                  className="select-box-tool-box"
                  size="small"
                  id="validations_selected"
                  value={[...toolKitSettings.validations_selected]}
                  label="validations_selected"
                  name="validations_selected"
                  multiple={true}
                  onChange={updateToolKit}
                  input={<OutlinedInput id="select-multiple-checkbox" label="Validations" />}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (value) => settingsArr[5].options.find((option) => option.value === value).label
                      )
                      .join(', ')
                  }
                >
                  {settingsArr[5].options.map((option) => (
                    <MenuItem key={uuidv4()} value={option.value}>
                      <Checkbox
                        checked={toolKitSettings.validations_selected.indexOf(option.value) > -1}
                      />
                      {option.label}
                    </MenuItem>
                  ))}
                </Select> */}

                <Select
                  className="select-box-tool-box"
                  size="small"
                  id="Model"
                  value={markedPrompts}
                  label="Model"
                  name="prompts"
                  multiple={true}
                  onChange={handlePromptSelect}
                  input={<OutlinedInput id="select-multiple-checkbox" label="Validations" />}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (value) => promptsToShowUser.find((option) => option.title === value.title).title
                      )
                      .join(', ')
                  }
                >
                  {promptsToShowUser.map((option) => (
                    <MenuItem key={uuidv4()} value={option.title}>
                      <Checkbox
                        checked={
                          markedPrompts.filter((prompt) => prompt.title === option.title).length > 0
                        }
                      />
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="flex-dropdown-and-label-last" />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
