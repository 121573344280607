import React from 'react';
import './singleBotItem.scoped.scss';
import moment from 'moment';
import { ReactComponent as CheckActiveIcon } from '@/assets/check-icon-active.svg';
import { ReactComponent as CheckIcon } from '@/assets/check-icon-lm.svg';
import { ReactComponent as BotLinkIcon } from '@/assets/link-attach-btn.svg';
import { ReactComponent as botNameIcon } from '@/assets/bot-name-img.svg';
import botNameImg from '@/assets/bot-name-img.png';

const SingleBotItem = ({
  markedBot,
  onClick,
  botDetails: { bot_name, owner, lastUpdate, createdAt, is_active, status, usage, botId, botUrl },
}) => {

  return (
    <tr
      style={{
        backgroundColor: markedBot ? 'var(--main-undertone-clr)' : 'var(--white-clr)',
        border: '10px solid var(--chat-background-clr)',
      }}
      onClick={() => onClick(botId)}
      className="single-bot-item"
    >
      <td style={{ paddingLeft: '13px' }}>
        {/* <CheckIcon /> */}
        <input
          type="checkbox"
          id={botId}
          // onClick={(e) =>
          //   handleMarkChange(
          //     e,
          //     document.doc_id,
          //     document.doc_title,
          //     document.doc_type,
          //     document.doc_description,
          //     document.vector_methods,
          //     document.doc_name_origin
          //   )
          // }
          className="checkbox"
        />
        <label onClick={() => onClick(botId)} htmlFor={botId}>
          {markedBot ? <CheckActiveIcon /> : <CheckIcon />}
        </label>
      </td>
      <td>
        <div className="cell">
          <img src={botNameImg} alt="" srcset="" />
          {/* <botNameIcon style={{ width: '32px', height: '32px', color:'black' }} /> */}
          <p style={{ whiteSpace: 'nowrap' }}> {bot_name ? bot_name : botId}</p>
        </div>
      </td>
      <td>{owner}</td>
      <td dir="ltr">
        {moment(lastUpdate)
          .locale('en')
          .format('D MMM, YYYY')}
      </td>
      <td dir="ltr">
        {moment(createdAt)
          .locale('en')
          .format('D MMM, YYYY')}
      </td>

      <td style={{ paddingLeft: '20px' }}>{is_active} </td>
      <td>{status}</td>
      <td style={{ paddingLeft: '25px' }}>{usage || '--'}</td>
      <td>
        <a href={`${botUrl}/${botId}`} target="_blank">
          <BotLinkIcon />
        </a>
      </td>
    </tr>
  );
};

export default SingleBotItem;
