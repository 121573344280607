import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { findActiveFolderAndNesting } from '@/api/documents';
import { treeAtom, activeFolderAtom } from './folders';
import { getMarkedFiles } from '@/services/mark/getMarkedFiles';

export const documentsToShowUserAtom = atom((get) => {
  let entireTree = get(treeAtom);
  let activeFolder = get(activeFolderAtom);
  let content;
  console.log(entireTree);
  console.log('activeFolder:', activeFolder);

  if (entireTree) {
    if (activeFolder) {
      content = findActiveFolderAndNesting(entireTree.subfolders, activeFolder.id);

      if (content != null) {
        console.log(content);
        return content.files;
      } else {
        return entireTree.files;
      }
    } else {
      console.log(entireTree);

      return entireTree.files;
    }
  } else {
    return [];
  }
});

export const markedDocumentsAtom = atom((get) => {
  let entireTree = get(treeAtom);
  if (entireTree) {
    return getMarkedFiles(entireTree.files, entireTree.subfolders);
  } else {
    return [];
  }
});

export const chosenDocumentsAtom = atomWithStorage('docs', []);

export const documentsOpenPopUpAtom = atom('');

export const documentHistoryAtom = atom('');
export const chosenDocumentsIdsAtom = atom((get) => {
  const documents = get(chosenDocumentsAtom);
  return documents.map((item) => item.doc_id);
});
