export function removeFoldersById(files,subfolders, folderIds) {
    // Filter out the folders at the top level
    const newTopLevelFolders = subfolders.filter(folder => !folderIds.includes(folder.id));

    // Remove the folders from within any subfolders
    const updatedTopLevelFolders = newTopLevelFolders.map(folder => 
        removeFoldersFromSubfolders(folder, folderIds)
    );

    return {files:files,subfolders:updatedTopLevelFolders};
}

function removeFoldersFromSubfolders(folder, folderIds) {
    // Filter out the folders from this folder's subfolders
    const newSubfolders = folder.subfolders.filter(subfolder => !folderIds.includes(subfolder.id));

    // Recursively remove the folders from sub-subfolders
    const updatedSubfolders = newSubfolders.map(subfolder => 
        removeFoldersFromSubfolders(subfolder, folderIds)
    );

    return { ...folder, subfolders: updatedSubfolders };
}