import React, { useRef, useState } from 'react';
import './lowerpart.scoped.css';
import { ReactComponent as LikeIconActive } from '@/assets/like-icon-active-lm.svg';
import { ReactComponent as LikeIcon } from '@/assets/like-icon-inactive-lm.svg';
import { ReactComponent as MinusIcon } from '@/assets/minus-icon-lm.svg';
import { useAtom } from 'jotai';
import { ReactComponent as DislikeIconActive } from '@/assets/dislike-icon-active-lm.svg';
import { ReactComponent as DislikeIcon } from '@/assets/dislike-icon-inactive-lm.svg';
import { ReactComponent as CopyIcon } from '@/assets/copy-icon.svg';
import { ReactComponent as CopyIconActive } from '@/assets/copy-icon-active.svg';
import { ReactComponent as ChatboxIcon } from '@/assets/chatbox-icon-lm.svg';
import { ReactComponent as ChatboxClose } from '@/assets/chatbox-close-icon-lm.svg';
import { ReactComponent as RightArrowIcon } from '@/assets/right-arrow-icon-lm.svg';
import { ReactComponent as LeftArrowIcon } from '@/assets/left-arrow-icon-lm.svg';
import { isPreviewAtom } from '@/atoms/preview';
import {
  toggleLikeAtom,
  toggleDislikeAtom,
  handleCommentChangeAtom,
  handleCommentSaveAtom,
  handleCommentCloseAtom,
} from '@/atoms/logs';

function LowerPart({
  handleDeleteInput,
  isLike,
  comment,
  id,
  show,
  arrOfAnswer,
  handleIncrement,
  handleDecrement,
  answersIndex,
  text,
}) {
  const [isTyping, setIsTyping] = useState(false);
  const [, toggleLike] = useAtom(toggleLikeAtom);
  const [isPreview] = useAtom(isPreviewAtom);
  const [, toggleDislike] = useAtom(toggleDislikeAtom);
  const [, handleCommentChange] = useAtom(handleCommentChangeAtom);
  const [, handleCommentSave] = useAtom(handleCommentSaveAtom);
  const [, handleCommentClose] = useAtom(handleCommentCloseAtom);
  const timeoutRef = useRef();

  const [isCopying, setIsCopying] = useState(false);
  const enterPressFunction = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleCommentSave({ e: e, key: id });
      setIsTyping(false);
      e.target.blur();
    }
  };
  const handleHideComment = (e, key) => {
    e.stopPropagation();
    handleCommentClose(key);
  };

  const copyText = () => {
    navigator.clipboard.writeText(text);
    setIsCopying(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setIsCopying(false);
    }, 2000);
  };

  return (
    <div className="lower-part">
      <div className="flex-answers-arrows">
        {arrOfAnswer && arrOfAnswer.length > 1 && (
          <button
            disabled={answersIndex === 0}
            onClick={handleDecrement}
            className="close-comment-icon bordered-icons"
          >
            <LeftArrowIcon />
          </button>
        )}
        {arrOfAnswer && arrOfAnswer.length > 1 && (
          <button
            disabled={answersIndex === arrOfAnswer.length - 1}
            onClick={handleIncrement}
            className="close-comment-icon bordered-icons"
          >
            <RightArrowIcon />
          </button>
        )}
      </div>
      {isLike !== null && show && (
        <label
          disabled={isPreview}
          onClick={!isPreview ? () => setIsTyping(true) : {}}
          for="comment-textarea"
          className={`flex-comment ${isTyping || comment.trim() === '' ? 'active-bg' : 'unactive-bg'}`}
        >
          <button disabled={isPreview}>
            <ChatboxIcon disabled={isPreview} />{' '}
          </button>
          <textarea
            disabled={isPreview}
            id="comment-textarea"
            rows={1}
            value={comment}
            onKeyPress={(e) => enterPressFunction(e)}
            onChange={(e) => handleCommentChange({ ev: e, key: id })}
          />
          {isTyping && (
            <button className="close-chatbox-btn" onClick={(e) => handleHideComment(e, { key: id })}>
              <ChatboxClose />{' '}
            </button>
          )}
        </label>
      )}
      <div className="flex-likes-answers">
        <div className="flex-likes-minus">
          <button
            className="close-comment-icon"
            disabled={isPreview}
            onClick={() => toggleLike({ key: id })}
          >
            {isLike === 'like' ? <LikeIconActive /> : <LikeIcon />}
          </button>
          <button
            className="close-comment-icon"
            disabled={isPreview}
            onClick={() => toggleDislike({ key: id })}
          >
            {isLike === 'dislike' ? <DislikeIconActive /> : <DislikeIcon />}
          </button>
          {/* <button className="close-comment-icon"  onClick={handleDeleteInput}> <MinusIcon/> </button> */}
        </div>
        <button className="close-comment-icon" onClick={() => copyText({ key: id })}>
          {isCopying ? <CopyIconActive /> : <CopyIcon />}
        </button>
      </div>
    </div>
  );
}

export default LowerPart;
