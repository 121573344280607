import './loader.scss';
import { Line } from 'rc-progress';

export const ProgressBar = ({ isLoading, isPageEnglish, progressPercent }) => {
  return (
    <Line
      percent={progressPercent}
      strokeWidth={4}
      strokeColor="var(--main-clr)"
      style={{ width: '450px', height: '25px' }}
    />
  );
};
