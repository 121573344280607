import './newHeader.scoped.css';
import Burger from '@/assets/burger.png';
import { ReactComponent as Setting } from '@/assets/Setting.svg';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useState, useRef, useCallback } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAtom } from 'jotai';
import { isMobileAtom, userAtom, tabValueAtom } from '@/atoms/general';
import { setEnabledFeaturesAtoms, enabledFeaturesAtom } from '@/atoms/features';
import { markedDocumentsAtom } from '@/atoms/documents';
import { callsToShowUserAtom } from '@/atoms/voice';
import { markedPromptsAtom } from '@/atoms/prompts';
import { markedBotsAtom } from '@/atoms/bots';
import { setEnabledFeatures } from '@/api/features.js';
import ArrowIconAvatar from '@/assets/arrow-icon-avatar.png';
import { ReactComponent as MainLogo } from '@/assets/main-logo.svg';
import { useEffect } from 'react';

export const NewHeader = ({ mainLocation, setIsHeaderBurgerOpen, isHeaderBurgerOpen }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tabValue, setTabValue] = useAtom(tabValueAtom);
  const [markedDocuments] = useAtom(markedDocumentsAtom);
  const [callsToShowUser] = useAtom(callsToShowUserAtom);
  const [markedPrompts] = useAtom(markedPromptsAtom);
  const [markedBots] = useAtom(markedBotsAtom);
  const [isMobile, setIsMobile] = useAtom(isMobileAtom);
  const [user, setUser] = useAtom(userAtom);
  const [isOpenLangContainer, setIsOpenLangContainer] = useState(false);
  const [isOpenUserContainer, setIsOpenUserContainer] = useState(false);
  const location = useLocation();
  const navigate = useHistory();

  const open = Boolean(anchorEl);
  const company = user.email.split('@')[1];
  const userFromMail = user.email.split('@')[0];
  const [_, setEnabledFeatures] = useAtom(setEnabledFeaturesAtoms);
  const [enabledFeatures] = useAtom(enabledFeaturesAtom);

  const initializeHeaderState = useCallback(async () => {
    await setEnabledFeatures();
    if (!enabledFeatures.isChatEnabledAtom) {
      setTabValue('assistant');
    }
    if (!enabledFeatures.isAssistantEnabledAtom) {
      setTabValue('edit');
    }
  });

  useEffect(() => {
    initializeHeaderState();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('user');
    localStorage.clear();
    window.location.replace('/');
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const toggleLangContainer = (ev) => {
    ev.stopPropagation();
    setIsOpenUserContainer(false);
    setIsOpenLangContainer((prev) => !prev);
  };

  const toggleUserContainer = (ev) => {
    ev.stopPropagation();
    setIsOpenLangContainer(false);
    setIsOpenUserContainer((prev) => !prev);
  };

  const resetHeaderPopUps = () => {
    setIsOpenLangContainer(false);
    setIsOpenUserContainer(false);
  };

  const overrideStlyes = {
    textTransform: 'none !important',
    fontFamily: 'Assistant',
    color: 'var(--text-clr)',
    fontWeight: '300',
    fontSize: '18px',
    paddingTop: '19px',
    '&.Mui-selected': {
      color: 'var(--secondary-clr)',
      fontWeight: '600',
      backgroundColor: 'var(--main-undertone-clr)',
      fontSize: '20px',
      height: '57px',
    },
  };
  // const handleAgent = () => {
  //   window.open(
  //     'https://langflow-app.delightfulwater-ecb5056f.westeurope.azurecontainerapps.io/flow/e64f307e-98fd-4554-9333-7d012cfba88d',
  //     '_blank'
  //   );
  //   setTabValue('edit');
  // };

  return (
    <>
      <div onClick={resetHeaderPopUps} className="page-header">
        <div className="logo-and-steps">
          <Link className="header-link" to="/playground" title="to main page" alt="to main page">
            {/* <img src={onebi} alt="onebi logo" /> */}
            <MainLogo />
          </Link>

          {!isMobile && (
            <Tabs
              sx={{ borderBottom: '1px solid var(--lighter-grey-clr)' }}
              value={tabValue}
              onChange={handleChange}
              textColor="secondary"
              TabIndicatorProps={{ style: { backgroundColor: 'var(--main-clr)' } }}
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              {enabledFeatures.isChatEnabledAtom && (
                <Tab value="edit" label="Chat" sx={overrideStlyes} />
              )}
              {enabledFeatures.isDataEnabledAtom && (
                <Tab
                  value="documents"
                  label={`Data ${markedDocuments?.length ? `(${markedDocuments.length})` : ''}`}
                  sx={overrideStlyes}
                />
              )}
              {enabledFeatures.isVoiceEnabledAtom && (
                <Tab
                  value="voice-analysis"
                  label={`Voice ${
                    callsToShowUser && callsToShowUser.length > 0 && tabValue === 'voice-analysis'
                      ? `(${callsToShowUser.length})`
                      : ''
                  }`}
                  sx={overrideStlyes}
                />
              )}
              {enabledFeatures.isPromptsEnabledAtom && (
                <Tab
                  value="prompts"
                  label={`Prompts ${
                    markedPrompts?.length && tabValue === 'prompts' ? `(${markedPrompts.length})` : ''
                  }`}
                  sx={overrideStlyes}
                />
              )}

              {enabledFeatures.isAssistantEnabledAtom && (
                <Tab
                  value="assistant"
                  label={`Assistant ${
                    markedBots?.length && tabValue === 'assistant' ? `(${markedBots.length})` : ''
                  }`}
                  sx={overrideStlyes}
                />
              )}
              {enabledFeatures.isaAgentEnabledAtom && (
                <Tab value="agents" label="Flows" sx={overrideStlyes} />
              )}
              {enabledFeatures.isAdminEnabledAtom && (
                <Tab value="admin" label="Admin" sx={overrideStlyes} />
              )}
            </Tabs>
          )}
        </div>
        <div onClick={toggleUserContainer} className="user-container">
          <IconButton
            aria-label="settings"
            aria-controls="settings-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <Setting />
          </IconButton>
          <Menu id="settings-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
            <MenuItem style={{ fontFamily: 'Assistant' }} onClick={handleLogout}>
              Log out
            </MenuItem>
          </Menu>
          <div className="user-bubble">{userFromMail[0].toUpperCase()}</div>
          <p className="username-title">{userFromMail}</p>
        </div>
      </div>

      {/* Uncomment and adjust the code below for mobile header functionality */}
      {/* {isHeaderBurgerOpen && (
        <div
          onClick={(ev) => {
            ev.stopPropagation()
            setIsOpenLangContainer(false)
          }}
          className="header-burger"
        >
          <div className="avatar"></div>
          <div className={mainLocation === 'playground' ? 'navigator-container-active' : 'navigator-container'}>
            <div className="sub-locations">
              <div className={`link-container ${location.pathname === '/' ? 'active' : ''}`}>
                <Link
                  onClick={() => setIsHeaderBurgerOpen(false)}
                  className="header-link"
                  to="/"
                  title="to main page"
                  alt="to main page"
                >
                  <p style={{ color: location.pathname === '/' ? '#2abcc3' : '' }}>Main</p>
                </Link>
              </div>
              <div className={`link-container ${location.pathname === '/history' ? 'active' : ''}`}>
                <Link
                  onClick={() => setIsHeaderBurgerOpen(false)}
                  className="header-link"
                  to="/history"
                  title="to history"
                  alt="to history"
                >
                  <p style={{ color: location.pathname === '/history' ? '#2abcc3' : '' }}>History</p>
                </Link>
              </div>
            </div>
          </div>
          <div className="burger-line"></div>
          <div onClick={toggleLangContainer} className="lang-container">
            <p className="lang-main-title">Language</p>
          </div>
        </div>
      )} */}
    </>
  );
};
