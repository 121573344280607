import "./conversion-table.scoped.css";
import React, { useState } from "react";
import { ReactComponent as SortArrowIcon } from "@/assets/sort-arrow-icon.svg";
import { ReactComponent as CheckActiveIcon } from "@/assets/check-icon-active.svg";
import { ReactComponent as CheckIcon } from "@/assets/check-icon-lm.svg";

export const TableHeaderSort = ({ type, arr, value, style, handler }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return type === "header" ? (
    <th style={style}>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          setIsExpanded(!isExpanded);
          switch (value) {
            case "Conversion Type":
              handler(arr, "type");
              break;
            case "Original Text":
              handler(arr, "src");
              break;
            case "Convert Text":
              handler(arr, "translated");
              break;
            case "Last Update":
              handler(arr, "last_updated");
              break;
            default:
              break;
          }
        }}
      >
        {value}
        <button
          style={{
            transform: isExpanded ? "rotate(180deg)" : "",
            //   transition: "transform 150ms ease",
            backgroundColor: "var(--chat-background-clr)",
            border: "none",
            marginLeft: "10px",
          }}
        >
          <SortArrowIcon />
        </button>
      </div>
    </th>
  ) : (
    <th
      style={style}
    >
      <input type="checkbox" id={`checkboxId-all`} className="checkbox" />
      <div onClick={handler.func}>
        {handler.value ? <CheckActiveIcon /> : <CheckIcon />}
      </div>
      <div style={{ marginLeft: "10px" }}>
        {"("}
        {value}
        {")"}
      </div>
    </th>
  );
};
