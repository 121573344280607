export function unmarkAll(files, subfolders) {
    // Unmark the files at the top level
    const newFiles = files.map(file => ({ ...file, marked: false }));

    // Function to unmark files and folders in subfolders
    function unmarkFilesAndFoldersInFolder(folder) {
        const newFolderFiles = folder.files.map(file => ({ ...file, marked: false }));
        const newSubfolders = folder.subfolders.map(subfolder => unmarkFilesAndFoldersInFolder(subfolder));
        return { ...folder, marked: false, files: newFolderFiles, subfolders: newSubfolders };
    }

    // Unmark files and folders in subfolders
    const newSubfolders = subfolders.map(subfolder => unmarkFilesAndFoldersInFolder(subfolder));

    return { files: newFiles, subfolders: newSubfolders };
}