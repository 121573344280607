import axios from '@/configs/axios.js';

export async function updateGroupUsersToken(data) {
  const { groupId, token } = data;

  await axios.post(`/api/groups/users/${groupId}/token`, {
    token,
  });
}

export async function updateGroupUsersModel(data) {
  const { groupId, model } = data;

  await axios.post(`/api/groups/users/${groupId}/model`, {
    model: model !== null ? JSON.stringify(model) : model,
  });
}

export async function updateGroupToken(data) {
  const { groupId, token } = data;

  await axios.post(`/api/groups/group/${groupId}/token`, {
    token,
  });
}

export async function updateGroupModel(data) {
  const { groupId, model } = data;

  await axios.post(`/api/groups/group/${groupId}/model`, {
    model: JSON.stringify(model),
  });
}

export async function updateUserToken(data) {
  const { userId, token } = data;

  await axios.post(`/api/groups/user/${userId}/token`, {
    token,
  });
}

export async function updateUserModel(data) {
  const { userId, model } = data;

  await axios.post(`/api/groups/user/${userId}/model`, {
    model: JSON.stringify(model),
  });
}
