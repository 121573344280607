export function addFolder(files, subfolders, newFolder, parentFolderId = null) {
  if (parentFolderId === null) {
    // Add to the top level
    return { files, subfolders: [...subfolders, newFolder] }
  } else {
    // Add as a subfolder to the specified parent folder
    return {
      files,
      subfolders: subfolders.map((folder) => addFolderToFolder(folder, newFolder, parentFolderId)),
    }
  }
}

function addFolderToFolder(folder, newFolder, parentFolderId) {
  if (folder.id === parentFolderId) {
    // The current folder is the parent, add the new folder here
    return { ...folder, subfolders: [...folder.subfolders, newFolder] }
  }

  // Otherwise, recurse into subfolders
  const newSubfolders = folder.subfolders.map((subfolder) =>
    addFolderToFolder(subfolder, newFolder, parentFolderId)
  )
  return { ...folder, subfolders: newSubfolders }
}
