import React, { useState, useEffect, useRef } from "react";
import { styled, Typography, Slider, Paper, Stack, Box } from "@mui/material";

import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeMuteIcon from "@mui/icons-material/VolumeMute";

import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
// import PauseIcon from "@mui/icons-material/Pause";
// import PlayArrowIcon from "@mui/icons-material/PlayArrow";
// import FastRewindIcon from "@mui/icons-material/FastRewind";
// import FastForwardIcon from "@mui/icons-material/FastForward";
// import SkipNextIcon from "@mui/icons-material/SkipNext";
// import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";

const styleColor = "var(--main-clr)";
const durationStyle = {
  color: styleColor,
  fontSize: "14px",
  fontWeight: "300",
};

const PSlider = styled(Slider)(({ theme, ...props }) => ({
  color: styleColor,
  height: 2,
  width:'150px',
  // "&:hover": {
  //   cursor: "auto",
  // },
  "& .MuiSlider-thumb": {
    width: "8px",
    height: "8px",
    display: props.thumbless ? "none" : "block",
    "&:hover": {
      color: "none",
    },
  },
}));

export default function Player({ audioSrc }) {
  console.log(audioSrc)
  const audioPlayer = useRef();

  const [index, setIndex] = useState(0);

  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(30);
  const [mute, setMute] = useState(false);

  const [elapsed, setElapsed] = useState(0);
  const [duration, setDuration] = useState(0);
  
  useEffect(() => {
    if (audioPlayer) {
      audioPlayer.current.volume = volume / 100;
    }

    if (isPlaying) {
      setInterval(() => {
        const _duration = Math.floor(audioPlayer?.current?.duration);
        const _elapsed = Math.floor(audioPlayer?.current?.currentTime);

        setDuration(_duration);
        setElapsed(_elapsed);
      }, 100);
    }
  }, [volume, isPlaying]);

  function formatTime(time, last) {
    if (time && !isNaN(time)) {
      const minutes =
        Math.floor(time / 60) < 10
          ? `0${Math.floor(time / 60)}`
          : Math.floor(time / 60);
      const seconds =
        Math.floor(time % 60) < 10
          ? `0${Math.floor(time % 60)}`
          : Math.floor(time % 60);

      return last ? `-${minutes}:${seconds}` : `${minutes}:${seconds}`;
    }
    return last ? "-00:00" : "00:00";
  }

  const togglePlay = () => {
    if (!isPlaying) {
      audioPlayer.current.play();
    } else {
      audioPlayer.current.pause();
    }
    setIsPlaying((prev) => !prev);
  };

  const toggleForward = () => {
    audioPlayer.current.currentTime += 10;
  };

  const toggleBackward = () => {
    audioPlayer.current.currentTime -= 10;
  };

  //   const toggleSkipForward = () => {
  //     if (index >= playlist.length - 1) {
  //       setIndex(0);
  //       audioPlayer.current.src = playlist[0];
  //       audioPlayer.current.play();
  //     } else {
  //       setIndex((prev) => prev + 1);
  //       audioPlayer.current.src = playlist[index + 1];
  //       audioPlayer.current.play();
  //     }
  //   };

  //   const toggleSkipBackward = () => {
  //     if (index > 0) {
  //       setIndex((prev) => prev - 1);
  //       audioPlayer.current.src = playlist[index - 1];
  //       audioPlayer.current.play();
  //     }
  //   };

  function VolumeBtns() {
    return mute ? (
      <VolumeOffIcon
        sx={{ color: styleColor, "&:hover": { color: "var(--main-clr)" } }}
        onClick={() => setMute(!mute)}
      />
    ) : volume <= 20 ? (
      <VolumeMuteIcon
        sx={{ color: styleColor, "&:hover": { color: "var(--main-clr)" } }}
        onClick={() => setMute(!mute)}
      />
    ) : volume <= 75 ? (
      <VolumeDownIcon
        sx={{ color: styleColor, "&:hover": { color: "var(--main-clr)" } }}
        onClick={() => setMute(!mute)}
      />
    ) : (
      <VolumeUpIcon
        sx={{ color: styleColor, "&:hover": { color: "var(--main-clr)" } }}
        onClick={() => setMute(!mute)}
      />
    );
  }

  return (
    <div>
      <audio src={audioSrc} ref={audioPlayer} muted={mute}  />
      <Box
        sx={{
          justifyContent: "space-between",
          width: "255px",
        }}
      />
      <Stack
        spacing={1}
        direction="row"
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column"}}>
          <div style={{ display: "flex", flexDirection: "row", gap:'20px' }}>
            {!isPlaying ? (
              <PlayCircleFilledIcon
                fontSize={"medium"}
                sx={{ color: styleColor }}
                onClick={togglePlay}
              />
            ) : (
              <PauseCircleFilledIcon
                fontSize={"medium"}
                sx={{ color: styleColor }}
                onClick={togglePlay}
              />
            )}
            <PSlider value={elapsed} max={duration} />
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginLeft:'43px' }}>
            <Typography sx={{ ...durationStyle }}>
              {formatTime(elapsed, false)}
            </Typography>
            <Typography sx={{ ...durationStyle, marginLeft: '51%' }}>
              {formatTime(duration - elapsed, true)}
            </Typography>
          </div>
        </div>
      </Stack>
    </div>
  );
}
