import { atom } from 'jotai';
import { v4 as uuidv4 } from 'uuid';

const id = uuidv4();

export const inputsAtoms = atom([
  {
    role: 'user',
    value: '',
    isAnswer: false,
    isLike: null,
    comment: '',
    show: true,
    toType: false,
    key: id,
    arrOfAnswer: null,
    files: [],
  },
]);

export const focusInputAtoms = atom(id);
