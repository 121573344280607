import React from 'react';
import './documents-main.scoped.css';
import DocumentsTable from '@/components/DocumentsTab/DocumentsTable/DocumentsTable';
import LowerNavbar from '@/components/DocumentsTab/LowerNavbar/LowerNavbar';
import { treeAtom, userFoldersAtom } from '@/atoms/folders';
import AddEditModal from '@/components/AddEditModal/AddEditModal';
import { activeFolderAtom, pathAtom } from '@/atoms/folders';
import { useAtom } from 'jotai';
import { ReactComponent as LeftArrowIcon } from '@/assets/left-arrow-icon-lm.svg';
import { getDocumentsAndFoldersAtom } from '@/api/documents';
import DeleteModal from '../DeleteModal/DeleteModal';
import AddEditFolderModal from '../AddEditFolderModal/AddEditFolderModal';
import { documentsOpenPopUpAtom, documentsToShowUserAtom } from '@/atoms/documents';
import { DocumentsHistory } from '../DocumentsHistory/DocumentsHistory';
import RestoreHistory from '@/components/RestoreHistory/RestoreHistory';

function DocumentsMain() {
  const [documentsOpenPopUp, setDocumentsOpenPopUp] = useAtom(documentsOpenPopUpAtom);
  const [, getDocumentsAndFolders] = useAtom(getDocumentsAndFoldersAtom);
  const [path, setPath] = useAtom(pathAtom);
  const [activeFolder, setActiveFolder] = useAtom(activeFolderAtom);

  const closeFolderPopup = () => {
    setDocumentsOpenPopUp('');
  };
  const closeAddEditPopup = () => {
    setDocumentsOpenPopUp('');
  };
  const closeDeletePopup = () => {
    setDocumentsOpenPopUp('');
  };

  // useEffect(() => {
  //     getDocumentsAndFolders();
  // }, [activeFolder]);

  const handleGoBack = () => {
    if (path.length === 1) {
      setActiveFolder('');
    } else {
      setActiveFolder({ id: path[path.length - 2].id, name: path[path.length - 2].name });
    }
    setPath((prevPath) => prevPath.slice(0, -1));
  };

  const renderBreadCrumbs = () => {
    return (
      <>
        <span>
          <button
            className="breadcrumbs-btn"
            onClick={() => {
              setPath([]);
              setActiveFolder('');
            }}
          >
            Documents/
          </button>
        </span>{' '}
        {path.map((folder, index) => (
          <span key={folder.id}>
            {index > 0 && '/'}
            <button
              className="breadcrumbs-btn"
              onClick={() => {
                setPath(path.slice(0, index + 1));
                setActiveFolder({ id: path[index].id, name: path[index].name });
              }}
            >
              {folder.name}
            </button>
          </span>
        ))}
      </>
    );
  };

  const handleFolderPress = (e, id, name) => {
    setPath((prev) => [...prev, { parenId: activeFolder ? activeFolder.id : null, id: id, name: name }]);
    console.log('pressed');

    setActiveFolder({ id, name });
  };

  return (
    <div className="documents-main-container">
      <div className="folders-container">
        <div className="page-subheader">
          {path.length > 0 && (
            <button className="close-comment-icon bordered-icons" onClick={handleGoBack}>
              <LeftArrowIcon />
            </button>
          )}
          <span className="breadcrumbs">{renderBreadCrumbs()}</span>
        </div>
      </div>
      {/* <ScopeProvider atoms={[treeAtom,userFoldersAtom,documentsToShowUserAtom]}> */}
      <DocumentsTable key={123} handleFolderPress={handleFolderPress} />

      {/* </ScopeProvider > */}

      {documentsOpenPopUp === 'delete' && (
        <DeleteModal
          header={'Delete Files'}
          isOpen={documentsOpenPopUp === 'delete'}
          subHeader={'i would like to delete the following files'}
          onClose={closeDeletePopup}
        />
      )}
      {documentsOpenPopUp === 'add-folder' && (
        <AddEditFolderModal
          header={'Add new Folder'}
          isOpen={documentsOpenPopUp === 'add-folder'}
          subHeader={''}
          onClose={closeFolderPopup}
          action={'add'}
        />
      )}
      {documentsOpenPopUp === 'edit-folder' && (
        <AddEditFolderModal
          header={'edit Folder'}
          isOpen={documentsOpenPopUp === 'edit-folder'}
          subHeader={''}
          onClose={closeFolderPopup}
          action={'edit'}
        />
      )}

      {documentsOpenPopUp === 'history' && <DocumentsHistory />}

      {documentsOpenPopUp === 'edit-document' && (
        <AddEditModal
          header={'edit-document'}
          subHeader={'Drag or Attach your Document to the screen'}
          isOpen={documentsOpenPopUp === 'edit-document'}
          action={'edit'}
          onClose={closeAddEditPopup}
        />
      )}

      {documentsOpenPopUp === 'add-document' && (
        <AddEditModal
          header={'Add New Document'}
          subHeader={'Drag or Attach your Document to the screen'}
          isOpen={documentsOpenPopUp === 'add-document'}
          action={'add'}
          onClose={closeAddEditPopup}
        />
      )}
      {/* <DocumentsPopup isOpenPopUp={isOpenPopUp} setIsOpenPopUp={setIsOpenPopUp}/> */}
      <LowerNavbar />
      {documentsOpenPopUp === 'history' && <RestoreHistory />}
    </div>
  );
}

export default DocumentsMain;
