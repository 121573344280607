import React from 'react';
import { Modal } from '@/components/Modal/Modal';
import DocumentsTable from '@/components/DocumentsTab/DocumentsTable/DocumentsTable.jsx';
import { activeFolderAtom } from '@/atoms/folders';
import { pathAtom } from '@/atoms/folders';
import { useAtom } from 'jotai';
import { ReactComponent as LeftArrowIcon } from '@/assets/left-arrow-icon-lm.svg';
import { Provider } from 'jotai';
import './docs-modal.scoped.css';
import { markedDocumentsAtom } from '@/atoms/documents';

function DocsModal({ onClose, handleClose, setChosenDocs }) {
  const [path, setPath] = useAtom(pathAtom);
  const [activeFolder, setActiveFolder] = useAtom(activeFolderAtom);
  const [markedDocuments] = useAtom(markedDocumentsAtom);

  const handleSubmit = () => {
    const botFinalDocments = markedDocuments.map((doc) => doc.docId);
    console.log(botFinalDocments);
    setChosenDocs([...botFinalDocments]);

    handleClose();
  };

  const handleGoBack = () => {
    if (path.length === 1) {
      setActiveFolder('');
    } else {
      setActiveFolder({ id: path[path.length - 2].id, name: path[path.length - 2].name });
    }
    setPath((prevPath) => prevPath.slice(0, -1));
  };
  const renderBreadCrumbs = () => {
    return (
      <>
        <span>
          <button
            className="breadcrumbs-btn"
            onClick={() => {
              setPath([]);
              setActiveFolder('');
            }}
          >
            Documents/
          </button>
        </span>{' '}
        {path.map((folder, index) => (
          <span key={folder.id}>
            {index > 0 && '/'}
            <button
              className="breadcrumbs-btn"
              onClick={() => {
                setPath(path.slice(0, index + 1));
                setActiveFolder({ id: path[index].id, name: path[index].name });
              }}
            >
              {folder.name}
            </button>
          </span>
        ))}
      </>
    );
  };

  const handleFolderPress = (e, id, name) => {
    setPath((prev) => [...prev, { parenId: activeFolder ? activeFolder.id : null, id: id, name: name }]);
    setActiveFolder({ id, name });
  };

  return (
    <Modal onClose={onClose} isOpen={true} style={{ width: '80%' }}>
      <div className="page-subheader">
        {path.length > 0 && (
          <button className="close-comment-icon bordered-icons" onClick={handleGoBack}>
            <LeftArrowIcon />
          </button>
        )}
        {renderBreadCrumbs()}
      </div>
      <DocumentsTable handleFolderPress={handleFolderPress} />
      <button
        // disabled={markedDocuments.length === 0}
        className="submit-btn-document"
        type="submit"
        onClick={handleSubmit}
      >
        save to Bot
      </button>
    </Modal>
  );
}

export default DocsModal;
