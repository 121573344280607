import { ReactComponent as RemoveVector } from '@/assets/remove_vector.svg';
import '../vector-modal.scoped.css';
import generateUniqueId from '@/utils/generateUniqueId';

export function RemoveCellRenderer(props) {
  const id = generateUniqueId();
  const handleRemove = (event) => {
    props.handleChange(props.data.id, props);
  };

  return (
    <div>
      <input
        type="checkbox"
        id={id}
        onChange={handleRemove}
        className="checkbox"
        checked={props.value}
      />
      <label htmlFor={id}>{<RemoveVector />}</label>
    </div>
  );
}
