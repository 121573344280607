import { ReactComponent as CheckActiveIcon } from "@/assets/check-icon-active.svg";
import { ReactComponent as CheckIcon } from "@/assets/check-icon-lm.svg";
import "../vector-modal.scoped.css";
import generateUniqueId from '@/utils/generateUniqueId';

export function FixedCheckboxCellRenderer(props) {
  const id = generateUniqueId();
  const handleCheckboxChange = (event) => {
    console.log(props);
    props.handleChange(props.data.id, props);
  };

  return (
    <div style={{ marginRight: "15px" }}>
      <input
        type="checkbox"
        id={id}
        onChange={handleCheckboxChange}
        className="checkbox"
        checked={props.value}
      />
      <label htmlFor={id}>
        {props.value !== false ? <CheckIcon /> : <CheckActiveIcon />}
      </label>
    </div>
  );
}
