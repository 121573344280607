import { ReactComponent as CheckActiveIcon } from '@/assets/check-icon-active.svg';
import { ReactComponent as CheckIcon } from '@/assets/check-icon-lm.svg';
import '../vector-modal.scoped.css';
import { useState } from 'react';
import generateUniqueId from '@/utils/generateUniqueId';

export function HeaderSelectAll(props) {
  const id = generateUniqueId();

  const getIsAllIgnore = () => {
    let rowData = [];
    props.api.forEachNode((node) => rowData.push(node.data));

    const isIgnoreArray = rowData.map((row) => row['is_ignore']);
    if (isIgnoreArray.length === 0) return false;
    const isIgnoreOld = isIgnoreArray.every((value) => value !== false);
    // props.api.refreshHeader()
    return isIgnoreOld;
  };
  const [isAllIgnore, setIsAllIgnore] = useState(getIsAllIgnore());

  const handleSelectAll = () => {
    console.log('isAllIgnoreisAllIgnore11', isAllIgnore);
    props.handleSelectAll(isAllIgnore);
    setIsAllIgnore((prev) => !prev);
  };
  return (
    <div className="custom-header">
      <input
        type="checkbox"
        id={id}
        onChange={handleSelectAll}
        className="checkbox"
        checked={isAllIgnore}
      />
      <label htmlFor={id}>{isAllIgnore ? <CheckActiveIcon /> : <CheckIcon />}</label>
      <span>{props.displayName}</span>
    </div>
  );
}
