import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import './vector-modal.scoped.css'
import { Modal } from '@/components/Modal/Modal'
import FilledBtn from '@/components/FilledBtn/FilledBtn'
import axios from '@/configs/axios.js'
import moment, { max } from 'moment'
import { useAtom } from 'jotai'
import { userAtom } from '@/atoms/general'
import { isEqual } from 'lodash'
import { markedDocumentsAtom } from '@/atoms/documents'
import TextField from '@mui/material/TextField'

import { AgGridReact } from 'ag-grid-react/lib/agGridReact'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { HeaderSelectAll } from './ag-grid/HeaderSelectAll'
import { CheckboxCellRenderer } from './ag-grid/CheckboxCellRenderer'
import { FixedCheckboxCellRenderer } from './ag-grid/FixedCheckboxCellRenderer'
import { RemoveCellRenderer } from './ag-grid/RemoveCellRenderer'
import { ReactComponent as AddVector } from '@/assets/add_new_vector.svg'
import { ReactComponent as AddLinks } from '@/assets/add_link_icon.svg'

import { v4 as uuidv4 } from 'uuid'

const defaultColDef = {
  resizable: true,
  sortable: true,
}

function VectorModal({ isOpen, subHeader, onClose, type, docId }) {
  const gridApi = useRef(null)
  const changedDocsRef = useRef([])
  const [isAddLinksPopUpOpen, setIsAddLinksPopUpOpen] = useState(false)
  const [linksAllData, setLinksAllData] = useState({ link_url: '', link_title: '' })
  const handleSelectAll = (isIgnoreOld) => {
    setDocumentsToShowUser((prev) => {
      let updatedDocs = [...prev] // shallow copy of the array
      updatedDocs = updatedDocs.map((doc) => {
        return (doc = { ...doc, is_ignore: !isIgnoreOld })
      })
      return updatedDocs
    })
  }
  const optionalColumns =
    type === 'questions'
      ? [
          {
            headerName: 'Question',
            field: 'question',
            editable: true,
            filter: true,
            width: 100,
            cellClass: 'ag-cell-text-left',
            valueGetter: (params) => {
              const content = params.data.content || 'Question: Answer:'
              return params.newValue
                ? params.newValue
                : content.split('Question:')[1]?.split('Answer:')[0] || ''
            },
            valueSetter: (params) => {
              const question = params.newValue || ''
              const content = params.data.content || 'Question: Answer:'
              const answer = content.split('Answer:')[1] || ''
              params.data.content = `Question:${question}Answer:${answer}`
              return true
            },
          },
          {
            headerName: 'Answer',
            field: 'answer',
            editable: true,
            filter: true,
            width: 100,
            cellClass: 'ag-cell-text-left',
            valueGetter: (params) => {
              const content = params.data.content || 'Question: Answer:'
              return params.newValue ? params.newValue : content.split('Answer:')[1] || ''
            },
            valueSetter: (params) => {
              const answer = params.newValue || ''
              const content = params.data.content || 'Question: Answer:'
              const question = content.split('Question:')[1]?.split('Answer:')[0] || ''
              params.data.content = `Question:${question}Answer:${answer}`
              return true
            },
          },
        ]
      : [
          {
            headerName: 'Content',
            field: 'content',
            editable: true,
            filter: true,
            width: 150,
            cellClass: 'ag-cell-text-left',
          },
        ]

  const [columnDefs] = useState([
    {
      headerName: 'Delete',
      field: 'id',
      editable: false,
      filter: false,
      width: 30,
      cellClass: 'ag-cell-text-middle',
      cellRendererSelector: () => {
        return {
          component: RemoveCellRenderer,
          params: {
            handleChange: RemoveContentVector,
          },
        }
      },
    },
    {
      headerName: 'Select All',
      field: 'is_ignore',
      filter: true,
      width: 56,
      headerComponent: HeaderSelectAll,
      headerComponentParams: {
        handleSelectAll: handleSelectAll, // the pound symbol will be placed into params
      },
      cellRendererSelector: () => {
        return {
          component: CheckboxCellRenderer,
          params: {
            handleChange: handleMarkChange,
          },
        }
      },
    },
    ...optionalColumns,
    {
      headerName: 'Link',
      field: 'link',
      editable: true,
      filter: true,
      width: 50,
      cellClass: 'ag-cell-text-left',
      valueGetter: (params) => {
        return params.data.metadata?.link_url || ''
      },
      valueSetter: (params) => {
        const link = params.newValue
        params.data.metadata.link_url = link || ''
        return true
      },
    },
    {
      headerName: 'Link Title',
      field: 'link title',
      editable: true,
      filter: true,
      width: 50,
      cellClass: 'ag-cell-text-left',
      valueGetter: (params) => {
        return params.data.metadata?.link_title || ''
      },
      valueSetter: (params) => {
        const linkTitle = params.newValue
        params.data.metadata.link_title = linkTitle || ''
        return true
      },
    },
    {
      headerName: 'Fixed',
      field: 'fixed',
      filter: true,
      width: 30,
      // headerComponent: HeaderSelectAll,
      // headerComponentParams: {
      //   handleSelectAll: handleSelectAll, // the pound symbol will be placed into params
      // },
      cellRendererSelector: () => {
        return {
          component: FixedCheckboxCellRenderer,
          // params: {
          //   handleChange: handleMarkChange,
          // },
        }
      },
    },
    {
      headerName: 'Edit By',
      valueGetter: () => 'Me',
      filter: true,
      width: 30,
    },
    {
      headerName: 'Created At',
      field: 'created_at',
      filter: true,
      width: 30,
      valueFormatter: (params) => moment(Number(params.value)).format('MMM D'),
    },
  ])

  const [user] = useAtom(userAtom)
  // console.log(user.email.split('@')[0])
  const [documentsToShowUser, setDocumentsToShowUser] = useState([])
  console.log(documentsToShowUser)
  const [initialVectors, setInitialVectors] = useState([])

  const [markedDocuments] = useAtom(markedDocumentsAtom)
  const [summery, setSummery] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  let header = ''
  switch (type) {
    case 'summary':
      header = 'Document Summary'
      break
    case 'questions':
      header = 'Questions & Answers'
      break

    case 'title':
      header = 'Divided By Title'

      break
    case 'recursivellama':
      header = 'Divided By Recursivellama'
      break
    case 'llamaPipeline':
      header = 'Divided By llamaPipeline'
      break
    case 'Flex':
      header = 'Divided By Flex'
      break
    case 'small':
      header = 'Divided By small'
      break
    case 'large':
      header = 'Divided By large'
      break
    case 'titles-summary':
      header = 'Titles with summary'
  }

  useEffect(() => {
    const getSummeryDoc = async () => {
      const vectorsResponse = await axios.post('/api/documents/get-doc-summery', {
        docId: docId,
        userId: user.id,
      })
      setSummery(vectorsResponse.data.doc_summery)
    }
    const getVectorsOfDocs = async () => {
      try {
        const vectorsResponse = await axios.post('/api/documents/get-docs-vectors', {
          docs: JSON.stringify(markedDocuments.map((doc) => doc.docId)),
          userId: user.id,
          method: type,
        })
        setDocumentsToShowUser([...vectorsResponse.data])
        setInitialVectors([...vectorsResponse.data])
        changedDocsRef.current = [...vectorsResponse.data]
      } catch (err) {
        console.log(err)
      }
    }
    if (type === 'summary') {
      getSummeryDoc()
    } else {
      getVectorsOfDocs()
    }
  }, [])

  const onGridReady = useCallback((params) => {
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit()
      })
    })
    gridApi.current = params.api
  }, [])

  const headerRefresh = useCallback((params) => {
    params.api.refreshHeader()
  }, [])

  const autoSizeStrategy = useMemo(() => {
    return {
      type: 'fitGridWidth',
    }
  }, [])

  const differences = useMemo(() => {
    const added = documentsToShowUser.filter(
      (newObj) => !initialVectors.some((oldObj) => oldObj.id === newObj.id)
    )
    const removed = initialVectors.filter(
      (oldObj) => !documentsToShowUser.some((newObj) => newObj.id === oldObj.id)
    )
    const modified = changedDocsRef.current.filter((obj) => obj.isCreated !== true)
    // const modified = initialVectors.filter((newObj) => {
    //   const original = documentsToShowUser.find(
    //     (oldObj) => oldObj.id === newObj.id
    //   );
    //   return original && !isEqual(original.content, newObj.content);
    // });
    console.log({ added, removed, modified })
    return { added, removed, modified }
  }, [documentsToShowUser])

  const handleContentChange = (e) => {
    const docId = e.data.id
    const field = e.colDef.field
    const changedDocIndex = changedDocsRef.current.findIndex((doc) => doc.id === docId)

    let newValue = e.data.content || 'Question: Answer:'
    let question = newValue.split('Question:')[1]?.split('Answer:')[0] || ''
    let answer = newValue.split('Answer:')[1] || ''

    if (field === 'question') {
      question = e.newValue
    } else if (field === 'answer') {
      answer = e.newValue
    }
    newValue = `Question:${question}Answer:${answer}`

    if (changedDocIndex !== -1) {
      // Update the content of existing vector
      changedDocsRef.current[changedDocIndex].content = e.data.content || e.value
      changedDocsRef.current[changedDocIndex].type = e.data.type
      changedDocsRef.current[changedDocIndex].metadata.link_url = e.data.metadata.link_url
      changedDocsRef.current[changedDocIndex].metadata.link_title = e.data.metadata.link_title
    } else {
      // Create a new vector with its content
      changedDocsRef.current.push({
        id: docId,
        ...documentsToShowUser[changedDocIndex],
        content: type === 'questions' ? newValue : e.value,
        type: type === 'questions' ? 'question-answer-data' : 'chunk-data',
        isCreated: true,
        lastUpdated: Date.now().toString(),
        lastUpdatedBy: user.id,
        metadata: {
          link_url: e.data.metadata?.link_url || '',
          link_title: e.data.metadata?.link_title || '',
        },
      })
      console.log(changedDocsRef.current)
    }

    console.log('====================================')
    console.log(changedDocsRef.current, 'changedDocsRef.current')
    console.log('====================================')

    setDocumentsToShowUser((prev) => {
      const updatedDocs = [...prev] // shallow copy of the array
      const docIndex = updatedDocs.findIndex((doc) => doc.id === docId)
      if (docIndex !== -1) {
        updatedDocs[docIndex] = {
          ...updatedDocs[docIndex],
          content: type === 'questions' ? newValue : e.data.content || e.value,
          type: type === 'questions' ? 'question-answer-data' : 'chunk-data',
          metadata: {
            ...updatedDocs[docIndex].metadata,
            link_url: e.data.metadata.link_url,
            link_title: e.data.metadata.link_title,
          },
        }
      }
      return updatedDocs
    })
  }

  const handleMarkChange = (docId, params) => {
    const { node, api } = params
    const { is_ignore } = node.data
    node.updateData({
      ...node.data,
      is_ignore: is_ignore !== false ? false : true,
    })

    setDocumentsToShowUser((prev) => {
      // console.log(prev);
      const updatedDocs = prev
      const docIndex = updatedDocs.findIndex((doc) => doc.id === docId)

      const changedDocIndex = changedDocsRef.current.findIndex((doc) => doc.id === docId)
      if (changedDocIndex !== -1) {
        // Update the content of the changed document
        changedDocsRef.current[changedDocIndex].is_ignore = !updatedDocs[docIndex].is_ignore
      } else {
        // Add a new changed document to the ref
        changedDocsRef.current.push({
          id: docId,
          ...updatedDocs[docIndex],
          is_ignore: !updatedDocs[docIndex].is_ignore,
        })
      }

      if (docIndex !== -1) {
        // Only update the content of the specific document
        updatedDocs[docIndex] = {
          ...updatedDocs[docIndex],
          is_ignore: !updatedDocs[docIndex].is_ignore,
        }
      }
      return updatedDocs
    })
    api.refreshHeader()
  }

  const handleSubmitSummery = async () => {
    try {
      setIsLoading(true)

      const response = await axios.put('/api/documents/file-summery', {
        userId: user.id,
        docId: docId,
        fileContent: summery,
      })
      onClose()
    } catch (err) {
      console.log(err)
    }
  }

  const AddContentVector = () => {
    // console.log(gridApi.current);
    let updatedDocs
    setDocumentsToShowUser((prev) => {
      // console.log(prev);
      updatedDocs = [...prev]
      updatedDocs.push({
        chunk_index: '',
        content: type === 'questions' ? 'Question: Answer:' : '',
        id: uuidv4(),
        is_ignore: true,
        metadata: {
          link_url: '',
          link_title: '',
        },
        // vector_modified_at: Date.now().toString(),
        fixed: false,
        lastUpdatedAt: Date.now().toString(),
        lastUpdatedBy: user.id,
        created_at: Date.now().toString(),
        type: type === 'questions' ? 'question-answer-data' : 'chunk-data',
        isCreated: true,
      })
      return updatedDocs
    })

    // Wait for the grid to process the update
    setTimeout(() => {
      if (gridApi.current) {
        gridApi.current.paginationGoToLastPage()
        const lastRowIndex = updatedDocs.length - 1

        // Scroll to the last row
        gridApi.current.ensureIndexVisible(lastRowIndex)

        // Get the row node and set focus
        const lastRowNode = gridApi.current.getDisplayedRowAtIndex(lastRowIndex)
        if (lastRowNode) {
          lastRowNode.setSelected(true)
          gridApi.current.setFocusedCell(lastRowIndex, gridApi.current.getColumnDefs()[0].field)
        }
      }
    }, 0)
  }

  const RemoveContentVector = (docId, params) => {
    let updatedDocs
    setDocumentsToShowUser((prev) => {
      // console.log(prev)
      updatedDocs = [...prev]
      updatedDocs = updatedDocs.filter((doc) => doc.id !== docId)

      return updatedDocs
    })

    // Wait for the grid to process the update
    setTimeout(() => {
      if (gridApi.current) {
        gridApi.current.paginationGoToLastPage()
        const lastRowIndex = updatedDocs.length - 1

        // Scroll to the last row
        gridApi.current.ensureIndexVisible(lastRowIndex)

        // Get the row node and set focus
        const lastRowNode = gridApi.current.getDisplayedRowAtIndex(lastRowIndex)
        if (lastRowNode) {
          lastRowNode.setSelected(true)
          gridApi.current.setFocusedCell(lastRowIndex, gridApi.current.getColumnDefs()[0].field)
        }
      }
    }, 0)
  }

  const inputsStyle = {
    style: { color: 'var(--text-clr)', opacity: '0.5' },
  }

  const handleSubmit = async () => {
    const { added, removed, modified } = differences
    try {
      setIsLoading(true)

      if (added.length > 0) {
        await axios.post('/api/documents/create-vectors', {
          vectors: JSON.stringify(added),
          userId: user.id,
          docId: docId,
          fixed: false,
          // lastUpdated: Date.now().toString(),
          // updatedBy: user.id,
        })
      }
      if (removed.length > 0) {
        await axios.post('/api/documents/delete-vectors', {
          vectors: JSON.stringify(removed),
        })
      }
      if (modified.length > 0) {
        await axios.put('/api/documents/edit-vectors', {
          vectors: JSON.stringify(modified),
          fixed: false,
          lastUpdatedAt: Date.now().toString(),
          lastUpdatedBy: user.id,
        })
      }

      if (added.length === 0 && removed.length === 0 && modified.length === 0) {
        console.log('No changes have been made to the vectors table!')
      }

      onClose()
    } catch (err) {
      alert('Error while submitting changes')
    } finally {
      setIsLoading(false)
    }
  }

  const handleLinkUrlChange = (e) => {
    const { name, value } = e.target

    setLinksAllData((prevData) => ({
      ...prevData,
      [name === 'link' ? 'link_url' : 'link_title']: value,
    }))
  }

  const submitAllLinksChange = () => {
    changedDocsRef.current = changedDocsRef.current.map((document) => ({
      ...document,
      metadata: {
        ...document.metadata,
        link_url: linksAllData.link_url,
        link_title: linksAllData.link_title,
      },
    }))
    setDocumentsToShowUser((prev) => {
      return prev.map((document) => ({
        ...document,
        metadata: {
          ...document.metadata, // Preserve existing metadata properties
          link_url: linksAllData.link_url, // Update the link_url
          link_title: linksAllData.link_title, // Update the link_title
        },
      }))
    })
    setIsAddLinksPopUpOpen(false)
  }

  if (isLoading) {
    return (
      <Modal
        key="add-folder"
        header={'loading...'}
        subHeader={'loading...'}
        onClose={onClose}
        isOpen={isOpen}
        style={{
          height: 'auto',
          backgroundColor: 'var(--chat-background-clr)',
          width: '90%',
          maxWidth: 'none',
        }}
      >
        loading...
      </Modal>
    )
  }

  if (isAddLinksPopUpOpen) {
    return (
      <Modal
        key="add-folder"
        header={'Add a general link'}
        subHeader={'Add one link for all chuncks'}
        onClose={() => setIsAddLinksPopUpOpen(false)}
        isOpen={isAddLinksPopUpOpen}
        backFunction={() => setIsAddLinksPopUpOpen(false)}
        style={{
          height: 'auto',
          backgroundColor: 'var(--chat-background-clr)',
          width: '90%',
          maxWidth: 'none',
        }}
      >
        <TextField
          InputLabelProps={inputsStyle}
          value={linksAllData.link_url}
          variant="filled"
          label="Link"
          name="link"
          InputProps={{
            disableUnderline: true, // Disables the underline
          }}
          style={{ maxWidth: '500px', width: '100%' }}
          onChange={handleLinkUrlChange}
        />
        <TextField
          InputLabelProps={inputsStyle}
          value={linksAllData.link_title}
          variant="filled"
          label="Link Title"
          name="link title"
          InputProps={{
            disableUnderline: true, // Disables the underline
          }}
          style={{ maxWidth: '500px', width: '100%' }}
          onChange={handleLinkUrlChange}
        />
        <FilledBtn
          disabled={!(linksAllData.link_url && linksAllData.link_title)}
          onClick={submitAllLinksChange}
        >
          Apply All
        </FilledBtn>
      </Modal>
    )
  }
  if (type !== 'summary') {
    return (
      <Modal
        key="add-folder"
        header={header}
        subHeader={subHeader}
        onClose={onClose}
        isOpen={isOpen}
        style={{
          height: 'auto',
          backgroundColor: 'var(--chat-background-clr)',
          width: '90%',
          maxWidth: 'none',
        }}
      >
        <div className="ag-theme-alpine" style={{ width: '100%', height: '40vh' }}>
          <AgGridReact
            columnDefs={columnDefs}
            rowData={documentsToShowUser}
            defaultColDef={defaultColDef}
            onCellValueChanged={handleContentChange}
            onGridReady={onGridReady}
            autoSizeStrategy={autoSizeStrategy}
            pagination={true}
            paginationPageSize={20}
            onRowDataUpdated={headerRefresh}
            suppressHorizontalScroll={false}
            // onRowDataChanged={handleRowDataChanged}
            // onRowValueChanged={handleContentChange}
            // rowModelType={'serverSide'}
          />

          <div className="icons">
            <AddVector alt="add content" onClick={AddContentVector} />
            <AddLinks alt="add links" onClick={() => setIsAddLinksPopUpOpen(true)} />
          </div>
        </div>

        <FilledBtn onClick={handleSubmit}>Submit</FilledBtn>
      </Modal>
    )
  } else {
    return (
      <Modal
        key="add-folder"
        header={header}
        subHeader={subHeader}
        onClose={onClose}
        isOpen={isOpen}
        style={{
          height: 'auto',
          backgroundColor: 'var(--chat-background-clr)',
          width: '90%',
          maxWidth: 'none',
        }}
      >
        <textarea
          alt="type your text here"
          className="question-input"
          type="text"
          value={summery}
          onChange={(e) => setSummery(e.target.value)}
        />
        <FilledBtn onClick={handleSubmitSummery}>Submit</FilledBtn>
      </Modal>
    )
  }
}
export default VectorModal
