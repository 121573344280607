import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

export const firebaseConfig = {
  apiKey: 'AIzaSyCGMelXicbAmMkokogkrgD3nkFlhw1fGqo',
  authDomain: 'gekochatbot.firebaseapp.com',
  projectId: 'gekochatbot',
  storageBucket: 'gekochatbot.appspot.com',
  messagingSenderId: '7922318951',
  appId: '1:7922318951:web:81d1ecf9b5f83a3b335ec2',
  measurementId: 'G-8HWX1JSWTH',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence);

export default app;
