import './welcomePage.scss';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '@/assets/login-logo.svg';
import { ReactComponent as CreditLogo } from '@/assets/credit-logo.svg';

export const WelcomePage = () => {
  return (
    <div className="welcome-page">
      <div className="modal-full-screen">
        <div className="modal-container">
          <div className="modal-upper">
            <Logo />
          </div>
          <h2>Welcome Back</h2>
          <div className="form-container">
            <Link to="/register">
              <button className="register-btn">Register</button>
            </Link>
            <Link className="link-margin-20" to="/login">
              <button className="login-btn">Login</button>
            </Link>
          </div>
        </div>
        <div className="bottom-credit">
          <h4>Created By</h4>
          <CreditLogo />
        </div>
      </div>
    </div>
  );
};
