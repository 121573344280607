import React from 'react';
import InfoIcon from "@mui/icons-material/Info";
import "../voice-table.scoped.css";

const InfoIconRenderer = (props) => {
  const handleButtonClick = (event) => {
    props.handleChange(props.data.id, props)
  }
  return (
    <div className="more-info-icon">
      <InfoIcon style={{ fontSize: "xx-large" }} onClick={handleButtonClick} />
    </div>
  );
};

export default InfoIconRenderer;