import { Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { groupsAtomWithUpdates } from '@/atoms/groups';
import './table-inner-row.scoped.css';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAtom } from 'jotai';
import { updateUserToken, updateUserModel } from '@/api/groups';

export const TableInnerRow = ({ user, group, settings }) => {
  const [, setGroups] = useAtom(groupsAtomWithUpdates);

  function getModelsLabels(modelsArr) {
    const newArr = modelsArr
      .map((model) => {
        const result = settings[0].options.find((option) => option.value === model);
        return result ? result.label : null;
      })
      .filter((option) => option !== null);

    return newArr;
  }

  const getToken = (token) => {
    const chosen = settings[1].options.find((option) => option.value === token);
    return chosen;
  };

  const handleTokenChange = (event) => {
    const newTokenValue = event.target.value;

    updateUserToken({ userId: user.id, token: newTokenValue });

    setGroups({
      type: 'updateUserToken',
      payload: { groupId: user.group_id, userId: user.id, val: newTokenValue },
    });
  };

  const handleModelChange = (event) => {
    const newModelsValue = event.target.value;

    updateUserModel({ userId: user.id, model: newModelsValue });

    setGroups({
      type: 'updateUserModel',
      payload: { groupId: user.group_id, userId: user.id, val: newModelsValue },
    });
  };

  return (
    <tr>
      <td />
      <td>{group.group_name}</td>
      <td style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{user.name || 'Null'}</td>
      <td style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{user.email || 'Null'}</td>
      <td>{user.title || 'Null'}</td>
      <td>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {user.token_limit > group.default_max_tokens_per_user && (
            <div
              style={{
                marginLeft: '0px',
                height: '0px',
                marginTop: '10px',
              }}
            >
              <span
                style={{
                  color: `var(--red-clr)`,
                  marginTop: '10px',
                  fontSize: '32px',
                  marginLeft: '-20px',
                }}
              >
                *
              </span>
            </div>
          )}
          <div>
            {user.token_limit !== null ? (
              <Select
                className="select-box-tool-box"
                size="medium"
                id={user.id}
                value={getToken(user.token_limit).value}
                style={{ width: '100px' }}
                label="Token"
                name="token"
                onChange={handleTokenChange}
              >
                {settings[1].options.map((option) => (
                  <MenuItem key={uuidv4()} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              'Null'
            )}
          </div>
        </div>
      </td>
      <td>{`${Math.floor(user.token_used / 1000)}k / ${
        user.token_limit !== null ? getToken(user.token_limit).label : 'null'
      }`}</td>
      <td>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {user.model !== null && user.model.length > group.default_model.length && (
            <div
              style={{
                marginLeft: '0px',
                height: '0px',
                marginTop: '10px',
              }}
            >
              <span
                style={{
                  color: `var(--red-clr)`,
                  marginTop: '10px',
                  fontSize: '32px',
                  marginLeft: '-20px',
                }}
              >
                *
              </span>
            </div>
          )}

          <div>
            {user.model !== null ? (
              <Select
                className="select-box-tool-box"
                size="medium"
                id={`${Math.floor(Math.random() * 25)}`}
                multiple
                value={user.model}
                renderValue={(selected) => selected.join(', ')}
                label="Model"
                name="model"
                onChange={handleModelChange}
              >
                {settings[0].options.map((option) => (
                  <MenuItem key={uuidv4()} value={option.value}>
                    <Checkbox checked={user.model.includes(option.value)} />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </Select>
            ) : (
              'Null'
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};
