export function addFile(files, subfolders, newFile, folderId = null) {
    if (folderId === null) {
        // Add to the top level if no folderId is specified
        return { files: [...files, newFile], subfolders };
    } else {
        // Add to the specified folder
        const newTopLevelFolders = subfolders.map(folder => {
            return addFileToFolder(folder, newFile, folderId);
        });
        return { files, subfolders: newTopLevelFolders };
    }
  }
  
  function addFileToFolder(folder, file, folderId) {
    if (folder.id === folderId) {
        // Folder to add the file is found
        return { ...folder, files: [...folder.files, file] };
    } else {
        // Search in subfolders
        const newSubfolders = folder.subfolders.map(subfolder => {
            return addFileToFolder(subfolder, file, folderId);
        });
        return { ...folder, subfolders: newSubfolders };
    }
  }