import React, { useState } from "react";
import './read-more.scoped.css'

// function replaceSentenceEndingsWithBr(text) {
//   return text.replace(/([.!?])(\s|$)/g, '$1\n$2');
// }

// function replaceLineEndingsWithBr(text) {
//   return text.replace(/\n/g, '<br>');
// }

const ReadMore = ({ text, limit, size }) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const textLength = text.length;

  // const TransformedText = replaceLineEndingsWithBr(text)
  const TransformedText =text

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  // function splitAndFormatString(inputText) {
  //   // Split the string by periods, filtering out empty segments if necessary
  //   const segments = inputText.split('.').filter(segment => segment.trim() !== '');
  //   // Rejoin the segments with a period and a newline
  //   const formattedText = segments.join('\n .');
  //   return formattedText;
  // }

  return textLength > limit ? (
    <p className="expand-text" style={{ width: size, minWidth: '600px', maxWidth: size, fontWeight: 400, textAlign: 'right' }} dir="rtl">
      {isReadMore ? TransformedText.slice(0, limit) : TransformedText}
      <span
        onClick={toggleReadMore}

        className="read-or-hide"
      >
        {isReadMore ? <><br /><b className="read-or-hide-text"> הצג יותר</b></> : <><br /><b className="read-or-hide-text"> הצג פחות</b></>}
      </span>
    </p>
  ) : (
    <p dir="rtl" style={{ textAlign: 'right' }}>{TransformedText}</p>
  );
};

export default ReadMore;
