import React from 'react'
import './filled-btn-scoped.css'
function FilledBtn({type,disabled,children,onClick,style={}}) {

  return (
    <button style={style} onClick={onClick} type={type} disabled={disabled} className='filled-btn'> 
    {children}
    </button>
  )
}

export default FilledBtn