import "./admin-table.scoped.css";
import React, { useState } from "react";
import ExpandableTable from "@/components/ExpandableTable/ExpandableTable";
import { filteredGroupsAtom, searchAtom } from "@/atoms/groups";
import { useAtom } from "jotai";

export function AdminTable() {
  const [searchName, setSearchName] = useAtom(searchAtom);
  const [filteredGroups] = useAtom(filteredGroupsAtom);
  const [expandedGroupId, setExpandedGroupId] = useState(null);

  const settingsArr = [
    {
      name: "model",
      options: [
        { label: "GPT 4", value: "gpt-4", id: 1 },
        { label: "GPT 3.5 Turbo 16k", value: "gpt-3.5-turbo-16k", id: 3 },
      ],
    },
    {
      name: "token",
      options: [
        { label: "100K", value: 100000, id: 1 },
        { label: "150K", value: 150000, id: 2 },
        { label: "200K", value: 200000, id: 3 },
        { label: "250K", value: 250000, id: 4 },
        { label: "300K", value: 300000, id: 5 },
        { label: "350K", value: 350000, id: 6 },
        { label: "400K", value: 400000, id: 7 },
        { label: "450K", value: 450000, id: 8 },
        { label: "500K", value: 500000, id: 9 },
        { label: "1M", value: 1000000, id: 10 },
      ],
    },
  ];

  return (
    <>
      <div className="search-box-container">
        <input
          value={searchName}
          className="search-input"
          placeholder="Search"
          type="text"
          onChange={(e) => setSearchName(e.target.value)}
        />
      </div>

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th />
              <th style={{ textAlign: "left" }}>Group</th>
              <th style={{ textAlign: "left" }}>Name</th>
              <th style={{ textAlign: "left" }}>Email</th>
              <th style={{ textAlign: "left" }}>Title</th>
              <th style={{ textAlign: "left" }}>Token Per Month</th>
              <th style={{ textAlign: "left" }}>Token Used</th>
              <th style={{ textAlign: "left" }}>Ai Model</th>
            </tr>
          </thead>

          <tbody>
            {filteredGroups.length > 0 &&
              filteredGroups.map((group, index) => {
                return (
                  group && (
                    <ExpandableTable
                      key={group.id || index}
                      group={group}
                      settings={settingsArr}
                      isExpanded={expandedGroupId === group.group_id}
                      onExpand={() =>
                        setExpandedGroupId(
                          expandedGroupId === group.group_id
                            ? null
                            : group.group_id
                        )
                      }
                    />
                  )
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
}
