import { atom } from 'jotai';
import { findActiveFolderAndNesting } from '@/api/documents';
import { getMarkedFolders, getMarkedFoldersInSubfolders } from '@/services/mark/getMarkedFolders';

///atom that represents the folder that the user pressed (it is string if in top folder)
export const activeFolderAtom = atom('');

/// atom that represents the folders that inside the active folder

// atom that represents the breadcrumbs path, consist of objects with the fields {parentId,folderId,name}
export const pathAtom = atom([]);

//atom that represents the folders that the users marked (for delete or other actions in the future)
// export const markedFoldersAtom = atom([])

//// entire tree resprenting the folders and files atoms
export const treeAtom = atom('');

// export const userFoldersAtom =atom('')

export const userFoldersAtom = atom((get) => {
  let entireTree = get(treeAtom);
  let activeFolder = get(activeFolderAtom);
  let content;

  if (entireTree) {
    if (activeFolder) {
      // if (content != null) {
      //   console.log(content)
      //   return content.files
      // }
      // else {
      //   return {files: entireTree.files, subfolders:[]}
      // }
      content = findActiveFolderAndNesting(entireTree.subfolders, activeFolder.id);
      if (content != null) {
        return content.subfolders;
      } else {
        return [];
      }
    } else {
      // console.log(entireTree);

      return entireTree.subfolders;
    }
  } else {
    return '';
  }
});

export const markedFoldersAtom = atom((get) => {
  let entireTree = get(treeAtom);
  if (entireTree) {
    return getMarkedFolders(entireTree.subfolders);
  } else {
    return [];
  }
});

export const entireTreeSizeAtom = atom((get) => {
  let entireTree = get(treeAtom);
  let count = 0;
  const countDocs = (entireTree) => {
    if (entireTree.subfolders.length > 0)
      entireTree.subfolders.forEach((subfolder) => {
        count += subfolder.files.length;
        countDocs(subfolder);
      });
  };
  if (entireTree) {
    if (entireTree.files.length > 0) count += entireTree.files.length;
    countDocs(entireTree);
  } else count = 0;

  return count;
});

export const currentTreeSizeAtom = atom((get) => {
  let entireTree = get(treeAtom);
  const entireTreeSize = get(entireTreeSizeAtom);
  let activeFolder = get(activeFolderAtom);
  let count = 0;

  const countDocs = (folder) => {
    if (folder.files.length > 0) count += folder.files.length;
    if (folder.subfolders.length > 0) {
      folder.subfolders.forEach((subfolder) => {
        count += subfolder.files.length;
        countDocs(subfolder);
      });
    }
  };

  if (entireTree && activeFolder) {
    const currentFolder = findActiveFolderAndNesting(entireTree.subfolders, activeFolder.id);
    if (currentFolder) {
      countDocs(currentFolder);
    }
  }
  if (activeFolder === '') return entireTreeSize;
  else return count;
});
