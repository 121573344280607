export function editFolder(files, subfolders, folderId, folderName) {
    // Edit folders at the top level
    const newSubfolders = subfolders.map(folder => {
        return editFolderInFolder(folder, folderId,folderName);
    });

    return { files, subfolders: newSubfolders };
}

function editFolderInFolder(folder, folderId,folderName) {
    if (folder.id === folderId) {
        // The current folder is the one to be edited
        return { ...folder,folder_name :folderName };
    }

    // Otherwise, recurse into subfolders
    const newSubfolders = folder.subfolders.map(subfolder => {
        return editFolderInFolder(subfolder, folderId, folderName);
    });

    return { ...folder, subfolders: newSubfolders };
}
