import './conversion-table.scoped.css';
import React from 'react';
import { ReactComponent as CheckActiveIcon } from '@/assets/check-icon-active.svg';
import { ReactComponent as CheckIcon } from '@/assets/check-icon-lm.svg';
import { ReactComponent as DeleteIcon } from '@/assets/remove_conversions.svg';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { v4 as uuidv4 } from 'uuid';
import { useAtom } from 'jotai';
import { updateTranslatesAtom } from '@/atoms/translates';

export const TableRow = ({ translate, settings }) => {
  const [, setTranslates] = useAtom(updateTranslatesAtom);

  const handleType = (event) => {
    const newType = event.target.value;
    setTranslates({
      type: 'updateType',
      payload: { translateId: translate.id, val: newType },
    });
  };

  const handleTranslated = (event) => {
    const newTranslated = event.target.value;
    setTranslates({
      type: 'updateTranslated',
      payload: { translateId: translate.id, val: newTranslated },
    });
  };

  const handleSrc = (event) => {
    const newSrc = event.target.value;
    setTranslates({
      type: 'updateSrc',
      payload: { translateId: translate.id, val: newSrc },
    });
  };

  const handleCheckbox = () => {
    setTranslates({
      type: 'updateActive',
      payload: { translateId: translate.id, val: !translate.is_active },
    });
  };

  const handleDelete = () => {
    setTranslates({
      type: 'deleteTranslate',
      payload: { translateId: translate.id },
    });
  };

  return (
    <tr>
      <td style={{ textAlign: 'left', display: 'flex' }}>
        <div onClick={handleCheckbox}>{translate.is_active ? <CheckActiveIcon /> : <CheckIcon />}</div>
        <div key={`deleteboxId-${translate.id}`} onClick={handleDelete} style={{ marginLeft: '10px' }}>
          <DeleteIcon />
        </div>
      </td>
      <td style={{ textAlign: 'left' }}>
        <Select
          className="select-box-tool-box"
          size="medium"
          id={`${Math.floor(Math.random() * 25)}`}
          value={translate.type}
          style={{ width: '150px' }}
          label="Type"
          name="type"
          onChange={handleType}
        >
          {settings[0].options.map((option) => (
            <MenuItem key={uuidv4()} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </td>
      <td style={{ textAlign: 'left' }}>
        <textarea
          name="src-text"
          value={translate.src}
          onChange={handleSrc}
          style={{ fontSize: 'medium' }}
        />
      </td>
      <td style={{ textAlign: 'left' }}>
        <textarea
          name="translated-text"
          value={translate.translated}
          onChange={handleTranslated}
          style={{ fontSize: 'medium' }}
        />
      </td>
      <td style={{ textAlign: 'center', direction: 'rtl' }}>{translate.last_updated}</td>
    </tr>
  );
};
