import axios from '@/configs/axios.js';
import { useEffect, useState } from 'react'

export const TurboPage = () => {
  const [prompts, setPrompts] = useState(null)
  useEffect(() => {
    const getData = async () => {
      const { data } = await axios.get('/api/turbo')
      console.log(data)
      setPrompts(data[0])
    }
    getData()
  }, [])

  const handlePromptChange = async ({ target }) => {
    const { name, value } = target
    setPrompts((prev) => ({ ...prev, [name]: value }))
  }

  const savePrompt = async (name, value) => {
    if (!value) return
    await axios.put('/api/turbo/update-prompt', {
      name: name,
      value: value,
    })
  }

  if (!prompts) return <div></div>
  return (
    <div>
      <div className="prompt-container">
        <h2> analyze_prompt</h2>
        <textarea
          name="analyze_prompt"
          onChange={handlePromptChange}
          value={prompts.analyze_prompt}
          style={{ height: '250px', width: '600px' }}
        />
        <button onClick={() => savePrompt('analyze_prompt', prompts.analyze_prompt)}>
          save analyze_prompt
        </button>
      </div>
      <div className="prompt-container">
        <h2> docs_answers_rank</h2>
        <textarea
          name="docs_answers_rank"
          onChange={handlePromptChange}
          value={prompts.docs_answers_rank}
          style={{ height: '250px', width: '600px' }}
        />
        <button onClick={() => savePrompt('docs_answers_rank', prompts.docs_answers_rank)}>
          save docs_answers_rank
        </button>
      </div>

      <div className="prompt-container">
        <h2> summarize</h2>
        <textarea
          name="summarize"
          onChange={handlePromptChange}
          value={prompts.summarize}
          style={{ height: '250px', width: '600px' }}
        />
        <button onClick={() => savePrompt('summarize', prompts.summarize)}>save summarize</button>
      </div>
      <div className="prompt-container">
        <h2> summary</h2>
        <textarea
          name="summary"
          onChange={handlePromptChange}
          value={prompts.summary}
          style={{ height: '250px', width: '600px' }}
        />
        <button onClick={() => savePrompt('summary', prompts.summary)}>save summary</button>
      </div>
      <div className="prompt-container">
        <h2> questions_answers</h2>
        <textarea
          name="questions_answers"
          onChange={handlePromptChange}
          value={prompts.questions_answers}
          style={{ height: '250px', width: '600px' }}
        />
        <button onClick={() => savePrompt('questions_answers', prompts.questions_answers)}>save questions_answers</button>
      </div>
      <div className='prompt-container'>
      <h2>chunk_size</h2>
          <input type="number" 
          onChange={handlePromptChange}

          name="chunk_size" 
          value={prompts.chunk_size}
          min="0" max="100" />
    <button style={{marginBottom:'20px'}} onClick={() => savePrompt("chunk_size", prompts.chunk_size)}>
            save chunk_size
          </button></div>

          <div className='prompt-container'>
      <h2>overlap</h2>
          <input type="number" 
          onChange={handlePromptChange}

          name="overlap" 
          value={prompts.overlap}
          min="0" max="100" />
    <button style={{marginBottom:'20px'}} onClick={() => savePrompt("overlap", prompts.overlap)}>
            save overlap
          </button></div>



          <div className='prompt-container'>
      <h2>vectors</h2>
          <input type="number" 
          onChange={handlePromptChange}

          name="vectors" 
          value={prompts.vectors}
          min="0" max="10" />
    <button style={{marginBottom:'20px'}} onClick={() => savePrompt("vectors", prompts.vectors)}>
            save vectors
          </button></div>


          <div className='prompt-container'>
      <h2>grade</h2>
          <input type="number" 
          onChange={handlePromptChange}

          name="grade" 
          value={prompts.grade}
          min="0" max="100" />
    <button style={{marginBottom:'20px'}} onClick={() => savePrompt("grade", prompts.grade)}>
            save grade
          </button></div>
    </div>




  )
}
