import linkToSource from '@/assets/link-to-source.png';

export const formatTextBreakLine = (text, ids, answer) => {
  console.log(answer);
  return text.split('\n\n').map((item, i) => (
    <>
      {ids && i > 0 && <br />}
      {ids && <br />}
      {item}
      <br />
      {ids && (
        <div className="source-container">
          <img src={linkToSource} alt="link-to-source-img" />
          <a
            className="link-to-source"
            href={`/resource/[${`"${ids[answer.currAnsIndex ? answer.currAnsIndex : i]}"`}]`}
            target="_blank"
          >
            source
          </a>
        </div>
      )}
    </>
  ));
};

export const formatTextBreakLineForTyping = (text, ids, answer) => {
  console.log(text);
  return text.split('\n\n').map(
    (item, i) =>
      `
        ${ids && i > 0 ? '<br />' : ''}
        ${ids ? '<br />' : ''}
        ${item}
        <br />
        ${
          ids &&
            `<div className="source-container" style=" display: flex;alignItems: center;justifyContent: flex-start; gap: 2px;margin: 15px 0 "
         >
            <img src=${linkToSource} alt="link-to-source-img" />
            <a
              className="link-to-source"
              href=/resource/[${ids[answer.currAnsIndex ? answer.currAnsIndex : i]}]
              target="_blank"
            >
              source
            </a>
          </div>`
          // `<a
          // className='link-to-source'
          //   href=/resource/[${ids[answer.currAnsIndex ? answer.currAnsIndex : i]}]
          //   target="_blank"
          // >
          //   source
          // </a>`
        }
      `
  );
};

export const removeCharsFromAns = (str) => {
  let text = str;
  text = text.replace('Banker:', '');
  text = text.replace('בנקאי:', '');
  return text;
};

export const enlargeTextInput = () => {
  var el = document.getElementsByClassName('question-input');
  if (el[0]) {
    el[0].style.height = '5px';
    if (el[0].scrollHeight) {
      el[0].style.height = el[0].scrollHeight + 'px';
    }
  }
};
