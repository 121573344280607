import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as ModalCloseIcon } from '@/assets/modal-close-icon-lm.svg';
import { ReactComponent as DownloadButton } from '@/assets/download-button.svg';
import { ReactComponent as PositiveSentimentIocn } from '@/assets/smile-face-icon.svg';
import { ReactComponent as NegativeSentimentIcon } from '@/assets/sad-face-icon.svg';
import { ReactComponent as ProblemSolvedIcon } from '@/assets/check-icon.svg';
import { ReactComponent as ProblemUnsolvedIcon } from '@/assets/x-icon.svg';
import ReadMore from '@/components/ReadMoreCollapse/ReadMore.jsx';
import Player from '@/components//AudioPlayer/AudioPlayer.jsx';
// import SendIcon from "@mui/icons-material/Send";
// import AudioPlayer from "../../util/AudioPlayBtn/AudioPlayBtn.jsx";

import './document-info-modal.scoped.css';

const subHeaderStyle = {
  color: 'var(--main-clr)',
  fontStyle: 'normal',
  lineHeight: '20px',
  fontSize: '20px',
  fontWeight: '600',
  display: 'flex',
  flexDirection: 'row-reverse',
};

const headerStyle = {
  ...subHeaderStyle,
  color: 'var(--dark-purple, #260747)',
  fontSize: '24px',
  fontWeight: '600',
};

export function DocumentInfoModal({ header, children, isOpen, onClose, style = {}, document }) {
  const [audioSrc, setAudioSrc] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
  const takalot = document.takalot.length > 0 ? document.takalot[0].takala : [];
  const error = document.error && document.error.replace('.', '');
  const notFound = '-';
  // const readMoreSettings.widthSize = '820px';
  const readMoreSettings = {
    limit: 120,
    widthSize: '820px',
  };

  console.log(audioSrc);
  useEffect(() => {
    fetch(
      `/api/voice/download-audio-file/${document.file_name}`
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        setAudioSrc(url);
        setFileUrl(url);
      })
      .catch((error) => {
        console.error("Error fetching audio:", error);
      });
  }, []);

  // const handleSend = async () => {
  //   try {

  //     const response = await axios.post('/api/zarkor/update-call', {
  //       callFromPhone: document.phone_number,
  //       shortText: document.documentation,
  //       midText: document.summary,
  //       longText: document.transcript
  //     });
  //     console.log('Response:', response.data);
  //     alert("Successfully sent call to CRM!")
  //   } catch (error) {
  //     console.error('Error:', error);
  //     alert("Failed sending call to CRM!")
  //   }
  //   onClose()
  // }

  return (
    <div className="modal-full-screen">
      <div className="modal-container" style={style}>
        <button onClick={onClose} className="modal-close-btn">
          <ModalCloseIcon />
        </button>
        <div className="header">
          <Typography sx={{ ...headerStyle }}>{`${header}${document.id}`}</Typography>
        </div>

        <div className="header-buttons">
          {audioSrc ? (
            <>
              <a href={fileUrl} download={document.file_name}>
                <DownloadButton
                  style={{
                    marginTop: '5px',
                    color: 'var(--secondary-clr)',
                  }}
                />
              </a>
              {<Player audioSrc={audioSrc} />}
              {/* {<SendIcon style={{ color: "var(--cellcom-purple, #9631FF)" }} onClick={handleSend} />} */}
            </>
          ) : (
            <CircularProgress
              style={{
                color: 'var(--main-clr)',
                marginBottom: '9px',
              }}
            />
          )}
        </div>

        <div className="modal-content">
          <div className="document-details">
            <Typography sx={{ ...subHeaderStyle }}>נתונים נוספים</Typography>
            <div
              style={{ display: 'flex', flexDirection: 'row', gap: '40px', justifyContent: 'flex-end' }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <div className="details-part">
                  <div className="details-info-headers">
                    <p>{document.phone_number || notFound}</p>
                    <p>{document.file_name || 'שם קובץ לא נמצא'}</p>
                    <p>{document.created_at || 'מועד יצירה לא נמצא'}</p>
                    <p>{document.duration || 'משך שיחה לא נמצא'}</p>
                    <p>
                      {(document.country !== '') & (document.country !== null)
                        ? document.country
                        : notFound}
                    </p>
                    <p dir="rtl">{document.operator !== '' & document.operator !== null ? document.operator : notFound}</p>
                    <div>{takalot.length > 0 ? <ReadMore text={takalot} limit={readMoreSettings.limit} /> : notFound}</div>
                  </div>
                </div>
                <div className="details-part" style={{ justifyContent: 'flex-end' }}>
                  <div className="details-info-headers">
                    <div className="details-info-icons">
                      {document.problem ? <ProblemSolvedIcon /> : <ProblemUnsolvedIcon />}
                      <p>{document.problem ? "טופלה" : "לא טופלה"}</p>
                    </div>
                    <div className="details-info-icons">
                      {document.sentiment ? <PositiveSentimentIocn /> : <NegativeSentimentIcon />}
                      <p>{document.sentiment ? "חיובי" : "שלילי"}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                  justifyContent: 'space-between',
                }}
              >
                <div className="details-part">
                  <div className="details-sub-headers">
                    <span>מספר נייד</span>
                    <span>שם קובץ ההקלטה</span>
                    <span>מועד יצירה</span>
                    <span>משך השיחה</span>
                    <span>מדינה</span>
                    <span>מפעיל</span>
                    <span>תקלה מהמערכת</span>
                  </div>
                </div>
                <div className="details-part">
                  <div className="details-sub-headers">
                    <span>טיפול בפניה</span>
                    <span>סנטימנט שיחה</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="document-prompts"
            style={{ width: readMoreSettings.widthSize, maxWidth: readMoreSettings.widthSize }}
          >
            <div className="document-prompts-titles">
              <Typography sx={{ ...subHeaderStyle }}>פרטי שיחה</Typography>
              {/* <div className="crm-button">
                <Typography
                  sx={{ ...subHeaderStyle, fontSize: '14px', fontWeight: '400' }}
                  // onClick={handleSend}
                >
                  CRM-שליחה ל
                </Typography>
              </div> */}
            </div>
            <div>
              <span>נושא הפניה</span>
              <div style={{ width: readMoreSettings.widthSize }}>{error ? <ReadMore text={error} limit={readMoreSettings.limit} /> : <ReadMore text="אף אחת מהתקלות המפורטות לא נובעות מהשיחה" limit={readMoreSettings.limit} size={readMoreSettings.widthSize} />}</div>
            </div>
            <div>
              <span>תיעוד שיחה</span>
              <div style={{ width: readMoreSettings.widthSize }}>{document.documentation ? <ReadMore text={document.documentation} limit={readMoreSettings.limit} /> : <ReadMore text="לא נמצא תיעוד שיחה" limit={readMoreSettings.limit} size={readMoreSettings.widthSize} />}</div>
            </div>
            <div>
              <span>תקציר שיחה</span>
              <div style={{ width: readMoreSettings.widthSize }}>{document.summary ? <ReadMore text={document.summary} limit={readMoreSettings.limit} /> : <ReadMore text="לא נמצא תקציר שיחה" limit={readMoreSettings.limit} size={readMoreSettings.widthSize} />}</div>
            </div>
            <div>
              <span>תמלול שיחה</span>
              <div style={{ width: readMoreSettings.widthSize }}>
                {document.transcript ? (
                  <ReadMore text={document.transcript} limit={readMoreSettings.limit} />
                ) : (
                  <ReadMore
                    text="לא נמצא תמלול שיחה"
                    limit={readMoreSettings.limit}
                    size={readMoreSettings.widthSize}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}
