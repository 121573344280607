import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import { MainPage } from './pages/MainPage/MainPage';
import { WelcomePage } from './pages/WelcomePage/WelcomePage';
import { Login } from './pages/Login/Login';
import { Register } from './pages/Register/Register';
import { useEffect, useState } from 'react';
import axios from '@/configs/axios.js';
import app from '@/configs/firebase.js';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, SecureRoute, LoginCallback, useOktaAuth } from '@okta/okta-react';
import Cookies from 'js-cookie';
import { userAtom } from './atoms/general';
import { finishedLoginAtom } from '@/api/users';
import setColors from './hooks/setColors';
import { Provider } from 'jotai';
import { TurboPage } from './pages/TurboPage';
import { useAtom } from 'jotai';
import io from 'socket.io-client';
import { socketAtom, propertiesAtom } from './atoms/general';
import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import BotsList from './pages/BotListPage/BotsList';
import { fileUploadCallbackAtom, fileErrorCallbackAtom } from '@/atoms/files';
import { useSnackbar } from 'notistack';
import Snackbar from '@/components/Snackbar/Snackbar';
import { SnackbarProvider } from 'notistack';
import { getDocumentsAndFoldersAtom } from '@/api/documents';
import { getPromptsAndFoldersAtom } from '@/api/prompts';
import { getProperties } from '@/api/properties';

function App() {
  const [socket] = useAtom(socketAtom);
  const [, setProperties] = useAtom(propertiesAtom);
  const [finishedLogin] = useAtom(finishedLoginAtom);
  const [fileUploadCallback] = useAtom(fileUploadCallbackAtom);
  const [fileErrorCallback] = useAtom(fileErrorCallbackAtom);
  const [, getDocumentsAndFolders] = useAtom(getDocumentsAndFoldersAtom);
  const [, getPromptsAndFolders] = useAtom(getPromptsAndFoldersAtom);

  const { enqueueSnackbar } = useSnackbar();

  const auth = getAuth();

  const navigate = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  //using dummy user to bypass authentication
  ///initialize the user to empty if want to go to login screen
  const [user, setUser] = useAtom(userAtom);

  const handleFileSuccessEvent = () => {};

  const handleFileErrorEvent = (error) => {
    if (fileErrorCallback) {
      enqueueSnackbar('We encountered a problem uploading, please try again', {
        content: (key, message) => <Snackbar type={'error'} message={message} />,
      });
    }
  };

  useEffect(() => {
    const setSettings = async () => {
      const settings = await getProperties();
      await setColors(settings.colors);
      setProperties(settings);
    };
    setSettings();
  }, []);

  useEffect(() => {
    socket.on('file-uploaded-successfully', handleFileSuccessEvent);
    socket.on('file-upload-error', handleFileErrorEvent);

    return () => {
      socket.off('file-uploaded-successfully', handleFileSuccessEvent);
      socket.off('file-upload-error', handleFileErrorEvent);
    };
  }, [fileUploadCallback, fileErrorCallback]);

  useEffect(() => {
    const publicRoutes =
      window.location.href.includes('/register') || window.location.href.includes('/turbo');
    if (!user && !publicRoutes) {
      navigate.push('/');
      return;
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    getDocumentsAndFolders();
    getPromptsAndFolders();

    return () => {
      socket.off('client-id');
    };
  }, []);

  return (
    <>
      <Route
        exact
        path="/"
        render={() => (
          <WelcomePage
          //  user={user}
          //  setUser={setUser}
          // socket={socket}
          />
        )}
      />
      <Route
        exact
        path="/register"
        render={() => (
          <Register
          //  user={user}
          //  setUser={setUser}
          // socket={socket}
          />
        )}
      />
      <Route
        exact
        path="/playground"
        render={() => (
          <MainPage
            //  user={user}
            //  setUser={setUser}
            socket={socket}
          />
        )}
      />
      <Route path="/turbo" render={() => <TurboPage />} />
      <Route
        path="/login"
        render={() => (
          <Login

          // setUser={setUser}
          />
        )}
      />
    </>
  );
  {
    /* <Route path="/welcome-page" render={() => <WelcomePage user={user} />} />
      <Route path="/history" render={() => <HistoryPage setUser={setUser} user={user} />} />
      <Route path="/turbo" render={() => <TurboPage />} />
      <Route path="/resource/:ids" render={() => <ResourcesPage />} />
      <Route path="/login/callback" component={LoginCallback} /> */
  }
}
const AppWithRouterAccess = () => (
  <HttpsRedirect>
    <Provider>
      <Router>
        <SnackbarProvider maxSnack={1}>
          <App />
        </SnackbarProvider>
      </Router>
    </Provider>
  </HttpsRedirect>
);

export default AppWithRouterAccess;
